import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles(() => ({
  filterMainSec: {
    marginTop: 30,
    borderBottom: `1px solid ${COLORS.lightGrey}`,
    paddingBottom: 30
  },
  heading: {
    fontSize: "22px",
    position: "relative",
    marginBottom: "25px",
    "&::before": {
      content: '""',
      width: "50px",
      borderRadius: 15,
      height: 4,
      position: "absolute",
      bottom: "-13px",
      left: "0",
      background: COLORS.primary,
    },
    "@media (max-width: 767px)": {
      fontSize: "20px !important",
    },
  },
  sliderSec: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  sliderHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "40px",
    marginLeft: '-10px',
    marginRight: '-10px',
    marginBottom: 10,
  }
}));

export default useStyles;
