import React from "react";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCategoryLink,
  getCategoryByParentId,
  getUrlParams,
  getFilterPath,
} from "../../helpers/common";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useParams, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./index.css";
import useStyles from "./styles";
import FilterByPrice from "../FilterByPrice";
import FilterByTaste from "../FilterByTaste";
import FilterByWeight from "../FilterByWeight";
import AppliedFilters from "../AppliedFilters";

const FirstLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);
  const { categoryId } = useParams();

  if (category.total_sub_categories === 0) {
    return (
      <MenuItem
        className={`${category.id === parseInt(categoryId) ? "active" : ""}`}
      >
        {category.name}
        <Link to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <SecondLevelMenu
            handleClick={handleClick}
            key={"SecondLevelMenu-" + category.id}
            categories={categories}
            category={category}
          />
        ))}
      </SubMenu>
    );
  }
};

const SecondLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);
  const { categoryId } = useParams();

  if (category.total_sub_categories === 0) {
    return (
      <MenuItem
        className={`${category.id === parseInt(categoryId) ? "active" : ""}`}
      >
        {category.name}
        <Link onClick={handleClick} to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <ThirdLevelMenu
            key={"ThirdLevelMenu-" + category.id}
            category={category}
            handleClick={handleClick}
          />
        ))}
      </SubMenu>
    );
  }
};

const ThirdLevelMenu = ({ category, handleClick }) => {
  const { categoryId } = useParams();

  return (
    <MenuItem
      className={`${category.id === parseInt(categoryId) ? "active" : ""}`}
    >
      {category.name}
      <Link onClick={handleClick} to={getCategoryLink(category)} />
    </MenuItem>
  );
};

export default function CategoriesSec({ hideFilterSec = false }) {
  const categories = useSelector((state) => state.categories.data);
  const classes = useStyles();
  const { categoryId, categoryName } = useParams();
  // where parent is Main.
  const level1Categories = getCategoryByParentId(categories, 1);
  const [open, setOpen] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const navigate = useNavigate();
  const [filteredWeight, setFilteredWeight] = React.useState("");
  const [filteredPrice, setFilteredPrice] = React.useState([]);
  const [filteredTaste, setFilteredTaste] = React.useState("");
  const frontendSettings = useSelector((state) => state.settings.data);

  const handleClick = () => {
    setOpen(!open);
  };

  const hasFilterApplied =
    filteredTaste || filteredWeight || filteredPrice?.length > 0;

  // separation of concerns (never mix two things in one function)
  // DRY - do not repeat code
  // Reusable

  const onFilterChange = (filterName, filterValue) => {
    let params = getUrlParams(filterName, filterValue);

    let pageNumber = 1;

    let path = getFilterPath(categoryId, categoryName, pageNumber, params);

    navigate(path, { replace: true });
  };

  const renderFilters = (show = true) => {
    if (!show && !openFilter) {
      return null;
    }

    return (
      <div className={classes.mainFilterSection}>
        {filteredPrice?.length > 0 || filteredWeight || filteredTaste ? (
          <AppliedFilters
            filteredPrice={filteredPrice}
            setFilteredPrice={setFilteredPrice}
            filteredWeight={filteredWeight}
            setFilteredWeight={setFilteredWeight}
            filteredTaste={filteredTaste}
            setFilteredTaste={setFilteredTaste}
          />
        ) : null}

        <FilterByPrice
          filteredPrice={filteredPrice}
          setFilteredPrice={setFilteredPrice}
          onFilterChange={onFilterChange}
          frontendSettings={frontendSettings}
        />
        <FilterByWeight
          filteredWeight={filteredWeight}
          setFilteredWeight={setFilteredWeight}
          onFilterChange={onFilterChange}
          frontendSettings={frontendSettings}
        />
        <FilterByTaste
          filteredTaste={filteredTaste}
          setFilteredTaste={setFilteredTaste}
          onFilterChange={onFilterChange}
          frontendSettings={frontendSettings}
        />
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <ProSidebar>
        <Menu>
          {level1Categories.map((category, index) => (
            <div key={"level1Categories-" + category.id}>
              <FirstLevelMenu
                handleClick={handleClick}
                categories={categories}
                category={category}
              />
            </div>
          ))}
        </Menu>
      </ProSidebar>
    );
  };

  return (
    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 categories-list'>
      <div className='web-category-sec'>
        <h4 className={classes.heading}>Categories</h4>
        {renderCategories()}
      </div>

      <div className='web-filter'>{!hideFilterSec && renderFilters()}</div>

      <div className='mobile-category-sec'>
        <div className='mobile-category-top'>
          <div className='all-category-btn' onClick={handleClick}>
            <div className='d-flex align-items-center'>
              <i className='fa fa-list' aria-hidden='true'></i>
              <h6>{open ? "Hide categories" : "See All Categories"}</h6>
            </div>
          </div>

          {!hideFilterSec && (
            <div
              className='all-category-btn'
              onClick={() => setOpenFilter(!openFilter)}
            >
              <div className='d-flex align-items-center'>
                <i className='fa fa-filter' aria-hidden='true'></i>
                <h6>Filter</h6>

                {hasFilterApplied && <span className='filterBadge'></span>}
              </div>
            </div>
          )}
        </div>

        <Collapse in={open} timeout='auto' unmountOnExit>
          {renderCategories()}
        </Collapse>

        {!hideFilterSec && renderFilters(false)}
      </div>
    </div>
  );
}
