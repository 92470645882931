import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles(() => ({
  filterMainSec: {
    marginTop: 30,
    borderBottom: `1px solid ${COLORS.lightGrey}`,
    paddingBottom: 30,
  },
  heading: {
    fontSize: "22px",
    position: "relative",
    marginBottom: "25px",
    "&::before": {
      content: '""',
      width: "50px",
      borderRadius: 15,
      height: 4,
      position: "absolute",
      bottom: "-13px",
      left: "0",
      background: COLORS.primary,
    },
    "@media (max-width: 767px)": {
      fontSize: "20px !important",
    },
  },
  filterHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterBody: {
    marginTop: 20,
  },
  clearBtn: {
    fontSize: "16px",
    color: COLORS.primary,
    cursor: "pointer",
    marginTop: 5
  },
  filterItem: {
    marginBottom: 7,
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    fontSize: "15px",
    "& span": {
      fontWeight: "normal",
      marginLeft: "8px",
    },
  },
  clearIcon: {
    fontSize: "14px",
    marginRight: "10px",
    cursor: "pointer",
  },
}));

export default useStyles;
