import React from 'react';
import useStyles from './styles';
function AppLoader() {
    const classes = useStyles();
    return (
        <div className={`${classes.loaderSec}`}>
            <div className="flex justify-center items-center w-full h-full">
                <img style={{width: 50, height: 50}} src="/images/loader-trans.gif" alt="loading" />
            </div>
        </div>
    )
}

export default AppLoader;