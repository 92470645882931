import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { placeOrder } from "../../actions/orders";
import { showErrorSnackbar } from "../../actions/snackbar";
import ShippingProPreview from "./ShippingProPreview";
import ShippingDetailsForm from "./ShippingDetailsForm";
import OrderSummary from "../OrderSummary";
import { useLocation, useNavigate } from "react-router-dom";
import AddressSelectForm from "../SelectAddress/AddressSelectForm";
import AppLoader from "../AppLoader";
import moment from "moment";
import { getWordCount } from "../../helpers/common";
import MetaTags from "../Common/MetaTags";

const Shipping = () => {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [hidebutton, setHidebutton] = useState(false);
  const delivery_preferred_date_time = localStorage.getItem(
    "delivery_preferred_date_time"
  );
  const { state } = useLocation();
  const couponCode = state?.couponCode;
  const remark = state?.remark;
  const customers = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const addressList = customers.address;
  const addressLoading = customers.loading;
  const defaultAddress = addressList.find((d) => d.is_default === 1);

  useEffect(() => {
    if (defaultAddress) {
      setSelectedAddress(defaultAddress);
    }
  }, [defaultAddress, selectedAddress]);

  const totalQuantity = () => {
    return cartData?.reduce((acc, item) => item.qty + acc, 0);
  };

  const totalPrice = useCallback(() => {
    return cartData?.reduce(
      (acc, item) => item.selling_price * item.qty + acc,
      0
    );
  }, [cartData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (addressList.length < 0) {
      setHidebutton(true);
    } else {
      setHidebutton(false);
    }
  }, [addressList]);

  const checkout = useCallback(() => {
    const items = cartData.map((d) => {
      return {
        item_id: d.id,
        quantity: d.qty,
      };
    });

    if (selectedAddress === null) {
      dispatch(showErrorSnackbar("Please select address"));
      return;
    }

    if (
      selectedAddress?.full_address?.length < 40 ||
      getWordCount(selectedAddress?.full_address) < 5
    ) {
      dispatch(
        showErrorSnackbar(
          "Your selected address is too short. Please update your address and try again."
        )
      );
      return;
    }

    if (!selectedAddress?.state) {
      dispatch(
        showErrorSnackbar(
          "Your address is missing the state. Please add your state to continue."
        )
      );

      return
    }

    setLoading(true);
    dispatch(
      placeOrder({
        customer: {
          name: selectedAddress.contact_person,
          address_id: selectedAddress.id,
        },
        items,
        coupon_code: couponCode,
        preferred_delivery_date: delivery_preferred_date_time || "",
        customer_remarks: remark || "",
      })
    )
      .then((response) => {
        navigate(`/checkout/${response.data.order.order_id}`, {
          state: { order: response.data },
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          dispatch(showErrorSnackbar(error.response.data.message));
        } else {
          dispatch(showErrorSnackbar("Something went wrong. Please try again"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    cartData,
    selectedAddress,
    dispatch,
    delivery_preferred_date_time,
    navigate,
    couponCode,
    remark,
  ]);

  const renderComponent = () => {
    if (addressLoading) {
      return (
        <div className={classes.innerLoaderSec}>
          <img
            className={classes.innerLoaderImg}
            src='/images/loader1.gif'
            alt=''
          />{" "}
        </div>
      );
    }

    return (
      <div className={classes.shippingSec}>
        <h2 className={classes.heading}>
          {addressList.length > 0 ? "Select Delivery Address" : "My Address"}
        </h2>

        <div className='row'>
          <div className='col-lg-8 col-md-7'>
            <Box flex={1.5} className={classes.shippingForm}>
              {addressList.length > 0 ? (
                <>
                  <AddressSelectForm
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    addressList={addressList}
                  />
                </>
              ) : (
                <ShippingDetailsForm classes={classes} />
              )}
            </Box>
          </div>
          <div className='col-lg-4 col-md-5'>
            <div className='row flex-column'>
              <ShippingProPreview taxCharge='*Calculated at Next Step' />

              {delivery_preferred_date_time && (
                <div className={classes.orderTime}>
                  <h3 className={classes.orderTimeHeading}>
                    Delivery Date & Time
                  </h3>
                  <div className={classes.orderTimeBody}>
                    {moment(delivery_preferred_date_time).format(
                      "Do MMMM YYYY, h:mm a"
                    )}

                    <i
                      className='fa fa-edit'
                      onClick={() => navigate("/cart")}
                    />
                  </div>
                </div>
              )}

              <div className='mt-4'>
                <OrderSummary
                  totalQuantity={totalQuantity}
                  totalPrice={totalPrice}
                  redirect={checkout}
                  shippingCharges='Free'
                  buttonText='Checkout'
                  order={null}
                  hiddenButton={hidebutton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section>
      {loading ? <AppLoader /> : null}

      <MetaTags title='Shipping Details' description='' keywords=''></MetaTags>

      <div className='container'>{renderComponent()}</div>
    </section>
  );
};

export default Shipping;
