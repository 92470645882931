import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertToSlug, productImageUrl } from "../../../helpers/common";
import AddCartButton from "../../AddCartButton";
import useStyles from "./styles";

export default function ProductBox({ product }) {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);
  const quantity = 1;

  return (
    <div key={product.id} className={`${classes.myCol}`}>
      <Link to={`/product/${product.id}/${convertToSlug(product?.url_slug)}`}>
        <div className={classes.productImgBlock}>
          <img
            className={classes.productImg}
            src={productImageUrl(
              product.main_image,
              "products",
              product.id,
              "290x290-"
            )}
            alt={product?.name}
          />
        </div>
        <div className={classes.content}>
          <h3>
            {product.name} ({product.item_weight} gms)
          </h3>
          
          <div className={classes.priceSec}>
            {product.mrp > product.selling_price ? (
              <del className={classes.del}>
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                {product.mrp}
              </del>
            ) : null}
            <span className={classes.price}>
              <i className="fa fa-inr mr-1" aria-hidden="true"></i>
              {product.selling_price}
            </span>
          </div>
        </div>
      </Link>
      <AddCartButton
        cartData={cartData}
        product={product}
        quantity={quantity}
      />
    </div>
  );
}
