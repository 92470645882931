import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductsByLable } from "../../api/items";
import AppLoader from "../AppLoader";
import ProductBox from "../ProductContainer/ProductBox";
import useStyles from "./styles";

const ProductsByLabels = () => {
  const classes = useStyles();
  const { labelName } = useParams();
  const [labeledProducts, setLabeledProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProductsByLable(labelName)
      .then((res) => {
        setLoading(false);
        setLabeledProducts(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [labelName]);

  return (
    <div className={classes.productContainer}>
      <div className={classes.innerBanner}>
        <div className="container">
          <h2 className={classes.heading}>{labelName}</h2>
        </div>
      </div>
      <div className={`container`}>
        {loading ? (
          <AppLoader />
        ) : (
          <>
            {labeledProducts.length > 0 ? (
              <div className="row">
                {labeledProducts.map((product) => (
                  <ProductBox
                    isSimilarProducts={true}
                    key={product.id}
                    product={product}
                  />
                ))}
              </div>
            ) : (
              <h6 className={classes.notFound}>No Product Found</h6>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsByLabels;
