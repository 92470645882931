import React, { useCallback, useState } from "react";
import { Grid, TextField, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../api/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { getCustomerAddress } from "../../actions/customer";
import { getSettingByKey, getWordCount } from "../../helpers/common";
import SelectCity from "../Common/SelectCity";
import SelectState from "../Common/SelectState";

const ShippingDetailsForm = ({ classes }) => {
  const [addressType, setAddressType] = useState("home");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.data);
  const findAllowedPincodes = getSettingByKey(
    settings,
    "allowed_pincodes"
  )?.value;

  const {
    mobile_number,
    pin_code,
    contact_person,
    address,
    address_name,
    state,
  } = useSelector((state) => state.customers.profile);

  const [customer, setCustomer] = useState({
    address_name: address_name || "",
    contact_person: contact_person || "",
    full_address: address || "",
    pin_code: pin_code || "",
    mobile_number: mobile_number || "",
    city: "",
    state: state || "",
  });

  const addressCharCount = customer?.full_address?.length;
  const maxAddressChar = 200;
  const addreddCharLength = maxAddressChar - addressCharCount;

  const handleFieldChange = useCallback(
    async (field, e) => {
      e.persist();
      if (e.target.value.length <= 200) {
        setCustomer((currData) => {
          return {
            ...currData,
            [field]: e.target.value,
          };
        });
      }

      setErrors((currErr) => {
        return {
          ...currErr,
          [field]: false,
        };
      });
    },
    [setCustomer, setErrors]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!customer.contact_person) {
        setErrors({ contact_person: "Name is required" });
        return;
      }
      if (!customer.mobile_number) {
        setErrors({ mobile_number: "Mobile number is required" });
        return;
      }
      if (
        !customer?.mobile_number?.match("[0-9]{10}") ||
        customer?.mobile_number?.length !== 10
      ) {
        setErrors({ mobile_number: "Please provide valid mobile number" });
        return;
      }
      if (!customer.full_address) {
        setErrors({ full_address: "Address is required" });
        return;
      }
      if (
        customer.full_address?.length < 40 ||
        getWordCount(customer.full_address) < 5
      ) {
        setErrors({
          full_address: "Address must be 5 word and 40 character long.",
        });
        return;
      }
      if (!customer.city) {
        setErrors({ city: "City is required" });
        return;
      }
      if (!customer.state) {
        setErrors({ state: "State is required" });
        return;
      }
      if (!customer.pin_code) {
        setErrors({ pin_code: "Pin code is required" });
        return;
      }

      if (
        findAllowedPincodes &&
        !findAllowedPincodes.includes("*") &&
        !findAllowedPincodes.includes(customer.pin_code)
      ) {
        setErrors({ pin_code: `Pin code is not allowed ` });
        return;
      }

      if (!customer.address_name) {
        setErrors({ address_name: "Address name is required" });
        return;
      }

      setLoading(true);

      addAddress({ ...customer, address_type: addressType })
        .then(() => {
          dispatch(getCustomerAddress()).then((ack) => {
            dispatch(showSuccessSnackbar("Address added successfully !"));
          });
        })
        .catch((_err) => {
          dispatch(showErrorSnackbar("Sorry! Address cannot be added !"));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addressType, customer, dispatch, findAllowedPincodes]
  );

  return (
    <Box className={classes.inputFormBox}>
      <form>
        <Grid className={classes.inputForm}>
          <TextField
            fullWidth
            variant='standard'
            value={customer.contact_person}
            name='contact_person'
            onChange={handleFieldChange.bind(this, "contact_person")}
            label='Your Name'
            error={errors.contact_person ? true : false}
            className={classes.shippingFeild}
          />
          {errors.contact_person ? (
            <Box className={classes.errorText}>{errors.contact_person}</Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <TextField
            fullWidth
            variant='standard'
            value={customer.mobile_number}
            type="number"
            name='mobile_number'
            onChange={handleFieldChange.bind(this, "mobile_number")}
            label='Mobile No'
            error={errors.mobile_number ? true : false}
            className={classes.shippingFeild}
          />
          {errors.mobile_number ? (
            <Box className={classes.errorText}>{errors.mobile_number}</Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <Grid container alignItems='center' position='relative'>
            <TextField
              fullWidth
              variant='standard'
              value={customer.full_address}
              name='full_address'
              onChange={handleFieldChange.bind(this, "full_address")}
              placeholder='Minimum 5 words required.'
              label='Full Address'
              error={errors.full_address ? true : false}
              className={`${classes.shippingFeild} ${classes.inputFeildAddress}`}
            />
            <div
              className={classes.characterLimit}
            >{`(${addreddCharLength} / ${maxAddressChar})`}</div>
          </Grid>
          {errors.full_address ? (
            <Box className={classes.errorText}>{errors.full_address}</Box>
          ) : null}
        </Grid>

        <Box className={classes.inputRow} minHeight={60}>
          <Grid container alignItems='center' position={"relative"}>
            <SelectCity
              classes={classes}
              errors={errors}
              setCustomer={setCustomer}
              customer={customer}
            />
          </Grid>

          <Grid container alignItems='center' position={"relative"}>
            <SelectState
              classes={classes}
              errors={errors}
              setCustomer={setCustomer}
              customer={customer}
              defaultValue={{
                value: customer?.state,
                label: customer?.state,
              }}
            />
          </Grid>

          <Grid container alignItems='center' position={"relative"} className='mobile-hide'>
            <TextField
              className={classes.inputFeild}
              fullWidth
              value={customer.pin_code}
              type="number"
              variant='standard'
              name='pin_code'
              onChange={handleFieldChange.bind(this, "pin_code")}
              label='Pin Code *'
              error={errors.pin_code ? true : false}
            />
            {errors.pin_code &&
              findAllowedPincodes &&
              !findAllowedPincodes.includes(customer.pin_code) && (
                <span className={classes.pincodeLink}>
                  Pin code is not allowed{" "}
                  <Link to='/pages/available-pincodes' target="_blank">Click here</Link>
                </span>
              )}
          </Grid>
        </Box>

        {/* this box is showing on mobile only */}
        <Box className={`${classes.inputRow} web-hide`}>
          <Grid container alignItems='center' position={"relative"}>
            <TextField
              className={classes.inputFeild}
              fullWidth
              value={customer.pin_code}
              type="number"
              variant='standard'
              name='pin_code'
              onChange={handleFieldChange.bind(this, "pin_code")}
              label='Pin Code *'
              error={errors.pin_code ? true : false}
            />
            {errors.pin_code &&
              findAllowedPincodes &&
              !findAllowedPincodes.includes(customer.pin_code) && (
                <span className={classes.pincodeLink}>
                  Pin code is not allowed{" "}
                  <Link to='/pages/available-pincodes' target="_blank">Click here</Link>
                </span>
              )}
          </Grid>
        </Box>

        <Grid className={classes.inputForm}>
          <TextField
            value={customer.landmark}
            fullWidth
            error={errors.landmark ? true : false}
            className={classes.shippingFeild}
            label='Landmark'
            placeholder='Landmark'
            onChange={handleFieldChange.bind(this, "landmark")}
            variant='standard'
          />
          {errors.landmark ? (
            <Box className={classes.errorText}>
              Please enter a valid additional mobile number
            </Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <TextField
            multiline
            fullWidth
            variant='standard'
            value={customer.address_name}
            name='address_name'
            onChange={handleFieldChange.bind(this, "address_name")}
            label='Name this Address*'
            placeholder='i.e. My Home, My Office, My Sister Home etc'
            error={errors.address_name ? true : false}
            className={classes.shippingFeild}
          />
        </Grid>

        <Grid className={classes.inputForm}>
          <h6>Save address as</h6>
          <div className={classes.addressSelect}>
            <div
              onClick={() => setAddressType("home")}
              className={`${classes.addressSelectBtn} ${
                addressType === "home" ? classes.addressSelectBtnActive : ""
              } `}
            >
              Home
            </div>
            <div
              onClick={() => setAddressType("work")}
              className={`${classes.addressSelectBtn} ${
                addressType === "work" ? classes.addressSelectBtnActive : ""
              } `}
            >
              work
            </div>
          </div>
        </Grid>

        <div className='d-flex justify-content-between'>
          <div className={classes.btnSec}>
            <Link
              className={`${classes.btnLink} ${classes.submitBtn}`}
              to='/cart'
            >
              <i className='fa fa-arrow-left'></i> Go Back
            </Link>
          </div>

          <div className={classes.btnSec}>
            <button
              className={`${classes.btnLink} ${classes.submitBtn}`}
              onClick={handleSubmit}
            >
              {loading ? "Loading.." : "Add Address"}
            </button>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default ShippingDetailsForm;
