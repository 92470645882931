import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

const useStyles = makeStyles((theme) => ({
  footerSec: {
    backgroundColor: COLORS.primary,
    paddingBottom: 20,
  },
  playStoreSec: {
    borderBottom: `1px solid ${COLORS.white}`,
    paddingTop: 30,
    background: '#f0f0f0',
    marginBottom: 30,
    paddingBottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 480px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  downloadText: {
    textAlign: "center",
    color: COLORS.black,
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "Poppins",
    letterSpacing: 5,
    margin: 0,
    "@media (max-width: 768px)": {
      fontSize: 16,
    },
    "@media (max-width: 480px)": {
      marginLeft: 15,
      marginBottom: 15,
      letterSpacing: 2,
      fontSize: 14,
    },
  },
  playStoreLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: COLORS.black,
    fontWeight: "500",
    fontSize: 13,
    marginLeft: 20,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: COLORS.primary,
      opacity: 0.8,
    },
  },
  playStoreLogo: {
    width: 70,
    height: "auto",
    marginBottom: 5,
    "@media (max-width: 768px)": {
      width: 50,
    },
  },
  footerMenuTitle: {
    color: COLORS.golden,
    textTransform: "uppercase",
    fontSize: "17px !important",
    fontWeight: "normal",
    fontFamily: "Poppins",
    letterSpacing: 1.5,
    marginBottom: 20,
  },
  footerMenuList: {
    paddingBottom: 10,
  },
  menuLink: {
    color: COLORS.white,
    fontSize: "15px !important",
    transition: "all 0.3s ease-in-out",
    padding: "10px 0",
    "&:hover": {
      color: COLORS.golden,
    },
  },
  footerSocialMenuTitle: {
    color: COLORS.golden,
    textTransform: "uppercase",
    fontSize: "17px !important",
    fontWeight: "normal",
    fontFamily: "Poppins",
    letterSpacing: 1.5,
    marginBottom: 20,
    textAlign: "end",
    "@media (max-width: 768px)": {
      textAlign: "left",
    },
  },
  socialUl: {
    display: "flex",
    justifyContent: "end",
    marginBottom: 30,
    alignItems: "center",
    "@media (max-width: 768px)": {
      justifyContent: "flex-start",
    },
  },
  socialLi: {
    marginLeft: 10,
    "@media (max-width: 768px)": {
      marginLeft: 0,
      marginRight: 10,
    },
  },
  socialLink: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    backgroundColor: COLORS.white,
    borderRadius: "50%",
    color: COLORS.primary,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      transform: "rotate(360deg)",
      color: COLORS.primary,
    },
    "@media (max-width: 768px)": {
      marginLeft: 0,
      marginRight: 10,
    },
    "@media (max-width: 500px)": {
      width: 35,
      height: 35,
    }
  },
  socialPaymentUl: {
    marginTop: 0,
    flexWrap: 'wrap',
  },
  // paymentPartnerLogo: {
  //   width: 50,
  //   height: "auto",
  //   marginBottom: 10
  // },
  paymentPartnerImg: {
    maxWidth: 260,
    marginBottom: 40,
  },
  copyrightSec: {
    textAlign: "center",
    paddingTop: 20,
  },
  copyrightText: {
    color: COLORS.white,
    fontSize: 13,
    fontFamily: "Poppins",
    fontWeight: "400",
  },
}));

export default useStyles;
