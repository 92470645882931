import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  footerBannerSec: {
    marginBottom: 60,
    '@media (max-width: 767px)': {
      marginBottom: 30,
    }
  }
}));
