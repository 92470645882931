import { Chip, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { getProductsItemList } from "../../../api/items";
import { filterByCategoryName, splitArray } from "../../../helpers/common";
import ProductBox from "../ProductBox";
import useStyles from "./styles";

const HomeCategories = () => {
  const classes = useStyles();

  const menus = useSelector((state) => state.menus.menus);
  const homeCategories = useMemo(() => {
    return filterByCategoryName(menus, "home-categories");
  }, [menus]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const [activeCategory, setActiveCategoryId] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const productListOne = splitArray(products)[0];
  const productListTwo = splitArray(products)[1];

  useEffect(() => {
    if (homeCategories?.length > 0) {
      setActiveCategoryId(homeCategories[0]?.url);
    }
  }, [homeCategories]);

  useEffect(() => {
    if (activeCategory) {
      const isNumber = parseInt(activeCategory)
      
      setLoading(true);
      getProductsItemList(
        `products?ProductsSearch[category_id]=${isNumber ? activeCategory : 50000}`
      )
        .then((res) => {
          setProducts(res?.data);
        })
        .catch(() => {
          alert("Sorry, Failed to fetch products");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeCategory]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };

  const renderProducts = () => {
    if (loading) {
      return (
        <div className='container text-center mt-5'>
          <CircularProgress size={30} color='inherit' />
        </div>
      );
    }

    if (products?.length === 0) {
      return (
        <p className={classes.notFoundText}>
          Sorry! Record not found in this category.
        </p>
      );
    }

    return (
      <>
        {productListOne?.length > 0 && (
          <div className={`productSliderSec`}>
            <div className='container'>
              <Carousel
                draggable={true}
                keyBoardControl={true}
                responsive={responsive}
                infinite={true}
                partialVisible={"false"}
                autoPlaySpeed={4000}
                arrows={true}
                autoPlay={false}
                dotListClass='custom-dot-list-style'
                removeArrowOnDeviceType={["mobile"]}
                showDots={false}
              >
                {productListOne?.map((product) => (
                  <ProductBox
                    key={"bestSellers-" + product.id}
                    product={product}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}

        {productListTwo?.length > 0 && (
          <div
            className={`productSliderSec `}
            style={{ marginTop: isMobile ? -20 : 0 }}
          >
            <div className='container'>
              <Carousel
                draggable={true}
                keyBoardControl={true}
                responsive={responsive}
                infinite={true}
                partialVisible={"false"}
                autoPlaySpeed={4000}
                arrows={true}
                autoPlay={false}
                dotListClass='custom-dot-list-style'
                removeArrowOnDeviceType={["mobile"]}
                showDots={false}
              >
                {productListTwo?.map((product) => (
                  <ProductBox
                    key={"bestSellers-" + product.id}
                    product={product}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </>
    );
  };

  if (homeCategories?.length === 0) {
    return null;
  }

  return (
    <div className={classes.categorySec}>
      <div className='container'>
        <h2 className={classes.heading}>Categories</h2>

        <div className={classes.catContainer}>
          {homeCategories?.map((item) => (
            <Chip
              label={item?.name}
              key={item?.id}
              className={`${classes.catChip} ${
                item?.url === activeCategory ? classes.activeCatChip : ""
              }`}
              onClick={() => setActiveCategoryId(item?.url)}
            />
          ))}
        </div>
      </div>

      {renderProducts()}
    </div>
  );
};

export default HomeCategories;
