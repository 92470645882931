import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  cmsPage: {
    paddingTop: "140px",
    "@media (max-width: 991px)": {
      paddingTop: "130px",
    },
    "@media (max-width: 500px)": {
      paddingTop: "110px",
    }
  },
  innerBanner: {
    padding: "50px 0 0px",
    "@media (max-width: 991px)": {
      minHeight: "auto",
    },
  },
  title: {
    textAlign: 'center',
    fontSize: "26px",
    "@media (max-width: 991px)": {
      fontSize: '20px !important',
    },
  },
  content: {
    padding: "20px 0px 40px",
  },
});

export default useStyle;
