import React, { useEffect } from "react";
import CategoriesSec from "../CategoriesSec";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../actions/categories";
import useStyles from "./styles";
import { setMeta } from "../../actions/meta";
import { Link, useParams } from "react-router-dom";
import { COLORS } from "../../constant/colors";
import { dataUrl } from "../../helpers/common";

export default function ProductLayout(props) {
  const categories = useSelector((state) => state.categories.data);
  const meta = useSelector((state) => state.meta.data);
  const classes = useStyles();
  const { categoryId } = useParams();
  const innerBanner = useSelector((state) => state.banners["Listing Banners"]);
  const mainCategories = categories.filter((record) => record.parent_id !== 0);
  const dispatch = useDispatch();

  const currentCategory = categories.find(
    (cat) => cat.id === parseInt(categoryId)
  );

  const findMainCat = categories.find(
    (cat) => cat.id === currentCategory?.parent_id
  );

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">
          <i className="fa fa-home" style={{ color: COLORS.primary }} />
        </Link>{" "}
        / Categories{" "}
        {findMainCat?.name && findMainCat?.name?.toLowerCase() !== "main" ? (
          <>/ {findMainCat?.name} </>
        ) : null}{" "}
        / {currentCategory?.name}
      </>
    );
    dispatch(setMeta({ title: "Product", breadcrumb }));
  }, [currentCategory, dispatch, findMainCat?.name]);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  let pageJsx = (
    <div className={classes.productLayout}>
      <img
        className={classes.bannerImg}
        src={`${dataUrl}/banners/1400x250-${innerBanner[0]?.image}`}
        alt="carousel-products"
      />

      <div className={classes.breadcrumbSec}>
        <div className="container">
          <ul className={`breadcrumb_info ${classes.breadcrumb_info}`}>
            <li className={classes.breadcrumbValue}>{meta.breadcrumb}</li>
          </ul>
        </div>
      </div>

      <div className={props.parentClass}>
        <div className="container">
          <div className={`row ${classes.listingRow}`}>
            <CategoriesSec
              mainCategories={mainCategories}
              categoryId={props.categoryId}
              hideFilterSec={props.hideFilterSec}
            />
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );

  return <div>{pageJsx}</div>;
}
