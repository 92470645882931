import React from "react";
import { Link } from "react-router-dom";
import makeStyle from "./styles";
import {
  convertToSlug,
  getCategoriesByParentName,
} from "../../../helpers/common";

const DropdownMenuWeb = ({ topMenus, categories, labeledProducts }) => {
  const classes = makeStyle();

  // <ul className={classes.menuUl}>
  //         {top_menus.map((menu) => (
  //           <li key={menu.id} className={classes.menuLi}>
  //             <Link className={classes.menuLink} to={menu.url}>
  //               {menu.name}
  //             </Link>
  //           </li>
  //         ))}
  //       </ul>

  return (
    <div className={classes.webMenus}>
      <ul className={`d-flex mb-0 ${classes.menuUl}`}>
        {topMenus.map((menu, index) => (
          <li key={index} className={`menu-dropdown ${classes.menuLi}`}>
            <Link
              className={`nav-link  ${classes.menuLink}`}
              key={`top-menu-${menu.id}`}
              to={
                menu?.url?.includes("#labels/") || menu?.url?.includes("#/")
                  ? "#"
                  : menu.url
              }
            >
              {menu.name}
            </Link>

            {getCategoriesByParentName(categories, menu.name)?.length > 0 ||
            labeledProducts[menu.name]?.length > 0 ? (
              <div className={`dropdown-content ${classes.dropdownContent}`}>
                <div className={`row ${classes.block} m-0`}>
                  <ul>
                    {menu.url.includes("#labels/")
                      ? labeledProducts[menu.name]?.map((product) => {
                          return (
                            <li
                              className={`${classes.menuLi}`}
                              key={product.id}
                            >
                              <Link
                                className={`nav-link  ${classes.dropdownMenuLink}`}
                                to={`/product/${product.id}/${convertToSlug(
                                  product.name
                                )}`}
                              >
                                {product.name}
                              </Link>
                            </li>
                          );
                        })
                      : getCategoriesByParentName(categories, menu.name)?.map(
                          (category) => (
                            <li
                              className={`${classes.menuLi}`}
                              key={category.id}
                            >
                              <Link
                                className={`nav-link  ${classes.dropdownMenuLink}`}
                                to={`/category/${category.id}/${convertToSlug(
                                  category.name
                                )}/1`}
                              >
                                {category.name}
                              </Link>
                            </li>
                          )
                        )}
                  </ul>
                </div>
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenuWeb;
