import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../actions/snackbar";
import { COLORS } from "../../../constant/colors";
import { Divider } from "@mui/material";
import SingleReview from "../SingleReview";
import { postReview } from "../../../api/items";
import { getReviews } from "../../../actions/products";

export default function ProductReview({ product, setProduct }) {
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const customer = useSelector((state) => state.customers.profile);
  const [writeReview, setWriteReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const ratingsAndReviews = useSelector(
    (state) => state.products.ratingsAndReviews
  );
  const classes = useStyles();
  const ratings = ratingsAndReviews.ratings;
  const reviews = ratingsAndReviews.reviews;

  let total =
    ratings.total_1_stars +
    ratings.total_2_stars +
    ratings.total_3_stars +
    ratings.total_4_stars +
    ratings.total_5_stars;

  let s1p = (ratings.total_1_stars * 100) / total;
  let s2p = (ratings.total_2_stars * 100) / total;
  let s3p = (ratings.total_3_stars * 100) / total;
  let s4p = (ratings.total_4_stars * 100) / total;
  let s5p = (ratings.total_5_stars * 100) / total;

  const onWriteReview = () => {
    if (!customer?.mobile_number) {
      dispatch(showErrorSnackbar("You need to login first"));
    } else {
      setWriteReview(true);
    }
  };

  const onSubmitReview = () => {
    if (customer?.mobile_number) {
      if (rating === 0 || review === "") {
        dispatch(showErrorSnackbar("Please add rating and review"));
        return;
      }
      setLoading(true);
      postReview({
        product_id: product.id,
        review,
        rating_value: rating,
      }).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          dispatch(showSuccessSnackbar("Review submitted successfully"));
          setWriteReview(false);
          setReview("");
          setRating(0);
        }

        // updating the product details
        if (res?.product) {
          setProduct(res.data.product);
        }
        // refreshing the reviews list
        if (product?.id) {
          dispatch(getReviews(product?.id));
        }
      });
    } else {
      dispatch(showErrorSnackbar("Please login first"));
    }
  };

  return (
    <>
      <div className={classes.comm_sec}>
        <div className={classes.prodrate_sec}>
          {/* <h5>Product Rating</h5> */}
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 ">
              <div className={classes.pr_left}>
                <h4 className={classes.totalRating}>{ratings.average_stars}</h4>

                <div className="rate_info xl">
                  <Rating
                    name="simple-controlled"
                    value={ratings.average_stars}
                    readOnly
                    size={"small"}
                    className={classes.ratingStar}
                  />
                  <div className={classes.rate_info}>
                    <span className={classes.rate_info_span}>
                      {ratings.average_stars} out of 5
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-7 col-sm-7 ">
              <ul>
                <li className={classes.progressList}>
                  <div className={classes.progressColLeft}>
                    5 <i className="fa fa-star" />
                  </div>
                  <div className={`progress ${classes.progress}`}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${s5p}%`, backgroundColor: "green" }}
                      aria-valuenow={75}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className={classes.progressColRight}>
                    <span>{ratings.total_5_stars}</span>
                  </div>
                </li>
                <li className={classes.progressList}>
                  <div className={classes.progressColLeft}>
                    4 <i className="fa fa-star" />
                  </div>
                  <div className={`progress ${classes.progress}`}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${s4p}%`, backgroundColor: "green" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className={classes.progressColRight}>
                    <span>{ratings.total_4_stars}</span>
                  </div>
                </li>
                <li className={classes.progressList}>
                  <div className={classes.progressColLeft}>
                    3 <i className="fa fa-star" />
                  </div>
                  <div className={`progress ${classes.progress}`}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${s3p}%`, backgroundColor: "#0ab10a" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className={classes.progressColRight}>
                    <span>{ratings.total_3_stars} </span>
                  </div>
                </li>
                <li className={classes.progressList}>
                  <div className={classes.progressColLeft}>
                    2 <i className="fa fa-star" />
                  </div>
                  <div className={`progress ${classes.progress}`}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${s2p}%`, backgroundColor: "#fdcd0a" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className={classes.progressColRight}>
                    <span>{ratings.total_2_stars}</span>
                  </div>
                </li>
                <li className={classes.progressList}>
                  <div className={classes.progressColLeft}>
                    1 <i className="fa fa-star" />
                  </div>
                  <div className={`progress ${classes.progress}`}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${s1p}%`, backgroundColor: "red" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className={classes.progressColRight}>
                    <span>{ratings.total_1_stars}</span>
                  </div>
                </li>
              </ul>
            </div>
            {!writeReview ? (
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 divider">
                <div className={`row ${classes.rateRow}`}>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-6">
                    <h6>Rate product</h6>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="rate_info text-right">
                      <Rating
                        name="simple-controlled"
                        value={0}
                        // style={{ color: COLORS.primary }}
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div
                  onClick={onWriteReview}
                  className={`btn-sec ${classes.rev_btns_sec}`}
                >
                  <Link to="#" className={`my-btn ${classes.rev_btns}`}>
                    Add Review
                  </Link>
                </div>
              </div>
            ) : (
              <div className={`col-12 mt-4`}>
                <Divider />
                <div className={`${classes.ratingFormSec}`}>
                  <h6 className="mb-3">Rating:</h6>
                  <Rating
                    name="simple-controlled"
                    value={rating}
                    style={{ color: COLORS.secondary }}
                    size="medium"
                    onChange={(e, value) => setRating(value)}
                  />

                  <textarea
                    type={"text"}
                    className={`form-control ${classes.ratingForm}`}
                    placeholder={"Your Review"}
                    onChange={(e) => setReview(e.target.value)}
                  />

                  {!loading ? (
                    <div onClick={onSubmitReview} className={`btn-sec`}>
                      <Link to="#" className={`my-btn ${classes.rev_btns}`}>
                        Submit
                      </Link>
                    </div>
                  ) : (
                    <div className={`btn-sec`}>
                      <Link to="#" className={`my-btn ${classes.rev_btns}`}>
                        Please wait...
                      </Link>
                    </div>
                  )}

                  <div
                    onClick={() => setWriteReview(false)}
                    className={`btn-sec `}
                  >
                    <Link to="#" className={`my-btn ${classes.cancelBtn}`}>
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/*  */}
        <div className={classes.commentBlock}>
          <h5 className={classes.heading}>Product Review</h5>
          {reviews.map((review, index) => (
            <SingleReview review={review} key={"single-review-" + index} />
          ))}
        </div>
      </div>
    </>
  );
}
