import React, { useEffect } from "react";

const NotFoundPage = ({cmsPageNotFound}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },[])
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: !cmsPageNotFound ? '160px 0px 30px' : '30px 0px 30px',
          textAlign: 'center',
        }}
      >
        <div style={{ width: 650 }}>
          <img src="/images/not-found.png" alt="404" style={{ width: "90%", margin: '0 auto' }} />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
