import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertToSlug, productImageUrl } from "../../helpers/common";
import useStyles from "./styles";

export default function CartItemsWeb({ updateCartProduct, removeItem }) {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);

  return (
    <div className={classes.producSec}>
      <div className={`row ${classes.headerRow}`}>
        <div className={`col-5`}>
          <p className={`${classes.title}`}>Product</p>
        </div>
        <div className={`col-2 ${classes.title}`}>
          <p className={`${classes.title}`}> Price</p>
        </div>
        <div className={`col-2 ${classes.title}`}>
          <p className={`${classes.title}`}>Quantity</p>
        </div>
        <div className={`col-3 ${classes.title}`}>
          <p className={`${classes.title}`}>Total</p>
        </div>
      </div>

      {cartData.length > 0 ? (
        cartData.map((product) => {
          const total = product?.selling_price * product?.qty;
          const link = `/product/${product.id}/${convertToSlug(product?.name)}`;
          return (
            <div key={product.id} className={`row ${classes.productRow}`}>
              <div className="col-5 d-flex align-items-center">
                <Link to={link}>
                  <img
                    className={classes.image}
                    src={productImageUrl(
                      product.main_image,
                      "products",
                      product.id,
                      "290x290-"
                    )}
                    alt="cart-item"
                  />
                </Link>
                <div className="">
                  <span className={classes.name}>{product.name}</span>
                  <br />
                  <span className={classes.weight}>
                    {product?.item_weight} gms
                  </span>
                </div>
              </div>
              <div className="col-2">
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                {product.selling_price}
              </div>
              <div className="col-2">
                <div className="quantity">
                  <div className="quantity-select">
                    <div
                      className="entry value-minus"
                      onClick={() => updateCartProduct(product, "decrease")}
                    >
                      &nbsp;
                    </div>
                    <div className="entry value">
                      <span className="span-1">{product.qty}</span>
                    </div>
                    <div
                      className="entry value-plus active"
                      onClick={() => updateCartProduct(product, "increase")}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-3 ${classes.total}`}>
                <div>
                  <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                  {total.toFixed(2)}
                </div>
                <div
                  className={classes.remove}
                  onClick={() => removeItem(product)}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h3 className={classes.emptyText}>Cart is Empty</h3>
      )}
    </div>
  );
}
