import React from "react";
import { Helmet } from "react-helmet";
import { dataUrl } from "../../helpers/common";

const SingleProductDetailMeta = ({ product }) => {

  const productData = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: product?.name,
    image: `${dataUrl}/products/${product?.id}/500x438-${product?.main_image}`,
    description: product?.description || product?.highlights,
    sku: product?.sku,
    brand: {
      "@type": "Brand",
      name: "avonmankeen",
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "INR",
      price: product?.selling_price,
      availability: `http://schema.org/${
        product?.available_stock > 0 ? "InStock" : "OutOfStock"
      }`,
    },
  };

  const breadcrumbData = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 0,
        item: {
          "@id": "https://avonnamkeen.com/",
          name: "avonnamkeen.com/",
        },
      },
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": `https://avonnamkeen.com/category/${product?.category_id}/${product?.category_name}/1`,
          name: product?.category_name,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": `https://avonnamkeen.com/product/${product?.id}/${product?.name}`,
          name: product?.name,
        },
      },
    ],
  };

  return (
    <Helmet>
      <script type='application/ld+json'>{JSON.stringify(productData)}</script>
      <script type='application/ld+json'>
        {JSON.stringify(breadcrumbData)}
      </script>
    </Helmet>
  );
};

export default SingleProductDetailMeta;
