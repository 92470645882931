import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

// TODO: delete unused styles

const useStyles = makeStyles((theme) => ({
  cartSec: {
    padding: "50px 0",
  },
  heading: {
    textAlign: "center",
    marginBottom: 40,
  },
  warningSec: {
    marginBottom: 30,
  },
  images: {
    width: 90,
  },
  title: {
    textTransform: "uppercase",
    padding: "0 10px",
    fontFamily: "Poppins",
    fontWeight: "500",
    '@media (max-width: 767px)': {
      fontSize: '15px !important',
    }
  },
  headerRow: {
    marginBottom: 20,
    border: "1px solid #fff",
    paddingBottom: 15,
    borderBottomColor: COLORS.lightGrey,
  },
  producSec: {
    padding: "0 20px",
  },
  productRow: {
    marginBottom: 18,
    alignItems: "center",
    border: "1px solid #fff",
    paddingBottom: 20,
    borderBottomColor: COLORS.lightGrey,
  },
  name: {
    marginLeft: 15,
  },
  orderHeader: {
    padding: "20px 10px 10px 10px",
    border: "1px solid #fff",
    borderBottomColor: COLORS.lightGrey,
  },
  orderSummary: {
    background: "#f8f9fa",
  },
  orderBody: {
    padding: "16px",
  },
  priceSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px",
  },
  priceCol: {
    fontSize: 15,
  },
  discountCol: {
    fontSize: 15,
    color: COLORS.success,
  },
  shippingCharge: {
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'end',
    marginLeft: 20,
    color: 'green'
  },
  btnSec: {
    textAlign: "center",
    padding: "10px 20px",
    background: COLORS.primary,
    color: COLORS.white,
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      background: COLORS.secondary,
    },
  },
  emptyText: {
    textAlign: "center",
    marginTop: "17%",
    marginBottom: "12%",
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  remove: {
    cursor: 'pointer',
  }
}));

export default useStyles;
