import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  searchResultSec: {
    paddingTop: 140,
  },
  loaderSec: {
    display: "flex",
    justifyContent: "center",
  },
  breadcrumbInfo: {
    paddingTop: 20,
  },
  loader: {
    width: 50,
    textAlign: "center",
    marginBottom: '10%',
  },
  innerSection: {
    padding: "10px 0px 50px",
  },
  notFoundSec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& h5': {
      '@media (max-width: 500px)': {
        fontSize: '13px',
      }
    }
  }
});

export default useStyles;