import React, { useCallback, useEffect } from "react";
import { COLORS } from "../../constant/colors";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import useStyles from "./styles";
import { convertToSlug, imageUrl } from "../../helpers/common";
import { getOrderDetails } from "../../api/orders";
import { useDispatch, useSelector } from "react-redux";
import { showErrorSnackbar, showSuccessSnackbar } from '../../actions/snackbar';
import { updateCartData } from "../../helpers/cart";
import { updateCart } from "../../actions/cart";
import { useNavigate } from "react-router-dom";

export default function OrderDetails({
  openModal,
  handleClose,
  selectedOrder,
  orderAmount,
}) {
  const [currOrder, setCurrOrder] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const cartData = useSelector((state) => state.cart.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onReorder = useCallback(
    () => {
      const productList = currOrder.map(order => ({ ...order.product, qty: order.quantity }))
      // const newCartData = updateCartData(cartData, productList, 1);

      for (let i = 0; i < productList.length; i++) {
        const product = productList[i];
        const newCartData = updateCartData(cartData, product, 1);
        dispatch(updateCart(newCartData));
      }
      // const newCartData = [...cartData , productList];

      // dispatch(updateCart(newCartData));
      // TODO: fix this later - need to update the cart data 

      dispatch(showSuccessSnackbar("Product added to cart"));
      navigate('/cart');
    },
    [currOrder, cartData, dispatch, navigate]
  );


  useEffect(() => {
    if (selectedOrder && selectedOrder.id) {
      setloading(true);
      getOrderDetails(selectedOrder.order_id)
        .then((res) => {
          setloading(false);
          setCurrOrder(res.data);
          console.log("res.data:: ",res.data);
        })
        .catch((err) => {
          console.log(err);
          if ( err.message.includes("401") ) {
            dispatch(showErrorSnackbar('Unauthorized! Please login again.'));
          } 
          setloading(false);
        })
    }
  }, [selectedOrder, dispatch]);

  const classes = useStyles();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px !important",
    width: "60%",
    height: "90%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    boxShadow: `0px 4px 16px -6px ${COLORS.lightGrey}`,
    transition: "all 0.5s ease-in-out",
    overflow: "overlay !important",
    overflowX: "hidden !important",
    "@media (max-width: 991px)": {
      width: "90%",
      height: "80%",
    },
    "@media (max-width: 550px)": {
      width: "95%",
      height: "75%",
    },
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <div onClick={handleClose} className={classes.closeButton}>
            <i className="fa fa-times" />
          </div>
          <h2 className={classes.heading}>Order Details</h2>

          {loading ? (
            <div className={classes.loaderSec}>
              <img
                className={classes.loader}
                src="/images/loader1.gif"
                alt="loading..."
              />
            </div>
          ) : (
            <div className={classes.orderDetailSec}>
              <h6 className={classes.itemTitle}>
                {currOrder?.length > 1 ? "Items" : "Item"}
              </h6>

              {currOrder?.map((item, index) => (
                <div
                  key={"order-detail-" + index}
                  className={classes.productRow}
                  onClick={() => navigate(`/product/${item?.product?.id}/${convertToSlug(item?.product?.name)}`)}
                >
                  <img
                    src={imageUrl(
                      item?.product?.main_image,
                      `products/${item?.product?.id}`
                    )}
                    className={classes.detailImg}
                    alt="product-img"
                  />
                  <div className={classes.itemDetail}>
                    <h6 className={classes.itemName}>{item?.product?.name}</h6>
                    <h6 className={classes.itemName}>
                      <i className="fa fa-inr mr-1" aria-hidden="true"></i> {item.product.selling_price} x {item.quantity}
                    </h6>
                    <h6 className={classes.itemName}>
                      <i className="fa fa-inr mr-1" aria-hidden="true"></i> {item.product.selling_price * item.quantity}
                    </h6>
                  </div>
                </div>
              ))}

              {!loading && currOrder ? (
                <div className={classes.orderFooter}>
                  <div className={classes.orderTotal}>
                    <h6 className={classes.totalAmount}>Total Amount: </h6>
                    <h6 className={classes.itemPrice}>
                      {" "}
                      {orderAmount}
                    </h6>
                  </div>
                  <div className={classes.reorder}>
                    <button onClick={onReorder} className={classes.reorderBtn}>Reorder</button>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
