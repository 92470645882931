import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import striptags from "striptags";
import { dataUrl, getContentBySlot, toArray } from "../../../helpers/common";
import useStyles from "./styles";

export default function AboutUs() {
  const classes = useStyles();
  const banners = useSelector((state) => state.banners["About Us Banners"]);
  const contents = useSelector((state) => state.contents.data);

  const aboutUsContents = getContentBySlot(contents, "homepage_about_us");
  const content = toArray(aboutUsContents?.content, "\n");

  let randomBanners = banners
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return (
    <div className={classes.aboutSec}>
      <div className="container">
        <div className={`row ${classes.row}`}>
          <div className={`col-md-6 ${classes.content}`}>
            <h2 className={classes.title}>About Us</h2>
            {content?.map((para, index) => (
              <div className={classes.paraDiv} key={index}>
                <p>{striptags(para).replace(/&nbsp;/gi, ' ')}</p>
              </div>
            ))}
            <div className="btn-sec">
              <Link to="/pages/about-us" className="my-btn">
                Read More
              </Link>
            </div>
          </div>
          <div className={`col-md-6 ${classes.imgSec}`}>
            <img
              src={`${dataUrl}/banners/${randomBanners[0]?.image}`}
              alt="about-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
