import React from "react";
import useStyles from "./styles";

export default function OrderBox(props) {
  const classes = useStyles();

  const openModal = () => {
    props.handleOpen();
    props.setOrder(props.product);
    props.setOrderAmount(props.product.item_amount);
  }
  return (
    <>
      <div className={`col-lg-4 col-md-6`}>
        <div
          onClick={openModal}
          className={`${classes.card} ${
            props.product.payment_status === "Paid"
              ? classes.paid
              : classes.pending
          } `}
        >
          <div className={classes.cardArrow}>
            <i className="fa fa-angle-right"></i>
          </div>
          <div className={classes.cardBody}>
            <h5
              className={`${classes.status} ${
                props.product.payment_status === "Paid"
                  ? classes.paymentPaid
                  : classes.paymentPending
              }`}
            >
              Order Status: {props.product.payment_status}
            </h5>
            <h5 className={classes.orderId}>
              Order Id: {props.product.order_id}
            </h5>
            <h6 className={classes.orderAmount}>
              Order Amount: <i className="fa fa-inr mr-1" aria-hidden="true"></i>{props.product.item_amount}
            </h6>
            <p> Placed on: {props.product.order_date}</p>
          </div>
        </div>
      </div>
    </>
  );
}
