import React from "react";
import LogoSection from "./LogoSection";
import SocialBar from "./SocialBar";
import SideBar from "../SideBar";
import MobileHeader from "../MobileHeader";
import useStyles from "./styles";

const Header = () => {

  const classes = useStyles();
  return (
    <div className={classes.headerSticky}>
      <SocialBar />
      <div className="web-header">
        <LogoSection />
        <SideBar />
      </div>
      <div className="mobile-header">
        <MobileHeader />
      </div>
    </div>
  );
};

export default Header;
