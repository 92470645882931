import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  myAddressesSec: {
    padding: "50px 0",
    textAlign: "center",
    '@media (max-width: 767px)': {
      padding: "30px 0",
    }
  },
  loaderSec: {
    padding: '50px 0px',
    textAlign: 'center',
  },
  loader: {
      textAlign: 'center',
      width: 50,
      height: 50,
  },
  heading: {
    marginBottom: 50,
    '@media (max-width: 767px)': {
      marginBottom: 20,
    }
  },
  form: {
    // boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
    border: '1px solid #e6e6e6',
    padding: '20px 20px 10px 20px',
    maxWidth: '620px',
    width: '100%',
    '@media (max-width: 767px)': {
      maxWidth: 'inherit',
      width: '100%',
    },
  },
  col: {
    borderBottom: '1px solid #e0e0e0',
    marginBottom: 15,
    padding: '0 0 15px 0',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    }
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  select: {
    width: '8%',
  },
  radioImg: {
    width: 20,
    height: 20
  },
  centerCol: {
    textAlign: 'left',
    width: '92%',
  },
  addressName: {
    fontSize: 15,
    '@media (max-width: 767px)': {
      fontSize: 14,
    }
  },
  name: {
    fontSize: 15,
    fontWeight: 'normal',
    '@media (max-width: 767px)': {
      fontSize: 13,
    }
  },
  edit: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    fontSize: 18,
    color: COLORS.primary,
  },
  submit: {
    width: '100%',
    border: 'none',
    marginTop: 20,
    background: COLORS.primary,
    color: '#fff',
    padding: '10px 20px',
    maxWidth: '650px',
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: COLORS.secondary,
    }
  },
  button: {
    border: `1px solid ${COLORS.lightGrey1}`,
    padding: '4px 15px',
    fontSize: '13px',
    borderRadius: 5,
    marginRight: 8,
    marginTop: 10,
    background: '#fff'
  },
  addAddressBtn: {
    padding: '13px 20px',
    border: '1px solid #e0e0e0',
    marginTop: 10,
    maxWidth: '620px',
    width: '100%',
    color: COLORS.primary,
    cursor: 'pointer',
    fontSize: 13,
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 60,
  },
}));


export default useStyles