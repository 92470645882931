import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { Box } from "@mui/material";
import ShippingProPreview from "../Shipping/ShippingProPreview";
import OrderSummary from "../OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import PaymentSummary from "./PaymentSummary";
import { verifyPayment } from "../../actions/orders";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { CLEAR_CART } from "../../constants/actionTypes";
import { authKey_local_storage_key } from "../../helpers/common";
import { COLORS } from "../../constant/colors";
import useRazorpay from "react-razorpay";
import moment from "moment";
import MetaTags from "../Common/MetaTags";

export default function Checkout() {
  const classes = useStyles();
  const Razorpay = useRazorpay();

  const location = useLocation();
  const shippingData = location?.state?.order;
  const cartData = useSelector((state) => state.cart.data);
  const [loader, setLoader] = useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("onlinePayment");
  const delivery_preferred_date_time = localStorage.getItem("delivery_preferred_date_time");
  const token = localStorage.getItem(authKey_local_storage_key);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!shippingData) {
      navigate('/shipping')
    }
  }, [shippingData, navigate])
  

  const getCourierCharge = () => {
    return cartData?.reduce(
      (acc, product) => acc + product.delivery_charges * product.qty,
      0
    );
  };

  const verifyPaymentHandle = useCallback(
    (data) => {
      setLoader(true);
      verifyPayment(token, data)
        .then((response) => {
          setLoader(false);

          // order successfull
          if (response?.data?.payment_status === "Pending") {
            dispatch({ type: CLEAR_CART });
            dispatch(showSuccessSnackbar("Order placed successfully"));
            navigate("/orders");
            // history.replace("/orders");
            // x: redirect to the orders page
          }
        })
        .catch((error) => {
          if (
            error?.message?.includes("401") ||
            error?.message?.includes("403")
          ) {
            dispatch(
              showErrorSnackbar("Session expired. Please login and try again.")
            );
          }
          setLoader(false);
          dispatch(showErrorSnackbar("Something went wrong. Please try again"));
        });
    },
    [setLoader, token, dispatch, navigate]
  );

  const payNow = useCallback(() => {
    if (paymentMethod === "COD") {
      verifyPaymentHandle({
        order_id: shippingData?.order.order_id,
        payment_option: "COD",
        payload: {},
      });
    }

    if (paymentMethod === "onlinePayment") {
      let options = {
        key: shippingData?.settings?.api_details?.api_key,
        amount: shippingData?.order.total_amount * 100,
        currency: "INR",
        name: "Avon",
        order_id: shippingData?.order.payment_gateway_order_id,
        description: "Online Order: " + shippingData?.customer?.name,
        image: `https://avonnamkeen.com/backend/data/assets/1646322517-logo.png`,
        handler: async function (response) {
          try {
            setLoader(true);
            const requestBody = {
              order_id: shippingData?.order.order_id, // don't change this
              payment_option: "razorpay",
              payload: {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              },
            };
            await verifyPayment(token, requestBody);
            // await updateUserCart("", token);

            dispatch({ type: CLEAR_CART });

            dispatch(
              showSuccessSnackbar(
                "Your Order has been executed successfully.",
                "success"
              )
            );

            navigate("/orders");
          } catch (err) {
            setLoader(false);
            console.log(err);
          }
        },
        prefill: {
          name: shippingData?.customer?.name, // customer name
          contact: shippingData?.customer?.mobile_number,
        },
        notes: {
          address: shippingData?.customer?.address,
        },
        theme: {
          color: COLORS.primary,
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.open();
    }
  }, [
    paymentMethod,
    verifyPaymentHandle,
    shippingData,
    Razorpay,
    token,
    dispatch,
    navigate,
  ]);

  const totalQuantity = () => {
    return cartData?.reduce((acc, item) => item.qty + acc, 0);
  };

  const totalPrice = useCallback(() => {
    return cartData?.reduce(
      (acc, item) => item.selling_price * item.qty + acc,
      0
    );
  }, [cartData]);

  return (
    <section>
      <MetaTags title='Checkout Details' description='' keywords=''></MetaTags>

      <div className="container">
        <div className={classes.checkoutSec}>
          <h2 className={classes.heading}>Checkout</h2>

          <div className="row">
            <div className="col-lg-8 col-md-7">
              <Box className={classes.leftCol}>
                <div className={classes.detailBox}>
                  <ul className={classes.ul}>
                    {/* <li className={classes.li}>
                      <span>Email:</span> {shippingData?.customer?.email}
                    </li> */}
                    <li className={classes.li}>
                      <span>Name:</span> {shippingData?.customer?.name}
                    </li>
                    <li className={classes.li}>
                      <span>Address:</span> {shippingData?.customer?.address}, {shippingData?.customer?.city}
                    </li>
                    <li className={classes.li}>
                      <span>Pin Code:</span> {shippingData?.customer?.pin_code}
                    </li>
                    <li className={classes.li}>
                      <span>Mobile Number:</span>{" "}
                      {shippingData?.customer?.mobile_number}
                    </li>
                  </ul>
                </div>

                <PaymentSummary
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />

                <div className={classes.btnSec}>
                  <Link className={classes.btnLink} to="/cart">
                    <i className="fa fa-arrow-left"></i> Go Back
                  </Link>
                </div>
              </Box>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="row flex-column">
                <ShippingProPreview taxCharge="*Calculated at Next Step" />

                {delivery_preferred_date_time && (
                  <div className={classes.orderTime}>
                    <h3 className={classes.orderTimeHeading}>
                      Delivery Date & Time
                    </h3>
                    <div className={classes.orderTimeBody}>
                      {moment(
                        delivery_preferred_date_time
                      ).format("Do MMMM YYYY, h:mm a")}

                      <i
                        className="fa fa-edit"
                        onClick={() => navigate("/cart")}
                      />
                    </div>
                  </div>
                )}

                <div className="mt-4">
                  <OrderSummary
                    loading={loader}
                    totalQuantity={totalQuantity}
                    totalPrice={totalPrice}
                    redirect={payNow}
                    order={shippingData?.order}
                    buttonText={
                      paymentMethod === "COD" ? "Place Order" : "Pay Now"
                    }
                    shippingCharges={getCourierCharge()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* main */}
    </section>
  );
}
