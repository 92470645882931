/* eslint-disable linebreak-style */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerDetails, logout } from "./actions/customer";
import Layout from "./components/Layout";
import "./index.css";
import { showErrorSnackbar } from "./actions/snackbar";
import { customerId_local_storage_key } from "./helpers/common";
import { getSettings } from "./actions/settings";
import "./App.css";
import AdModal from "./components/AdModal";
import SEOTags from "./components/Common/SEOTags";

const PATHS = [];

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const customerId = localStorage.getItem(customerId_local_storage_key);
  const adBanner = useSelector((state) => state.banners["Website Ad Banners"]);

  useEffect(() => {
    PATHS.forEach((path) => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;

      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerDetails(customerId)).then((res) => {
        if (res?.message?.includes("401") || res?.message?.includes("403")) {
          dispatch(showErrorSnackbar("Session expired. Please login again."));
          dispatch(logout());
        }
      });
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setLoading(false);
    });
  }, [dispatch]);

  if (loading) {
    return (
      <div className='main-loader-sec'>
        <img className='main-loader' src='/images/loader1.gif' alt='loader' />
      </div>
    );
  }

  return (
    <>
      <SEOTags />

      {adBanner?.length > 0 ? <AdModal /> : null}
      <Layout />
    </>
  );
}

export default App;
