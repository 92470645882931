import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import DropdownMenuWeb from './DropdownMenuWeb';
import { getMenusByCategory } from "../../../helpers/common";

import { getLabeledProducts } from "../../../actions/products";

const DropdownMenu = ({sideBarRef, open}) => {

    const dispatch = useDispatch();

    const menus = useSelector((state) => state.menus.menus);
    const topMenus = getMenusByCategory(menus, 'top-menu-dropdown') ?? [];
    const labeledProducts = useSelector((state) => state.products.labeledProducts) ?? {};
    const categories = useSelector((state) => state.categories.data) ?? [];

    useEffect(() => {
        dispatch(getLabeledProducts('Best Sellers'));
        dispatch(getLabeledProducts('Todays Deal'));
        dispatch(getLabeledProducts('New Arrival'));
    }, [dispatch]);

    return <>
        <DropdownMenuWeb topMenus={topMenus} categories={categories} labeledProducts={labeledProducts} />
    </>

}

export default DropdownMenu;