import CmsPage from '../../components/CmsPage';
import ProductListing from '../../components/ProductListing';
import SingleProduct from '../../components/SingleProduct';
import ContactUs from '../../components/ContactUs';
import Cart from '../../components/Cart';
import Shipping from '../../components/Shipping';
import Checkout from '../../components/Checkout';
import SearchResult from '../../components/SearchResult';
import Profile from '../../components/Profile';
import Orders from '../../components/Orders';
import SelectAddress from '../../components/SelectAddress';
import MyAddresses from '../../components/MyAddresses';
import AddAddress from '../../components/MyAddresses/AddAddress';
import UpdateAddress from '../../components/MyAddresses/UpdateAddress';
import UpdateProfile from '../../components/Profile/UpdateProfile';
import ProductsByLabels from '../../components/ProductsByLables';
import LoginPage from '../../components/LoginPage';


const commonRoutes = [
  { path: '/category/:categoryId/:categoryName/:currentPage', element: <ProductListing /> },
  { path: '/category/:categoryId/:currentPage', element: <ProductListing /> },
  { path: '/products', element: <ProductListing /> },
  { path: '/product/:productId/:productName/:currentPage', element: <ProductListing /> },
  { path: '/products/label/:labelName', element: <ProductsByLabels /> },
  { path: '/category/:categoryId/:categoryName/:pageNumber/filters', element: <ProductListing /> },
  {
    path: "/product/:productId/:productName",
    element: <SingleProduct />
  },
  { path: "/cart", element: <Cart /> },
  { path: "/contact-us", element: <ContactUs/> },
  { path: "/pages/:page", element: <CmsPage/> },
  { path: '/search/:keyword', element: <SearchResult /> },
  { path: '/login', element: <LoginPage /> },
];

const authProtectedRoutes = [
  { path: "/profile", element: <Profile /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  { path: "/shipping", element: <Shipping/> },
  { path: "/checkout/:orderId", element: <Checkout/> },
  { path: "/select-address", element: <SelectAddress/> },
  { path: "/my-addresses", element: <MyAddresses/> },
  { path: "/update-address/:addressId", element: <UpdateAddress/> },
  { path: "/new-address", element: <AddAddress/> },
  { path: "/orders", element: <Orders /> },
];

const publicRoutes = [
  // { path: '/signup', component: SignUp },
  // { path: '/forget-password', component: ForgetPassword }
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
