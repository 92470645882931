export const apiUrl = "https://avonnamkeen.com/backend_roi/api/web";
export const dataUrl = "https://avonnamkeen.com/backend_roi/data";
export const appUrl = "https://avonnamkeen.com";
export const appLogoUrl = "https://avonnamkeen.com/backend_roi/data/assets/1698223085-avon-logo.png";
export const appName = "Avon Namkeen";

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`;

export const sortFn = (el1, el2, sortKey = "display_order") =>
  el1[sortKey] > el2[sortKey] ? 1 : -1;

export const imageUrl = (url, prefix = "products", sizePrefix = "120x120-") =>
  `${dataUrl}/${prefix}/${sizePrefix}${url}`;

export const getMenusByCategory = (menus, categoryName) =>
  menus.filter((menu) => menu.category === categoryName);

export const productImageUrl = (
  url,
  prefix = "products",
  id = "1",
  sizePrefix = "120x120-"
) => `${dataUrl}/${prefix}/${id}/${sizePrefix}${url}`;

export const testimonialimageUrl = (url, prefix = "products") =>
  `${dataUrl}/${prefix}/${url}`;

export const convertToSlug = (text) =>
  text
    ?.toLowerCase()
    ?.replace(/[^\w ]+/g, "")
    ?.replace(/ +/g, "-");

export const getContentByName = (contents, name, defaultValue) =>
  contents.find((content) => content.name === name) || defaultValue;

export const getContentBySlot = (contents, name, defaultValue) =>
  contents.find((content) => content?.slot === name) || defaultValue;

export const getCategoryByName = (categories, name) => {
  return categories.find(
    (category) => category.name.toLowerCase() === name.toLowerCase()
  );
};

// export const getWordCount = (str) => {
//   return str.split(" ").length;
// }
export const getWordCount = (str) => {
  return str?.match(/\S+/g)?.length;
};

export const getCategoriesByParentName = (categories, parentName) => {
  let parentId = getCategoryByName(categories, parentName)?.id;
  return categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );
};

export const filterByCategoryName = (contents, categoryName, defaultValue) =>
  contents.filter((content) => content.category === categoryName) ||
  defaultValue;

export const getCategoryLink = (category) =>
  `/category/${category.id}/${category.url_slug}/1`;

export const getContentByCategoryName = (
  contents,
  categoryName,
  defaultValue
) =>
  contents.find((content) => content.category === categoryName) || defaultValue;

export const getSettingByKey = (settings, keyName, defaultValue) =>
  settings?.find((setting) => setting.key === keyName) || defaultValue;

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getParsedJson = (json, defaultValue = "[]") => {
  let parsedJson = defaultValue;

  try {
    if (isJsonString(json)) {
      parsedJson = JSON.parse(json ?? defaultValue);
    }

    if (isJsonString(parsedJson)) {
      parsedJson = JSON.parse(parsedJson ?? defaultValue);
    }
  } catch (error) {
    parsedJson = JSON.parse(defaultValue);
  }

  return parsedJson;
};

export const getTestimonialByName = (
  clienttestimonials,
  company,
  defaultValue
) =>
  clienttestimonials.find(
    (clienttestimonial) => clienttestimonial.company === company
  ) || defaultValue;

export const getTestimonialByCompanyName = (clienttestimonials, companyName) =>
  clienttestimonials.filter(
    (clienttestimonial) => clienttestimonial.company === companyName
  );

export const getCategoryByParentId = (categories, parentId) =>
  categories.filter(
    (category) => parseInt(category.parent_id) === parseInt(parentId)
  );

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator) => {
  let arr = [];

  if (value) {
    arr = value
      .split(seperator)
      .filter(Boolean)
      .map((v) => v.trim())
      .filter((v) => v !== "");
  }
  return arr;
};

// check img exists or not in the given url
export const checkImgExists = (url) => {
  var img = new Image();
  img.src = url;
};

//  check img exists or not in the given url
export function imageExists(image_url) {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status !== 404;
}

// it is used to get the image url from the given html.
// export const getImageUrl = (html) => {
//     let img = html.match(/<img.*?src="(.*?)"/);
//     // let regex = /src="(.*?)"/i;
//     // let match = regex.exec(html);
//     return match[1];
// }

export const getUrlParams = (paramName, paramValue) => {
  // let url = 'https://avonnamkeen.com/category/10/namkeens/filter?price_range=100-500&taste=Sweet&weight=100';

  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.set(paramName, paramValue);

  // returns => price_range=100-500&taste=Sweet&weight=100
  return urlObj.searchParams.toString();
};

export const removeUrlParam = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.delete(paramName);
  return urlObj.searchParams.toString();
};

export const getAppliedFilters = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  return urlObj.searchParams.get(paramName);
};

export const getFilterPath = (categoryId, categoryName, pageNumber, params) => {
  return `/category/${categoryId}/${categoryName}/${pageNumber}/filters?${params}`;
};

export const getImageUrl = (html) => {
  let img = html.match(/<img.*?src="(.*?)"/);
  return img[1];
};

export function getFirstCharacter(fullName) {
  let fname = fullName.replace(/\s+/g, " ").trim();
  fname = fname.split(" ");
  let firstNames = [];
  if (fname[0]) {
    if (fname[0][0]) {
      firstNames.push(fname[0][0]);
    }
  }
  if (fname[1]) {
    if (fname[1][0]) {
      firstNames.push(fname[1][0]);
    }
  }
  return firstNames.join("").toUpperCase();
}

export function splitArray(arr) {
  if (arr.length < 8) {
    const firstPart = arr.slice(0, 4);
    const secondPart = arr.slice(4);
    return [firstPart, secondPart];
  } else {
    const midIndex = Math.floor(arr.length / 2);
    const firstPart = arr.slice(0, midIndex);
    const secondPart = arr.slice(midIndex);
    return [firstPart, secondPart];
  }
}

export const customerId_local_storage_key = "avon_customerId";
export const authKey_local_storage_key = "avon_authToken";
export const cartData_local_storage_key = "avon_cart_data";
export const userInfo_local_storage_key = "avon_userInfo";
