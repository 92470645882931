import React from "react";
import { Box, Divider} from '@mui/material';
import { useSelector } from 'react-redux';
import { productImageUrl } from '../../helpers/common';
import useStyles from "./styles";

export default function ShippingProPreview(props) {
const cartData = useSelector(state => state.cart.data);
const classes = useStyles();

  return (
    <Box flex={1} className="pro-preview">
      <Box flex={1} color="#666" fontSize={15} fontWeight={500} pb={2} mb={3} >
        Product Details - {cartData.length} item(s)
      </Box>

      <Box mt={-3}>
          {cartData.map((product) => (
            <Box
              key={product.id}
              p={1}
              display="flex"
              fullwidth="true"
              alignItems="center"
            >
              <Box width={60} height={60}>
                <img
                  src={productImageUrl(
                    product.main_image,
                    "products",
                    product.id
                  )}
                  alt="cart-item"
                />
              </Box>

              <Box flex={1} ml={2}>
                <Box className={classes.productName} >
                  {product.name}
                </Box>
                <Box className={classes.productPrice}><i className="fa fa-inr mr-1" aria-hidden="true"></i>{product.selling_price} x {product.qty}</Box>
              </Box>

              <Box className={classes.productTotalPrice} mx={3} color="#666">
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>{product.selling_price * product.qty}
              </Box>
            </Box>
          ))}
      </Box>
      <Divider fullWidth />


    </Box>
  );
}
