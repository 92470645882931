import { contactUs } from '../api/contacts';
import { CONTACT_US_SUCCESS, CONTACT_US_FAILURE } from '../constants/actionTypes';

import { createAction } from 'redux-actions';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbar';

const contactUsSuccess = createAction(CONTACT_US_SUCCESS);
const contactUsFailure = createAction(CONTACT_US_FAILURE);

export function postContactUs(params) {
  return dispatch => contactUs(params)
    .then(json => {
      dispatch(contactUsSuccess(json));
      dispatch(showSuccessSnackbar('Your message sent successfully'));
      return json;
    })
    .catch(error => {
      dispatch(contactUsFailure(error));
      dispatch(showErrorSnackbar('Something went wrong! Please try again later.'))
      // error.message.indexOf('422') > -1 ? 'Sorry Login first' : ''
      return error;
    });
}
