import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { dataUrl } from "../../../helpers/common";

function BannerCarousel() {
  const banners = useSelector((state) => state.banners["Website Homepage Banners"]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 481 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const classes = useStyles();

  return (
    <div className={classes.bannerSection}>
      <Carousel
        draggable={true}
        showDots={false}
        keyBoardControl={true}
        responsive={responsive}
        infinite={true}
        partialVisible={"false"}
        removeArrowOnDeviceType={["mobile"]}
        dotListClass="custom-dot-list-style"
        autoPlay
        autoPlaySpeed={3000}
        arrows={false}
      >
        {banners?.map((banner) => (
          <div key={'homepageBanners-' + banner.id} className={classes.bannerSec}>
            <Link to={banner?.url} >
              <img
                className={classes.bannerImg}
                src={`${dataUrl}/banners/1500x600-${banner.image}`}
                alt={banner?.name}
              />
            </Link>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default BannerCarousel;
