import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getErrorMsg } from "../../actions/customer";
import { resendOtp } from "../../api/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";


const useResendOTP = ({
  maxTime,
  onTimerComplete,
  timeInterval,
  onResendClick,
  setResendLoading,
  phone
}) => {

  const timeout = useRef();

  const [remainingTime, setRemainingTime] = useState(maxTime);
  const dispatch = useDispatch();

  useEffect(() => {
    if (timeout.current && remainingTime === 0) {
      clearTimeout(timeout.current);
      if (onTimerComplete) {
        onTimerComplete();
      }
    } else {
      timeout.current = setTimeout(() => {
        setRemainingTime(t => t - 1);
      }, timeInterval);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [onTimerComplete, remainingTime, timeInterval]);

  const handelResendClick = () => {
    setResendLoading(true);
    if (onResendClick) {
      onResendClick(remainingTime === 0);
    }


    // We are directly calling the api without dispatching.
    resendOtp({mobile_number: phone}).then(result => {
      if (result.data.message) {
        // showMessage(result.data.message, "success");
        dispatch(showSuccessSnackbar(result.data.message))
      }
      setResendLoading(false);
      setRemainingTime(maxTime);

    }).catch(err => {
      dispatch(showErrorSnackbar(getErrorMsg(err)))
      setResendLoading(false);
    }
    );
  };


  return {
    handelResendClick,
    remainingTime
  };
};

export default useResendOTP;