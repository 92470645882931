import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

export default makeStyles(() => ({
  categorySec: {
    marginBottom: 40,
    "@media (max-width: 767px)": {
      marginBottom: 30,
    },
    '& .productSliderSec': {
      marginBottom: 15
    }
  },
  heading: {
    textAlign: "center",
    marginBottom: 40,
    "@media (max-width: 767px)": {
      marginBottom: 20,
    },
  },
  catContainer: {
    display: "flex",
    gap: 10,
    marginBottom: 30,
    padding: "0 15px",
    flexWrap: 'wrap',
    justifyContent: "center",
  },
  catChip: {
    cursor: "pointer !important",
    minWidth: 100,
  },
  activeCatChip: {
    backgroundColor: COLORS.primary + " !important",
    color: "#fff !important",
  },
  notFoundText: {
    padding: "30px 15px",
    textAlign: "center",
    "@media (max-width: 500px)": {
      paddingTop: 15
    },
  },
}));
