import { createAction } from 'redux-actions';
import * as Auth from '../api/customer';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_ADDRESS_FAILURE,
  GET_CUSTOMER_ADDRESS_LOADING
} from '../constants/actionTypes';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar';

const signUpSuccess = createAction(SIGNUP_SUCCESS)
const signUpFailure = createAction(SIGNUP_FAILURE);

export function signUp(params) {
  return dispatch => Auth.signUpRequest(params)
    .then((json) => {
      dispatch(signUpSuccess(json));
      dispatch(showSuccessSnackbar('An OTP has been sent to your mobile number!'))
      return true;
    })
    .catch(error => {
      dispatch(signUpFailure(error));
      return false;
    });
}

const verifyOtpSuccess = createAction(VERIFY_OTP_SUCCESS);
const verifyOtpFailure = createAction(VERIFY_OTP_FAILURE);

export function verifyOtp(params) {
  return dispatch => Auth.verifyOtp(params)
    .then(json => {
      dispatch(showSuccessSnackbar('OTP verified successfully'));
      dispatch(verifyOtpSuccess(json));
      return true;
    })
    .catch(error => {
      dispatch(showErrorSnackbar('OTP verification failed ! Please try again !'));
      dispatch(verifyOtpFailure(error));
      return false;
    })
}

const loginSuccess = createAction(LOGIN_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);

export function login(params) {
  return dispatch => Auth.login(params)
    .then(json => {
      dispatch(loginSuccess(json.data));
      dispatch(showSuccessSnackbar('Logged in successfully'))
      return json;
    })
    .catch(error => {
      console.error(error);
       dispatch(showErrorSnackbar('Invalid OTP! Please try again.'))
      dispatch(loginFailure({ error: 'Invalid mobile number or Otp' }));
      return error;
    });
}

const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS);
const updateProfileFailure = createAction(UPDATE_PROFILE_FAILURE);

export function updateProfile(params) {
  return dispatch => Auth.updateProfile(params)
    .then(json => {
      dispatch(updateProfileSuccess(json));
      return json;
    })
    .catch(error => {
      dispatch(updateProfileFailure(error));
      return error;
    })
}

const getProfileSucess = createAction(GET_CUSTOMER_SUCCESS);
const getProfileFailure = createAction(GET_CUSTOMER_FAILURE);

export function getCustomerDetails(userId) {
  return dispatch => Auth.getCustomerDetails(userId)
    .then((json) => {
      dispatch(getProfileSucess(json));
      return json;
    })
    .catch(error => {
      getProfileFailure(error);
      return error;
    })
}

const logoutSuccess = createAction(LOGOUT_SUCCESS);

export function logout() {
  return dispatch => dispatch(logoutSuccess());
}

const forgetPasswordSuccess = createAction(FORGET_PASSWORD_SUCCESS);
const forgetPasswordFailure = createAction(FORGET_PASSWORD_FAILURE);

export function forgetPassword(params) {
  return dispatch => Auth.forgetPassword(params)
    .then((json) => {
      dispatch(forgetPasswordSuccess(json));
      dispatch(showSuccessSnackbar(`An OTP sent to your mobile number +${params.mobile_number}`));
      return true;
    }).catch(error => {
      dispatch(showErrorSnackbar('The mobile number may be wrong!'))
      dispatch(forgetPasswordFailure(error));
      return false;
    });
}


const customerAddressSuccess = createAction(GET_CUSTOMER_ADDRESS_SUCCESS);
const customerAddressFailure = createAction(GET_CUSTOMER_ADDRESS_FAILURE);

export function getCustomerAddress() {
  return dispatch => {
    dispatch({ type: GET_CUSTOMER_ADDRESS_LOADING });
    return Auth.getAddress()
      .then(json => dispatch(customerAddressSuccess({ data: json.data })))
      .catch(error => dispatch(customerAddressFailure(error)));
  };
}

export const getErrorMsg = (err) => {
  let msg = "Something went wrong! Please try again later!";
  if (err.response && err.response.data && err.response.data[0] && err.response.data[0].message) {
      msg = err.response.data[0].message;
  } else if (err.response && err.response.data && err.response.data.message) {
      msg = err.response.data.message;
  }
  return msg;
}