import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorSnackbar } from "../../actions/snackbar";
import useStyles from "./styles";
import { authKey_local_storage_key } from "../../helpers/common";
import AddressSelectForm from "./AddressSelectForm";
import MetaTags from "../Common/MetaTags";

export default function MyAddresses() {
  const classes = useStyles();
  const navigate = useNavigate();
  const addressList = useSelector((state) => state.customers.address);
  const dispatch = useDispatch();
  const token = localStorage.getItem(authKey_local_storage_key);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // const delivery_preferred_date_time = localStorage.getItem(
  //   "delivery_preferred_date_time"
  // );

  useEffect(() => {
    if (!token) {
      dispatch(
        showErrorSnackbar("You are not logged out ! Please login to continue")
      );
      navigate("/");
    }
  }, [token, navigate, dispatch]);

  // TODO: remove this
  // const checkout = useCallback(() => {
  //   const items = cartData.map((d) => {
  //     return {
  //       item_id: d.id,
  //       quantity: d.qty,
  //     };
  //   });

  //   let coupon_code = "";

  //   callOrdersCheckoutApi(dispatch, token, {
  //     customer: {
  //       name: selectedAddress.contact_person,
  //       address_id: selectedAddress.id,
  //     },
  //     items,
  //     coupon_code,
  //     delivery_preferred_date_time: delivery_preferred_date_time || "",
  //   })
  //     .then((response) => {
  //       if (response.status === 200 && response.data) {
  //         navigate(`/checkout/${response.data.order.order_id}`, {
  //           state: { order: response.data },
  //         });
  //       } else {
  //         dispatch(showErrorSnackbar("Something went wrong. Please try again"));
  //       }
  //     })
  //     .catch((error) => {
  //       if (error?.response?.data?.message) {
  //         dispatch(showErrorSnackbar(error.response.data.message));
  //       } else {
  //         dispatch(showErrorSnackbar("Something went wrong. Please try again"));
  //       }
  //     })
  //     .finally(() => {
  //       setLoader(false);
  //     });
  // }, [
  //   cartData,
  //   dispatch,
  //   token,
  //   selectedAddress,
  //   delivery_preferred_date_time,
  //   navigate,
  // ]);

  return (
    <div>
      <MetaTags title='Select Address' description='' keywords=''></MetaTags>

      <div className='container'>
        <div className={classes.myAddressesSec}>
          <h2 className={classes.heading}>Select Address</h2>

          {addressList.length > 0 && (
            <AddressSelectForm
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              addressList={addressList}
            />
          )}
        </div>
      </div>
    </div>
  );
}
