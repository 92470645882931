import React, { useCallback, useState, useEffect } from "react";
import {
  getSettingByKey,
  toArray,
} from "../../helpers/common";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { updateCartData } from "../../helpers/cart";

import { updateCart } from "../../actions/cart";
import { UPDATE_CART } from "../../constants/actionTypes";
import OrderSummary from "../OrderSummary";
import Login from "../Login";
import { showErrorSnackbar } from "../../actions/snackbar";
import CouponCode from "./CouponCode";
import CartItemsWeb from "./CartItemsWeb";
import CartItemsMobile from "./CartItemsMobile";
import moment from "moment";
import DeliveryDateTime from "./DeliveryDateTime";
import { TextField } from "@mui/material";
import MetaTags from "../Common/MetaTags";

const Cart = () => {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);
  const authToken = localStorage.getItem("avon_authToken");
  const settings = useSelector((state) => state.settings.data);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const handleLoginOpen = () => setOpenLoginModal(true);
  const handleLoginClose = () => setOpenLoginModal(false);
  const [couponCode, setCouponCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [isDateTimeApplied, setIsDateTimeApplied] = useState(false);

  const preferredDeliveryCateIdsStr =  getSettingByKey(settings, 'preferred_delivery_category_ids')?.value
  const preferredDeliveryCateIds = toArray(preferredDeliveryCateIdsStr, ',')
 
  useEffect(() => {
    const findDateTime = cartData.filter((item) =>
    preferredDeliveryCateIds.includes(item?.category_id?.toString())
    );
    if (findDateTime.length > 0) {
      setIsDateTimeApplied(true);
    } else {
      setIsDateTimeApplied(false);
      localStorage.removeItem("delivery_preferred_date_time");
    }
  }, [cartData, isDateTimeApplied, preferredDeliveryCateIds]);

  const totalPrice = useCallback(() => {
    return cartData?.reduce(
      (acc, item) => item.selling_price * item.qty + acc,
      0
    );
  }, [cartData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const removeItem = (product) => {
    const newCartData = updateCartData(cartData, product, 0);
    dispatch(updateCart(newCartData));
    dispatch(
      showErrorSnackbar(`Product "${product.name}" removed from the cart`)
    );
  };

  const minimumOrderAmount = getSettingByKey(settings, "minimum_order_amount");

  const updateCartProduct = useCallback(
    (product, action) => {
      let newQty = product.qty;

      if (action === "increase") {
        newQty = product.qty + 1;
      }

      if (action === "decrease") {
        newQty = product.qty - 1;
      }

      // atlease one qty must be there in cart.
      if (newQty === 0) {
        return;
      }

      // customer can not add more products than the available stock.
      if (newQty > product.available_stock) {
        dispatch(
          showErrorSnackbar(
            `No more stock is available for the Product '${product.name}'`
          )
        );
        return;
      }

      const newCartData = updateCartData(cartData, product, newQty);
      dispatch({ type: UPDATE_CART, payload: newCartData });
    },
    [dispatch, cartData]
  );

  const totalQuantity = () => {
    return cartData?.reduce((acc, item) => item.qty + acc, 0);
  };

  const redirect = useCallback(() => {
    if (authToken) {
      if (isDateTimeApplied) {
        if (dateTime) {
          localStorage.setItem(
            "delivery_preferred_date_time",
            moment(dateTime).format("YYYY-MM-DD HH:mm:ss")
          );
        } else {
          dispatch(showErrorSnackbar("Please select delivery date and time"));
          return;
        }
      }

      if (totalPrice() < minimumOrderAmount?.value) {
        dispatch(
          showErrorSnackbar(
            `Minimum order amount must be ₹ ${minimumOrderAmount?.value}`
          )
        );
      } else {
        navigate("/shipping", { state: { couponCode, remark } });
      }
    } else {
      handleLoginOpen();
    }
  }, [
    authToken,
    isDateTimeApplied,
    totalPrice,
    minimumOrderAmount?.value,
    dateTime,
    dispatch,
    navigate,
    couponCode,
    remark
  ]);

  return (
    <div className="container">
      <MetaTags title='Shopping Cart' description='' keywords=''></MetaTags>
      
      <Login
        openModal={openLoginModal}
        handleOpen={handleLoginOpen}
        setOpenModal={setOpenLoginModal}
        handleClose={handleLoginClose}
      />
      <div className={classes.cartSec}>
        {cartData.length > 0 ? (
          <h2 className={classes.heading}>Shopping Cart</h2>
        ) : null}

        {cartData.length > 0 ? (
          <>
            <div className={classes.warningSec}>
              <Alert severity="warning">
                min order amount should be{" "}
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                {minimumOrderAmount?.value}
              </Alert>
            </div>
            <div className="row">
              <div className="col-md-8">
                <CartItemsWeb
                  removeItem={removeItem}
                  updateCartProduct={updateCartProduct}
                />
                <CartItemsMobile
                  removeItem={removeItem}
                  updateCartProduct={updateCartProduct}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={remark}
                  inputProps={{
                    style: {
                      padding: "27px 10px",
                      fontSize: "14px",
                    },
                  }}
                  variant="outlined"
                  label="Your Remark"
                  placeholder="Anything you want to add along with delivery or order? "
                  onChange={(e) => setRemark(e.target.value)}
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                />
              </div>
              <div className="col-md-4">
                {isDateTimeApplied && (
                  <DeliveryDateTime
                    dateTime={dateTime}
                    setDateTime={setDateTime}
                  />
                )}

                <CouponCode
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  handleLoginOpen={handleLoginOpen}
                />
                <OrderSummary
                  totalQuantity={totalQuantity}
                  totalPrice={totalPrice}
                  redirect={redirect}
                  shippingCharges="Free"
                  buttonText="Checkout"
                  order={""}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={classes.emptyCartSec}>
            <img
              className={classes.emptyCartImg}
              src="/images/emptycart.png"
              alt="empty-cart"
            />
            <h3 className={classes.emptyText}>Cart is Empty</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
