
export const COLORS = {
   // primary: "#ed3338",
  primary: "#a9253c",
  secondary: "#ffce2a",
  golden: '#beb163',
  danger: '#ed1c24',
  darkGrey1: "#333333",
  darkGrey2: "#444444",
  lightGrey: "#cbcbcb",
  lightGrey1: "#777777",
  lightGrey2: "#696969",
  white: "#ffffff",
  black: "#000000",
  success: "#00b300",
};