import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

export default makeStyles(() => ({
  boxRow: {
    "@media (max-width: 767px)": {
      padding: "0 10px",
    },
  },
  myCol: {
    padding: "15px !important",
    textAlign: "center",
    margin: "0 auto",
    transition: "all 0.3s ease-in-out",
    "& h3": {
      transition: "all 0.3s ease-in-out",
    },
    "&:hover": {
      transform: "translateY(-10px)",
      "& h3": {
        color: COLORS.secondary,
      },
    },
    "@media (max-width: 767px)": {
      padding: "0px 8px 15px !important",
      "& h3": {
        fontSize: "14px !important",
        marginBottom: "2px !important",
        fontWeight: "400 !important",
      },
    },
    "@media (max-width: 467px)": {
      "& h3": {
        fontSize: "12px !important",
      },
    },
  },
  heading: {
    fontSize: "26px",
    position: "relative",
    marginBottom: "40px",
    textTransform: "capitalize",
    "&::before": {
      content: '""',
      width: "50px",
      borderRadius: 15,
      height: 4,
      position: "absolute",
      bottom: "-13px",
      left: "0",
      background: COLORS.primary,
    },
    "@media (max-width: 767px)": {
      fontSize: "20px !important",
    },
  },
  productImg: {
    width: "100%",
  },
  content: {
    textAlign: "center",
    marginTop: 20,
    "@media (max-width: 767px)": {
      marginTop: 10,
    },
  },

  deliveryTime: {
    fontSize: "13px",
    color: "#777777",
    marginBottom: "14px !important",
  },
  ratingStar: {
    marginTop: -4,
    "@media (max-width: 767px)": {
      marginTop: 0,
    },
    "@media (max-width: 475px)": {
      fontSize: "14px !important",
    },
  },
  star: {
    color: COLORS.secondary,
    "@media (max-width: 767px)": {
      fontSize: "12px !important",
    },
  },
  priceSec: {
    "@media (max-width: 600px)": {
      marginTop: -5,
    },
  },
  del: {
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: "500",
    color: COLORS.lightGrey1,
    "@media (max-width: 767px)": {
      fontSize: 13,
    },
  },
  price: {
    color: COLORS.primary,
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 767px)": {
      fontSize: 14,
    },
  },
}));
