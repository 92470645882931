import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../Login";
import { authKey_local_storage_key, dataUrl } from "../../../helpers/common";
import { showErrorSnackbar } from "../../../actions/snackbar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { logout } from "../../../actions/customer";

export default function LogoSection() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const sideBarRef = useRef();
  const menuBtnRef = useRef();
  const searchRef = useRef();
  const searchBtn = useRef();
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const cartData = useSelector((state) => state.cart.data);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const handleLoginOpen = () => setOpenLoginModal(true);
  const handleLoginClose = () => setOpenLoginModal(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;
  const authToken = localStorage.getItem(authKey_local_storage_key);
  const navigate = useNavigate();
  const assets = useSelector((state) => state.assets.data);

  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");

  const handleAccountClick = (event) => {
    if (authToken) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
    dispatch(showErrorSnackbar("You are logged out!"));
  }, [dispatch]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handler = (event) => {
      if (
        !sideBarRef.current.contains(event.target) &&
        !menuBtnRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
      if (
        !searchRef.current.contains(event.target) &&
        !searchBtn.current.contains(event.target)
      ) {
        setOpenSearch(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (!searchKeyword) {
        dispatch(showErrorSnackbar("Please enter any keyword", "warning"));
        return false;
      } else {
        navigate("/search/" + searchKeyword);
      }
    },
    [searchKeyword, dispatch, navigate]
  );

  return (
    <>
      <div className="container">
        <div className={classes.logoSec}>
          <Login
            openModal={openLoginModal}
            setOpenModal={setOpenLoginModal}
            handleClose={handleLoginClose}
          />
          <div className={classes.row}>
            <div className={classes.logo}>
              <Link to="/">
              <img src={`${dataUrl}/assets/${websiteLogo?.file_name}`} alt="logo" />
              </Link>
            </div>
            

            <div className={classes.row}>
              <div ref={searchBtn} className={classes.headerButton}>
              <form
              onSubmit={handleSearch}
              ref={searchRef}
              className={`${classes.minisearchSec} ${
                openSearch ? classes.minisearchSecActive : ""
              }`}
            >
               <i className="fa fa-search"></i>
              <input
                className={classes.input}
                type="text"
                placeholder="Search Entire Store Here"
                // onMouseDown={handleSearch}
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button
                type="button"
                onClick={(_) => setOpenSearch(!openSearch)}
                className={`${classes.inputButton} ${
                  openSearch ? classes.inputButtonActive : ""
                }`}
              >
                <i className="fa fa-times"></i>
              </button>
            </form>
            {/* Mini modal ends here */}
                <Link
                  onClick={(_) => setOpenSearch(!openSearch)}
                  to="#"
                  className={classes.link}
                >
                  <i className={`fa fa-search ${classes.buttonIcon}`} />
                </Link>
              </div>
              {authToken ? (
                <div className={classes.headerButton}>
                  <Link
                    onClick={handleAccountClick}
                    to="#"
                    className={classes.link}
                  >
                    <i className={`fa fa-user ${classes.buttonIcon}`} />
                  </Link>
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    style={{ marginTop: "10px" }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classes.popoverBody}>
                      <Typography className={classes.popoverList}>
                        <Link className={classes.popoverLink} to="/profile">
                          My Profile
                        </Link>
                      </Typography>
                      <Typography className={classes.popoverList}>
                        <Link className={classes.popoverLink} to="/orders">
                          My Order
                        </Link>
                      </Typography>
                      <Typography className={classes.popoverList}>
                        <Link
                          className={classes.popoverLink}
                          to="/my-addresses"
                        >
                          My Addresses
                        </Link>
                      </Typography>
                      <Typography
                        onClick={handleLogout}
                        className={classes.popoverList}
                      >
                        <p className={classes.popoverLink}>Log Out</p>
                      </Typography>
                    </div>
                  </Popover>
                </div>
              ) : (
                <div className={classes.headerButton}>
                  <Link
                    to="#"
                    onClick={handleLoginOpen}
                    className={classes.link}
                  >
                    <i className={`fa fa-sign-in ${classes.buttonIcon}`} />
                  </Link>
                </div>
              )}

              <div className={classes.headerButton}>
                <span className={classes.cartCount}>{cartData.length}</span>
                <Link to="/cart" className={classes.link}>
                  <i className={`fa fa-shopping-cart ${classes.buttonIcon}`} />
                </Link>
              </div>
              <div className={classes.menuBar}>
                <div className="">
                  <div
                    ref={menuBtnRef}
                    onClick={() => setOpen(!open)}
                    className={
                      open ? "menu-btn open ml-auto" : "menu-btn ml-auto"
                    }
                  >
                    <div className="menu-btn-burger"></div>
                  </div>
                </div>

                <SideBar
                  open={open}
                  setOpen={setOpen}
                  sideBarRef={sideBarRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
