import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getCustomerAddress } from "../../actions/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { deleteAddress } from "../../api/customer";
import NewAddressModal from "../MyAddresses/NewAddressModal";
import UpdateAddressModal from "../MyAddresses/UpdateAddressModal";
import useStyles from "./styles";
import AppLoader from "../AppLoader";

export default function AddressSelectForm({
  selectedAddress,
  setSelectedAddress,
  addressList,
}) {
  const classes = useStyles();
  const [openUpdateAddressModal, setOpenUpdateAddressModal] = useState(false);
  const [openNewAddressModal, setOpenNewAddressModal] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const handleEditAddressModal = (address) => {
    setOpenUpdateAddressModal(true);
    setSelectedAddressId(address.id);
    setSelectedAddress(address);
  };

  const addressDelete = () => {
    setDeleteLoading(true);
    deleteAddress(selectedAddress, selectedAddress.id)
      .then(() => {
        dispatch(getCustomerAddress()).then((ack) => {
          dispatch(showSuccessSnackbar("Address deleted successfully !"));
        });
      })
      .catch((_err) => {
        dispatch(showErrorSnackbar("Error occurred, please try again later."));
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <>
      {deleteLoading ? <AppLoader /> : null}
      {selectedAddress && (
        <UpdateAddressModal
          openUpdateAddressModal={openUpdateAddressModal}
          setOpenUpdateAddressModal={setOpenUpdateAddressModal}
          selectedAddressId={selectedAddressId}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      <NewAddressModal
        openNewAddressModal={openNewAddressModal}
        setOpenNewAddressModal={setOpenNewAddressModal}
      />
      <div className={classes.form}>
        {addressList.map((address) => (
          <div
            onClick={() => setSelectedAddress(address)}
            key={address.id}
            className={`${classes.col}`}
          >
            <div className={`${classes.detailRow}`}>
              <div className={`${classes.select}`}>
                {selectedAddress && selectedAddress.id === address.id ? (
                  <>
                    <img
                      className={classes.radioImg}
                      src="/images/radio-on-button.png"
                      alt=""
                    />
                  </>
                ) : (
                  <img
                    className={classes.radioImg}
                    src="/images/radio-off-button.png"
                    alt=""
                  />
                )}
              </div>
              <div className={classes.centerCol}>
                <h5 className={classes.addressName}>
                  {address.address_name}
                  {address.is_default === 1 && (
                    <span style={{ fontSize: 13, marginLeft: 7 }}>
                      (Default)
                    </span>
                  )}
                </h5>
                <h6 className={classes.name}>{address.contact_person}</h6>
                <p>
                  {address.full_address}, {address.state} - {address.pin_code}
                </p>
                <p>Mobile: {address.mobile_number}</p>

                {selectedAddress && selectedAddress.id === address.id ? (
                  <div>
                    <button onClick={addressDelete} className={classes.button}>
                      Remove
                    </button>
                    <button
                      onClick={() => handleEditAddressModal(address)}
                      className={classes.button}
                    >
                      Edit
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classes.rightCol}>
              <i
                className={
                  address.address_type === "home"
                    ? "fa fa-home"
                    : "fa fa-building-o"
                }
              />
              {/* <div
                      onClick={() => updateAddress(address)}
                      className={classes.edit}
                    >
                      <i className="fa fa-edit" />
                    </div> */}
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={() => setOpenNewAddressModal(true)}
        className={classes.addAddressBtn}
      >
        + Add New Address
      </div>
    </>
  );
}
