import React from 'react';
import ProductBox from './ProductBox';
import { useSelector } from 'react-redux';
import useStyles from '../SearchResult/styles';
import useStyles2 from './styles';
import {useParams} from 'react-router-dom';

export default function ProductContainer(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { categoryName } = useParams();
  const products = useSelector(state => state.products.data);

  const cartData = useSelector(state => state.cart.data);

  let loader = <div className={classes.loaderSec}>
  <img
    className={classes.loader}
    src="/images/loader1.gif"
    alt="loading..."
  />
</div>;
  let notFound = <h5 className="text-danger">Sorry! Record not found in this category</h5>;

  let productList = (<div className={`row ${classes2.boxRow}`}>
    {products.map(product => <ProductBox key={"product-" + product.id} product={product} cartData={cartData} />)}
  </div>);

  return (
    <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
      <h4 className={classes2.heading}>{categoryName}</h4>

      {props.recordFound || notFound}

      {props.loading ? loader : productList}

      {/* <div className="pagination_info">
        <ul>
          <li className="disable prev"><a href="#">&lt; Prev</a></li>
          <li className="active"><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li className="next"><a href="#">Next &gt;</a></li>
        </ul>
      </div> */}

    </div>
  )
}
