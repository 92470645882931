import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioGroup, FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import useStyles from "./styles";

export default function PaymentSummary({
  paymentMethod,
  setPaymentMethod,
}) {
  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };
  const classes= useStyles()
  return (
    <>
      <div className="payment-sec">
        <h5 className={classes.paymentSecHeading} >Choose a Payment Option</h5>
        <FormControl component="fieldset">
          <RadioGroup
            value={paymentMethod}
            onChange={handleChangePaymentMethod}
          >
            <FormControlLabel
              value="onlinePayment"
              control={<Radio />}
              label="Online Payment (Credit Card, Debit Card, Online Banking, UPI)"
              className={classes.paymentOption}
            />
            {/* <FormControlLabel
              value="COD"
              control={<Radio />}
              label="Cash On Delivery (COD)"
              className={classes.paymentOption}
            /> */}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
}
