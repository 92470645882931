import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

 const useStyles = makeStyles({
  logoImg: {
    maxWidth: 130,
    margin: 'auto'
  },
  mobileMenus: {
    marginTop: 30,
  },
  root: {
    padding: '0 !important',
  },
  sideBarMenu: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    zIndex: 100,
    width: 0,
    textAlign: "center",
    overflowX: 'hidden',
    paddingTop: "60px",
    paddingBottom: "30px",
    backgroundColor: COLORS.white,
    opacity: 0,
    transition: "all 0.5s ease-in-out",
    boxShadow: `0px 4px 16px -6px ${COLORS.darkGrey1}`,
  },
  sideBarMenuActive: {
    width: 260,
    opacity: 1,
  },
  nested: {
    paddingLeft: '25px !important',
  },
  subMenuLink: {
    '& span': {
      fontSize: '14px !important'
    }
  },
  menuUl: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "0px",
    flexDirection: "column",
    textAlign: "left",
    paddingTop: 30,
    paddingLeft: 20
  },
  menuLi: {
    padding: "10px ",
  },
  menuLink: {
    color: COLORS.primary,
    fontWeight: "400",
    fontSize: "17px",
    fontFamily: "Montserrat, sans-serif",
    transition: "all 0.3s ease-in-out",
    padding: "12px",
    "&:hover": {
      color: COLORS.secondary,
      textDecoration: "none",
    },
  },
});

export default useStyles;
