import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getImageUrl, toArray } from "../../helpers/common";
import useStyles from "./styles";
import ProductReview from "./ProductReview";

function TabPanel(props) {
  const { children, value, index, product, ...other } = props;

  // it gets string with new lines and returns li components

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ product, setProduct, tabValue, setTabValue, tabRef }) {
  
  const [imgExist, setImgExist] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (product.description && product.description.includes("<img")) {
      setImgExist(true);
    } else {
      setImgExist(false);
    }
  }, [product?.description]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const getList = (list) => {
    let listArr = toArray(list, "\n");
    return listArr.map((h) => <li key={h.id}>{h}</li>);
  };


  return (
    <div ref={tabRef} className="w-100">
      <div className="">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              scrollButtons={true}
            >
              <Tab
                className={`tab_title ${classes.tabTitle}`}
                label="Description"
                {...a11yProps(0)}
              />
              {/* <Tab className={`tab_title ${classes.tabTitle}`} label="Ingredients" {...a11yProps(1)} /> */}
              <Tab
                className={`tab_title ${classes.tabTitle}`}
                label="Nutrition & Ingredients"
                {...a11yProps(1)}
              />
              <Tab
                className={`tab_title ${classes.tabTitle}`}
                label="Reviews"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div className={classes.descriptionSec}>
              <ul className={classes.descriptionUl}>
                {product.highlights
                  ? getList(product.highlights)
                  : "No value foundd"}
              </ul>
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            {product.attributes
              ? getList(product.attributes)
              : "No value found"}
          </TabPanel> */}
          <TabPanel value={tabValue} index={1}>
            {!product.description && !product?.attributes
              ? "No value found"
              : null}

            <div className={classes.nutritionRow}>
              {product?.description || imgExist ? <div className={classes.nutiLeftCol}>
                {imgExist ? (
                  <div>
                    <img
                      className={classes.nutritionImg}
                      src={getImageUrl(product?.description)}
                      alt="nutrition-img"
                    />
                  </div>
                ) : (
                  product?.description?.replace(/(<([^>]+)>)/gi, "")
                )}
              </div> : null}

              {(product?.description || imgExist) && product?.attributes ? (
                <div className={classes.divider}></div>
              ) : null}

              <div className={classes.nutiRightCol}>
                {/* ingredients */}
                {product?.attributes && (
                  <div className={classes.ingredientsSec}>
                    {/* <p className={classes.ingredientsText}>Ingredients:</p> */}
                    <p className={classes.ingredientsValue}>
                      {" "}
                      {product.attributes
                        ? getList(product.attributes)
                        : "No value found"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ProductReview product={product} setProduct={setProduct} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
