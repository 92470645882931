import React from "react";
import { useSelector } from "react-redux";
import { getSettingByKey } from "../../../helpers/common";
import useStyles from "./styles";
import Ticker from "react-ticker";

export default function SocialBar() {
  const classes = useStyles();
  const settings = useSelector((state) => state.settings.data);
  const running_text = getSettingByKey(settings, "running_text");

  return running_text ? (
    <div className={classes.socialBar}>
      <div className="container">
        <Ticker offset="run-in" speed={5} mode="await">
          {({ _ }) => (
            <>
              <p className={classes.runningText}>{running_text?.value}</p>
            </>
          )}
        </Ticker>
      </div>
    </div>
  ) : null;
}
