import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateCart } from "../../actions/cart";
import { updateCartData, findProductInCart } from "../../helpers/cart";
import "../../index.css";
import { Link } from "react-router-dom";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";

export default function AddCartButton({ product, cartData, quantity = 1 }) {
  const dispatch = useDispatch();

  const addProductToCart = useCallback(
    (product) => {
      const newCartData = updateCartData(cartData, product, quantity);

      dispatch(updateCart(newCartData));

      dispatch(showSuccessSnackbar("Product added to cart"));
    },
    [dispatch, cartData, quantity]
  );

  const removeFromCart = useCallback((product) => {
    const newCartData = updateCartData(cartData, product, 0);
    dispatch(updateCart(newCartData));
    dispatch(
      showErrorSnackbar(`Product "${product.name}" removed from the cart`)
    );
  }, [dispatch, cartData]);

  if (product.available_stock <= 0 || product.selling_price === 0) {
    return (
      <div  className="btn-sec">
        <Link to="#" className="my-btn out_of_stock_btn">
          Out of Stock
        </Link>
      </div>
    );
  }

  if (findProductInCart(cartData, product)) {
    return (
      <div className="btn-sec" onClick={() => removeFromCart(product)}>
        <Link to="#" className="my-btn added-btn">
          Remove from cart
        </Link>
      </div>
    );
  }

  return (
    <div onClick={() => addProductToCart(product)} className="btn-sec">
        <Link to="#" className="my-btn">
          Add to Cart
        </Link>
      </div>
  );
}
