import { makeStyles } from "@mui/styles";
import { COLORS } from '../../constant/colors';


const useStyles = makeStyles((theme) => ({
  myAddressesSec: {
    padding: "50px 0",
    textAlign: "center",
    paddingTop: "180px",
    "@media (max-width: 991px)": {
      paddingTop: "160px",
    },
    "@media (max-width: 500px)": {
      paddingTop: "140px",
    }
  },
  loaderSec: {
    padding: '50px 0px',
    textAlign: 'center',
  },
  loader: {
    textAlign: 'center',
    width: 50,
    height: 50,
  },
  heading: {
    marginBottom: 50,
    "@media (max-width: 500px)": {
      marginBottom: 30,
    }
  },
  form: {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    // border: '1px solid #e6e6e6',
    padding: '20px 20px 10px 20px',
    maxWidth: '650px',
    width: '100%',
    margin: '0 auto',
  },
  inputRow: {
    display: 'flex',
  },
  col: {
    borderBottom: '1px solid #e0e0e0',
    marginBottom: 15,
    padding: '0 0 15px 0',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    }
  },
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftCol: {
    textAlign: 'left',
    paddingRight: 80,
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 60,
  },
  edit: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    fontSize: 18,
    color: COLORS.primary,
  },
  inputFeild: {
    padding: "12px 0 !important",
    textAlign: "left",
    position: "relative",
  },
  submit: {
    width: '100%',
    border: 'none',
    marginTop: 10,
    background: COLORS.primary,
    color: '#fff',
    padding: '10px 20px',
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: COLORS.secondary,
    }
  },
  addNew: {
    marginTop: 30,
  },
  newAddressLink: {
    transition: 'all 0.3s ease-in-out',
    color: COLORS.primary,
    textDecoration: 'underline !important',
    '&:hover': {
      color: COLORS.secondary,
    }
  },
  delete: {
    marginRight: 20
  },
  inputFeildAddress: {
    '& input': {
      paddingRight: 70,
    }
  },
  characterLimit: {
    position: 'absolute',
    right: 0,
    top: 30,
    fontSize: 12,
    color: '#999',
  },
  citySelect: {
    width: '95%',
    textAlign: 'left',
  },
  label: {
    color: `rgba(0, 0, 0, 0.6)`,
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: 400,
    fontSize: 13,
    marginBottom: 0,
    position: 'absolute',
    top: -2,
  },
  errorMsg: {
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    bottom: -15,
  },
  pincodeLink: {
    color: '#d32f2f',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontSize: 13,
    position: 'absolute',
    top: 52,
    lineHeight: 1.2
  }
}))


export default useStyles;