import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  cartSec: {
    padding: "180px 0 50px",
    "@media (max-width: 767px)": {
      padding: "160px 0 30px",
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: 40,
    fontSize: "26px",
    "@media (max-width: 767px)": {
      marginBottom: 20,
    },
  },
  emptyCartSec: {
    textAlign: "center",
  },
  emptyCartImg: {
    width: 300,
    marginTop: 20,
    textAlign: "center",
    "@media (max-width: 767px)": {
      width: 200,
    },
    "@media (max-width: 478px)": {
      width: 160,
    },
  },
  emptyText: {
    textAlign: "center",
    fontSize: "22px !important",
    marginBottom: 30,
    "@media (max-width: 767px)": {
      fontSize: "18px !important",
      marginBottom: 20,
    },
    "@media (max-width: 478px)": {
      fontSize: "14px !important",
    },
  },
  warningSec: {
    marginBottom: 30,
  },
  image: {
    width: 90,
  },
  title: {
    textTransform: "uppercase",
    padding: "0 10px",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  headerRow: {
    marginBottom: 20,
    border: "1px solid #fff",
    paddingBottom: 15,
    borderBottomColor: COLORS.lightGrey,
  },
  producSec: {
    padding: "0 20px",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  productRow: {
    marginBottom: 18,
    alignItems: "center",
    border: "1px solid #fff",
    paddingBottom: 20,
    borderBottomColor: COLORS.lightGrey,
  },
  name: {
    marginLeft: 15,
    display: 'inline-block',
  },
  weight: {
    marginLeft: 15,
    fontSize: 14,
    display: 'inline-block',
  },
  priceSec: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px",
  },
  priceCol: {
    fontSize: 15,
    '@media (max-width: 767px)': {
      fontSize: '14px',
    }
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
  },
  remove: {
    cursor: "pointer",
  },
  datePickerSec: {
    width: "100%",
    background: "#f8f9fa",
    marginBottom: 10,
    padding: "15px 20px",
  },
  chooseDateTitle: {
    marginBottom: 10,
    '@media (max-width: 767px)': {
      fontSize: '14px !important',
    }
  },
}));

export default useStyles;
