import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBestSellers } from "../../../actions/products";
import useStyles from "./styles";
import ProductBox from "../ProductBox";
import Carousel from "react-multi-carousel";
// import Carousel from "react-grid-carousel";
import { splitArray } from "../../../helpers/common";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

export default function BestSellers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bestSellersProducts =
    useSelector((state) => state.products.bestSellers) || [];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const bestSellersOne = splitArray(bestSellersProducts)[0];
  const bestSellersTwo = splitArray(bestSellersProducts)[1];

  useEffect(() => {
    dispatch(getBestSellers("Best Sellers"));
  }, [dispatch]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };

  return bestSellersProducts?.length > 0 ? (
    <div className={`${classes.bestSellerSec}`}>
      <div className={`productSliderSec`}>
        <div className='container'>
          <h2 className={classes.heading}>Best Sellers</h2>

          {/* TODO: remove this if not use later */}
          {/* <Carousel
            cols={4}
            rows={bestSellersProducts?.length > 4 ? 2 : 1}
            gap={10}
            loop={true}
            mobileBreakpoint={0}
            responsiveLayout={[
              { breakpoint: 1000, cols: 3 },
              { breakpoint: 750, cols: 2, rows: 2, gap: 5 },
              { breakpoint: 499, cols: 2, rows: 2, autoplay: 3000 },
            ]}
          >
            {bestSellersProducts?.map((product) => (
              <Carousel.Item key={"bestSellers-" + product.id}>
                <ProductBox product={product} />
              </Carousel.Item>
            ))}
          </Carousel> */}

          <Carousel
            draggable={true}
            keyBoardControl={true}
            responsive={responsive}
            infinite={true}
            partialVisible={"false"}
            autoPlaySpeed={4000}
            arrows={true}
            autoPlay={false}
            dotListClass='custom-dot-list-style'
            removeArrowOnDeviceType={["mobile"]}
            showDots={false}
          >
            {bestSellersOne?.map((product) => (
              <ProductBox key={"bestSellers-" + product.id} product={product} />
            ))}
          </Carousel>
        </div>
      </div>

      {bestSellersTwo?.length > 0 && (
        <div
          className={`productSliderSec ${!isMobile ? "mt-3" : ""} `}
          style={{ marginTop: isMobile ? -20 : 0 }}
        >
          <div className='container'>
            <Carousel
              draggable={true}
              keyBoardControl={true}
              responsive={responsive}
              infinite={true}
              partialVisible={"false"}
              autoPlaySpeed={4000}
              arrows={true}
              autoPlay={false}
              dotListClass='custom-dot-list-style'
              removeArrowOnDeviceType={["mobile"]}
              showDots={false}
            >
              {bestSellersTwo?.map((product) => (
                <ProductBox
                  key={"bestSellers-" + product.id}
                  product={product}
                />
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
