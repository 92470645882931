import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { getBanners } from "../../../actions/banners";
import { getSettings } from "../../../actions/settings";
import { getMenus } from "../../../actions/menus";
import {
  getSettingByKey,
  filterByCategoryName,
  authKey_local_storage_key,
} from "../../../helpers/common";
import { getContents } from "../../../actions/contents";
import { LOAD_CART_DATA } from "../../../constants/actionTypes";
import { getCategory } from "../../../actions/categories";
import { getCustomerAddress } from "../../../actions/customer";
import { getAssets } from "../../../actions/assets";

export default function Footer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.data);
  const menus = useSelector((state) => state.menus.menus);

  const footerQuickLinks = filterByCategoryName(menus, "footer-quick-links");
  const footerOurPolicies = filterByCategoryName(menus, "footer-our-policies");
  const customerService = filterByCategoryName(menus, "customer-service");
  const copyright_text = getSettingByKey(settings, "footer_copyright_message");

  const token = localStorage.getItem(authKey_local_storage_key);

  useEffect(() => {
    dispatch({ type: LOAD_CART_DATA });
    dispatch(getBanners("Website Homepage Banners"));
    dispatch(getBanners("Listing Banners"));
    dispatch(getBanners("Product Banners"));
    dispatch(getBanners("Home Footer Banners"));
    dispatch(getBanners("About Us Banners"));
    dispatch(getBanners("Contact Us Banners"));
    dispatch(getBanners("Home_footer_banner"));
    dispatch(getBanners("Website Ad Banners"));
    dispatch(getSettings());
    dispatch(getMenus());
    dispatch(getAssets());
    dispatch(getContents());
    dispatch(getCategory());
    if (token) {
      dispatch(getCustomerAddress());
    }
    // dispatch(getAssets());
    // dispatch(loadCartData());
  }, [dispatch, token]);

  const socialLinkFacebook = getSettingByKey(settings, "social_links_facebook");

  const socialLinkTwitter = getSettingByKey(settings, "social_links_twitter");

  const socialLinkPlayStore = getSettingByKey(
    settings,
    "social_links_play_store"
  );

  const socialLinkInstagram = getSettingByKey(
    settings,
    "social_links_instagram"
  );

  const socialLinkYoutube = getSettingByKey(settings, "social_links_youtube");

  const openInNewWindow = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const hasOpenInNewWindow = (menu) => {
    const url = menu.url;
    if (menu.open_in_new_window === "yes") {
      openInNewWindow(url);
    }
  };

  return (
    <div className={classes.footerSec}>
      <div className={classes.playStoreSec}>
        <h2 className={classes.downloadText}>Download Our App</h2>
        <Link
          onClick={() => openInNewWindow(socialLinkPlayStore?.value)}
          className={classes.playStoreLink}
          to="."
        >
          <img
            src="/images/playstore.png"
            className={classes.playStoreLogo}
            alt=""
          />
          Google Play
        </Link>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-6">
            <div className={classes.footerMenuSec}>
              <h3 className={classes.footerMenuTitle}>Quick Links</h3>
              <ul>
                {footerQuickLinks.map((menu) => (
                  <li className={classes.footerMenuList} key={menu.id}>
                    <Link
                      onClick={() => hasOpenInNewWindow(menu)}
                      className={classes.menuLink}
                      to={`${
                        menu.open_in_new_window === "no" ? menu.url : "#"
                      }`}
                    >
                      {menu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-6">
            <div className={classes.footerMenuSec}>
              <h3 className={classes.footerMenuTitle}>Our Policies</h3>
              <ul>
                {footerOurPolicies.map((menu) => (
                  <li className={classes.footerMenuList} key={menu.id}>
                    <Link
                      onClick={() => hasOpenInNewWindow(menu)}
                      className={classes.menuLink}
                      to={`${
                        menu.open_in_new_window === "no" ? menu.url : "#"
                      }`}
                    >
                      {menu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className={classes.footerMenuSec}>
              <h3 className={classes.footerMenuTitle}>Customer Service</h3>
              <ul>
                {customerService.map((menu) => (
                  <li className={classes.footerMenuList} key={menu.id}>
                    <Link
                      onClick={() => hasOpenInNewWindow(menu)}
                      className={classes.menuLink}
                      to={`${
                        menu.open_in_new_window === "no" ? menu.url : "#"
                      }`}
                    >
                      {menu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className={classes.footerMenuSec}>
              <h3 className={classes.footerSocialMenuTitle}>Follow Us On </h3>
              <ul className={classes.socialUl}>
                <li className={classes.socialLiList}>
                  <Link
                    onClick={() => openInNewWindow(socialLinkFacebook?.value)}
                    className={classes.socialLink}
                    to={`.`}
                  >
                    <i className="fa fa-facebook" />
                  </Link>
                </li>
                <li className={classes.socialLiList}>
                  <Link
                    onClick={() => openInNewWindow(socialLinkTwitter?.value)}
                    className={classes.socialLink}
                    to={`.`}
                  >
                    <i className="fa fa-twitter" />
                  </Link>
                </li>
                <li className={classes.socialLiList}>
                  <Link
                    onClick={() => openInNewWindow(socialLinkInstagram?.value)}
                    className={classes.socialLink}
                    to={`.`}
                  >
                    <i className="fa fa-instagram" />
                  </Link>
                </li>
                <li className={classes.socialLiList}>
                  <Link
                    onClick={() => openInNewWindow(socialLinkYoutube?.value)}
                    className={classes.socialLink}
                    to={`${socialLinkYoutube?.value}`}
                  >
                    <i className="fa fa-youtube" />
                  </Link>
                </li>
              </ul>

              <h3 className={classes.footerSocialMenuTitle}>
                Payment Partners{" "}
              </h3>
              {/* <ul className={`${classes.socialUl} ${classes.socialPaymentUl}`}>
                <li className={classes.socialLi}>
                  <img
                    className={classes.paymentPartnerLogo}
                    src="/images/visa-logo.png"
                    alt=""
                  />
                </li>
                <li className={classes.socialLi}>
                  <img
                    className={classes.paymentPartnerLogo}
                    src="/images/american-express-logo.png"
                    alt=""
                  />
                </li>
                <li className={classes.socialLi}>
                  <img
                    className={classes.paymentPartnerLogo}
                    src="/images/maestro-logo.png"
                    alt=""
                  />
                </li>
                <li className={classes.socialLi}>
                  <img
                    className={classes.paymentPartnerLogo}
                    src="/images/paypal-logo.png"
                    alt=""
                  />
                </li>
                <li className={classes.socialLi}>
                  <img
                    className={classes.paymentPartnerLogo}
                    src="/images/google-wallet-logo.png"
                    alt=""
                  />
                </li>
              </ul> */}

              <img
                className={classes.paymentPartnerImg}
                src="/images/footer payment image.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className={classes.copyrightSec}>
          <p className={classes.copyrightText}>{copyright_text?.value}</p>
        </div>
      </div>
      {/* container */}
    </div>
  );
}
