import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getSettingByKey, toArray } from '../../../helpers/common';

const SelectState = ({ classes, errors, setCustomer, customer, defaultValue }) => {

  const [stateValue, setStateValue] = useState();

  const settings = useSelector((state) => state.settings.data);

  const findAllowedCities = getSettingByKey(settings, 'allowed_states')?.value;

  const stateOptionsArray = findAllowedCities && toArray(findAllowedCities, '\n');

  const stateOptions = useMemo(() => {
    const options = [];
    if (stateOptionsArray && stateOptionsArray.length > 0) {
      stateOptionsArray.forEach((item) => {
        options.push({ value: item, label: item });
      });
    }
    return options;
  }, [stateOptionsArray]);
  
  const [options, setOptions] = useState(stateOptions);

  const customStyles = useMemo(
    () => ({
      option: (provided) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: '100%',
        border: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
        borderRadius: '0px',
        padding: '0px',
        boxShadow: 'none !important',
        '&:hover': {
          borderBottom: '2px solid rgba(0, 0, 0)',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0px !important',
        marginBottom: '-10px !important',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#000',
        padding: '0px !important',
      }),
    }),
    []
  );

  const handleStateCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setStateValue(newValue);
      setCustomer({ ...customer, state: newValue ? newValue.value : "" });
    },
    [options, setCustomer, customer]
  );

  const handleStateChange = useCallback((inputValue) => {
    setStateValue(inputValue)
    setCustomer({ ...customer, state: inputValue ? inputValue.value : "" });
  }, [customer, setCustomer]);

  return (
    <>
      <h5 className={classes.label}>State *</h5>
      <CreatableSelect
        isClearable
        value={stateValue}
        options={options}
        defaultValue={defaultValue?.value ? defaultValue : []}
        onChange={handleStateChange}
        onCreateOption={handleStateCreate}
        className={`${classes.citySelect} creatable-select`}
        placeholder="Enter your state"
        styles={customStyles}
      />
      <h6 className={classes.errorMsg}>{errors.state}</h6>
    </>
  )
}

export default SelectState