import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFilterPath, removeUrlParam } from "../../helpers/common";
import useStyles from "./styles";

const AppliedFilters = ({
  filteredPrice,
  setFilteredPrice,
  filteredWeight,
  setFilteredWeight,
  filteredTaste,
  setFilteredTaste,
}) => {
  const classes = useStyles();
  const rupeeIcon = <i className="fa fa-inr"></i>;
  const navigate = useNavigate();
  const pageNumber = 1;
  const { categoryId, categoryName } = useParams();

  const handleClearAllFilters = () => {
    setFilteredPrice([]);
    setFilteredTaste("");
    setFilteredWeight("");

    navigate(`/category/${categoryId}/${categoryName}/${pageNumber}`, {
      replace: true,
    });
  };

  const handleClearFilter = (urlPrams) => {
    const params = removeUrlParam(urlPrams);
    let pageNumber = 1;
    let path = getFilterPath(categoryId, categoryName, pageNumber, params);
    navigate(path, { replace: true });
    if (urlPrams === "price_range") {
      setFilteredPrice([]);
    } else if (urlPrams === "item_weight") {
      setFilteredWeight("");
    } else if (urlPrams === "taste") {
      setFilteredTaste("");
    } else {
      setFilteredPrice([]);
      setFilteredTaste("");
      setFilteredWeight("");
    }
  };

  return (
    <div className={classes.filterMainSec}>
      <div className={classes.filterHead}>
        <h4 className={classes.heading}>Filter</h4>
        <h5 className={classes.clearBtn} onClick={() => handleClearAllFilters()}>
          Clear all{" "}
        </h5>
      </div>

      <div className={classes.filterBody}>
        {filteredPrice?.length > 0 && (
          <div className={classes.filterItem}>
            <i
              onClick={() => handleClearFilter("price_range")}
              className={`fa fa-times ${classes.clearIcon}`}
              aria-hidden="true"
            ></i>
            Price:{" "}
            <span>
              {rupeeIcon} {filteredPrice[0]} - {rupeeIcon} {filteredPrice[1]}
            </span>
          </div>
        )}

        {filteredWeight && (
          <div className={classes.filterItem}>
            <i
              onClick={() => handleClearFilter("item_weight")}
              className={`fa fa-times ${classes.clearIcon}`}
              aria-hidden="true"
            ></i>
            Weight: <span>{filteredWeight} gms</span>
          </div>
        )}

        {filteredTaste && (
          <div className={classes.filterItem}>
            <i
              onClick={() => handleClearFilter("taste")}
              className={`fa fa-times ${classes.clearIcon}`}
              aria-hidden="true"
            ></i>
            Taste: <span>{filteredTaste}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
