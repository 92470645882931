import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorSnackbar } from "../../actions/snackbar";
import { getCouponCodeList } from "../../api/items";
import { COLORS } from "../../constant/colors";
import { authKey_local_storage_key, dataUrl } from "../../helpers/common";
import useStyles from "./CouponCodeStyles";
import moment from "moment";

export default function CouponCode({ handleLoginOpen, couponCode, setCouponCode }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    if ( !couponCode ) {
      setOpenModal(true)
    }
  };
  const handleClose = () => {
    setCouponError("");
    setOpenModal(false);
  };
  const [couponList, setCouponList] = useState([]);
  const [couponInputValue, setCouponInputValue] = useState("");
  const [couponError, setCouponError] = useState("");
  const [loading, setLoading] = useState(false);
  const customer = useSelector((state) => state.customers.profile);

  const dispatch = useDispatch();
  const token = localStorage.getItem(authKey_local_storage_key);
  let findCouponCode = couponList.find(
    (code) => code.coupon_code === couponInputValue
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0 !important",
    width: "35%",
    height: "95%",
    borderRadius: "10px",
    background: "#fff",
    boxShadow: `0px 4px 16px -6px ${COLORS.lightGrey}`,
    transition: "all 0.5s ease-in-out",
    overflow: "overlay !important",
    overflowX: "hidden !important",
    "@media (max-width: 991px)": {
      width: "90%",
      height: "80%",
    },
    "@media (max-width: 550px)": {
      width: "95%",
      height: "75%",
    },
  };

  const removeCouponCode = () => {
    setCouponCode("");
    localStorage.removeItem("couponCode");
    handleClose()
  };

  useEffect(() => {
    setLoading(true);
    getCouponCodeList()
      .then(function (response) {
        setCouponList(response.data);
        setLoading(false);
      })
      .catch(function (_) {
        setLoading(false);
        setCouponList([]);
        dispatch(showErrorSnackbar("Coupon code list not found"));
      });
  }, [dispatch, setCouponList]);

  const onApply = useCallback(
    async (value) => {
      if (token && customer?.id) {
        setCouponCode(value);
        localStorage.setItem("couponCode", value);
        handleClose();
      } else {
        handleClose();
        handleLoginOpen();
        dispatch(showErrorSnackbar("Please login to apply coupon code"));
      }
    },
    [customer?.id, dispatch, handleLoginOpen, setCouponCode, token]
  );

  const handleCouponCodeSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (
        token &&
        customer?.id &&
        couponInputValue === findCouponCode?.coupon_code
      ) {
        setCouponCode(couponInputValue);
        localStorage.setItem("couponCode", couponInputValue);
        handleClose();
      } else if (!token && !customer?.id) {
        dispatch(showErrorSnackbar("Please login to apply coupon code"));
        handleLoginOpen();
        handleClose();
      } else {
        setCouponError("Invalid Coupon Code");
      }
    },
    [couponInputValue, customer?.id, dispatch, findCouponCode?.coupon_code, handleLoginOpen, setCouponCode, token]
  );

  return (
    <>
      <div onClick={handleOpen} className={classes.couponCodeSec}>
        <div className="d-flex align-items-center">
          <img
            className={classes.discountOfferImg}
            src="/images/discount_offer.png"
            alt="coupon-code"
          />
          {!couponCode ? (
            <h6 className={classes.applyCoupon}>Apply Coupon</h6>
          ) : (
            <h6
              className={classes.appliedCoupon}
            >{`Coupon "${couponCode}" Applied`}</h6>
          )}
          {/* {!couponCode ?  "Apply Coupon" : `Coupon Code: ${couponCode} Applied`} */}
        </div>
        <div>
          {!couponCode ? (
            <i
              className={`fa fa-angle-right ${classes.arrowIcon}`}
              aria-hidden="true"
            />
          ) : (
            <i
              onClick={removeCouponCode}
              className="fa fa-times"
              aria-hidden="true"
            ></i>
          )}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={classes.modalSec}>
            <div className={classes.modalHeader}>
              <div className={classes.modalTitle}>
                <h6>Apply Coupon</h6>
              </div>
              <div onClick={handleClose} className={classes.modalClose}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
            <div className={classes.modalBody}>
              <form onSubmit={handleCouponCodeSubmit} className={classes.form}>
                <input
                  value={couponInputValue}
                  onChange={(e) => setCouponInputValue(e.target.value)}
                  variant="outlined"
                  className={classes.couponInput}
                  fullWidth
                  placeholder="Enter coupon code"
                />
                <button className={classes.inputApplyBtn}>Apply</button>
                {couponError && (
                  <div className={classes.errorMsg}>{couponError}</div>
                )}
              </form>

             {!loading  ? <>
                {couponList.length > 0 ? (
                  couponList?.map((coupon) => (
                    <div
                      key={"coupon-" + coupon.id}
                      className={classes.couponCards}
                    >
                      <div className={classes.couponCardTop}>
                        <img
                          className={classes.couponImg}
                          src={`${dataUrl}/coupons/120x120-${coupon.image}`}
                          alt="coupon-card"
                        />
                        <div className={classes.couponDetailSec}>
                          <h6 className={classes.title}>{coupon.title}</h6>
                          <p className={classes.description}>
                            {coupon.description}
                          </p>
                        </div>
                      </div>
  
                      <div className={classes.couponCardBottom}>
                        <div className={classes.couponName}>
                          Code: <span>{coupon?.coupon_code}</span> <br />
                          Validity:{" "}
                          <span>
                            {moment(coupon.end_date).format("do MMM YYYY")}
                          </span>
                        </div>
  
                        <button
                          onClick={() => onApply(coupon?.coupon_code)}
                          className={classes.applyBtn}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={classes.noCoupon}>No coupon found</div>
                )}
              </> : <div className={classes.loadingSec}>Loading...</div>}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
