import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

export default makeStyles(() => ({
  bestSellerSec: {
    marginBottom: 60,
    "@media (max-width: 991px)": {
      marginBottom: 40,
    },
    "@media (max-width: 767px)": {
      marginBottom: 0,
    },
    "@media (max-width: 500px)": {
      paddingBottom: 0,
    }
  },
  heading: {
    textAlign: "center",
    marginBottom: 20,
  },
  boxRow: {
    "@media (max-width: 767px)": {
      padding: "0 15px",
    },
  },
  myCol: {
    padding: "15px !important",
    textAlign: "center",
    transition: "all 0.3s ease-in-out",
    "& h3": {
      transition: "all 0.3s ease-in-out",
    },
    "&:hover": {
      transform: "translateY(-10px)",
      "& h3": {
        color: COLORS.secondary,
      },
    },
    "@media (max-width: 767px)": {
      padding: "0px 8px 15px !important",
      "& h3": {
        fontSize: "14px !important",
        marginBottom: "2px !important",
        fontWeight: "400 !important",
      },
    },
    "@media (max-width: 467px)": {
      "& h3": {
        fontSize: "12px !important",
      },
    },
  },
  productImg: {
    width: "100%",
  },
  content: {
    textAlign: "center",
    marginTop: 20,
    "@media (max-width: 767px)": {
      marginTop: 10,
    },
  },
  rating: {
    marginTop: -4,
    "@media (max-width: 767px)": {
      marginTop: 0,
    }
  },
  ratingStar: {
    color: COLORS.secondary,
    "@media (max-width: 475px)": {
      fontSize: "14px !important",
    }
  },
  star: {
    color: COLORS.secondary,
    "@media (max-width: 767px)": {
      fontSize: "12px !important",
    },
  },
  priceSec: {
    '@media (max-width: 600px)': {
      marginTop: -5,
    }
  },
  del: {
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: "500",
    color: COLORS.lightGrey1,
    "@media (max-width: 767px)": {
      fontSize: 13,
    },
  },
  price: {
    color: COLORS.primary,
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 767px)": {
      fontSize: 14,
    },
  },
}));
