import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialProducts } from "../../../actions/products";
import useStyles from "./styles";
import ProductBox from "../ProductBox";
import Carousel from "react-multi-carousel";
import { getSettingByKey } from "../../../helpers/common";

export default function OurSpecials() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ourSpecials = useSelector((state) => state.products.specialProducts);

  const settings = useSelector((state) => state.settings.data);
  const sectionTitle = getSettingByKey(settings, "our_special_product_title");

  useEffect(() => {
    dispatch(getSpecialProducts("Our Specials"));
  }, [dispatch]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };

  if (ourSpecials?.length === 0) {
    return null;
  }

  return (
    <div className={`${classes.newArrivalsSec}`}>
      <div className='productSliderSec'>
        <div className='container'>
          <h2 className={classes.heading}>
            {sectionTitle?.value || "Our Specials"}
          </h2>

          <Carousel
            draggable={true}
            keyBoardControl={true}
            responsive={responsive}
            infinite={true}
            partialVisible={"false"}
            autoPlaySpeed={4000}
            arrows={true}
            autoPlay={false}
            dotListClass='custom-dot-list-style'
            removeArrowOnDeviceType={["mobile"]}
            showDots={false}
          >
            {ourSpecials.map((product) => {
              return (
                <ProductBox
                  key={"ourSpecialsSec-" + product.id}
                  product={product}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
