import React, { useState, useEffect } from "react";
import OrderBox from "./OrderBox";
import { getAllOrder } from "../../actions/orders";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import OrderDetails from "./OrderDetails";
import { showErrorSnackbar } from "../../actions/snackbar";

export default function Orders(props) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedOrder, setOrder] = useState(null);
  const [orderAmount, setOrderAmount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllOrder()).then((response) => {
      if (response?.payload?.data) {
        setOrders(response.payload.data);
      } else if (response?.payload?.response?.data?.message) {
        dispatch(showErrorSnackbar(response.payload.response.data.message));
        navigate("/");
      } else {
        dispatch(showErrorSnackbar("Something went wrong"));
        navigate("/");
      }
      setLoading(false);
    });
  }, [dispatch, navigate]);

  return (
    <>
      {selectedOrder && (
        <OrderDetails
          openModal={openModal}
          handleOpen={handleOpen}
          setOpenModal={setOpenModal}
          handleClose={handleClose}
          selectedOrder={selectedOrder}
          orderAmount={orderAmount}
        />
      )}
      <div className={classes.ordersSec}>
        <div className="container">
          <h2 className={classes.heading}>My orders</h2>
          {loading ? (
            <div className={classes.loaderSec}>
              <img
                className={classes.loader}
                src="/images/loader1.gif"
                alt="loading..."
              />
            </div>
          ) : (
            <div className="row">
              {orders.length > 0 ? orders?.map((product) => (
                <OrderBox
                  setOrderAmount={setOrderAmount}
                  handleOpen={handleOpen}
                  setOrder={setOrder}
                  product={product}
                />
              )) : <div className="col-12 text-center mt-3">No orders found</div>}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
