import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Login from '../Login'

const LoginPage = () => {
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const handleLoginOpen = () => setOpenLoginModal(true);
  const handleLoginClose = () => setOpenLoginModal(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpenLoginModal(true);
  }, [navigate])

  return (
    <Login
        openModal={openLoginModal}
        handleOpen={handleLoginOpen}
        setOpenModal={setOpenLoginModal}
        handleClose={handleLoginClose}
      />
  )
}

export default LoginPage