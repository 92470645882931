import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showErrorSnackbar } from "../../actions/snackbar";
import useStyles from "./styles";
import { authKey_local_storage_key } from "../../helpers/common";
import MetaTags from "../Common/MetaTags";

export default function MyAddresses() {
  const classes = useStyles();
  const navigate = useNavigate();
  const addressList = useSelector(state => state.customers.address);
  const dispatch = useDispatch();
  const token = localStorage.getItem(authKey_local_storage_key);

  useEffect(() => {
    if (!token) {
      dispatch(showErrorSnackbar('You are not logged out ! Please login to continue'));
      navigate("/");
    }
  }, [token, navigate, dispatch]);

  const updateAddress = (address) => {
    navigate(`/update-address/${address.id}`, { state: { address } });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>

      <MetaTags
        title="My Addresses"
        description=""
        keywords=""
      ></MetaTags>

      <div className="container">
        <div className={classes.myAddressesSec}>
          <h2 className={classes.heading}>My Addresses</h2>

          {addressList.length > 0 ? (<div className={classes.form}>
            <div className="row mr-0 ml-0">
              {addressList.map(address => (
                <div key={address.id} className={`col-12 ${classes.col}`}>
                  <div className={classes.detailRow}>
                    <div className={classes.leftCol}>
                      <h5>{address.address_name}</h5>
                      <h6>{address.contact_person}</h6>
                      <p>{address.full_address}, {address.state} - {address.pin_code}</p>
                      <p>Mobile: {address.mobile_number}</p>

                    </div>
                    <div className={classes.rightCol}>
                      <i className={address.address_type === "home" ? 'fa fa-home' : 'fa fa-building-o'} />
                      {/* <i class="fa-solid fa-buildings"></i> */}
                      <div onClick={() => updateAddress(address)} className={classes.edit}><i className="fa fa-edit" /></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>) :
            <div className={classes.noAddress}>
              <p>You don't have any address here, add a new address to continue.</p>
            </div>}

          <div className={classes.addNew}>
            <Link className={classes.newAddressLink} to="/new-address"> + Add New Address</Link>
          </div>

        </div>
      </div>
    </div>
  );
}
