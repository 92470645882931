import React, { useState, useEffect, useCallback } from "react";
import { fetchProductsByConditions } from "../../api/items";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../actions/categories";
import CategoriesSec from "../CategoriesSec";
import ProductBox from "../ProductContainer/ProductBox";
import { useParams } from "react-router-dom";
import useStyles from "./styles";
import MetaTags from "../Common/MetaTags";

export default function SearchResult() {
  const [searchedProductList, setList] = useState([]);
  const classes = useStyles();
  const { keyword } = useParams();
  const [loading, setLoading] = useState(false);
  const [recordNotFound, setRecordNotFound] = useState(false);
  const cartData = useSelector((state) => state.cart.data);
  const categories = useSelector((state) => state.categories.data);
  const dispatch = useDispatch();

  let loader = (
    <div className={classes.loaderSec}>
      <img
        className={classes.loader}
        src="/images/loader1.gif"
        alt="loading..."
      />
    </div>
  );
  let notFound = (
    <div className={classes.notFoundSec}>
      <h5 className="text-danger">
        Sorry! Products are not found for the given search keyword{" "}
        <span style={{ fontWeight: "600", color: "black" }}>"{keyword}"</span>
      </h5>
    </div>
  );

  let productList = (
    <div className="row">
      {searchedProductList.map((product) => (
        <ProductBox
          key={"product-" + product.id}
          product={product}
          cartData={cartData}
        />
      ))}
    </div>
  );

  const asyncCallsOnLoad = useCallback(async () => {
    setRecordNotFound(false);
    setLoading(true);
    try {
      const response = await fetchProductsByConditions(
        "ProductsSearch[keyword]=" + keyword
      );
      console.log("response::", response);
      setList(response.data);
      setRecordNotFound(false);
      setLoading(false);
      if (response?.data?.length === 0) {
        setRecordNotFound(true);
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  }, [keyword]);

  useEffect(() => {
    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  return (
    <div className={classes.searchResultSec}>
      <MetaTags title={`Search results for "${keyword}"`} description='' keywords=''></MetaTags>

      <div className="container">
        <ul className={classes.breadcrumbInfo}>
          <li>
            Search Result for:{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              "{keyword}"
            </span>{" "}
          </li>
        </ul>
      </div>

      <div className={classes.innerSection}>
        <div className="container">
          <div className="row">
            <CategoriesSec mainCategories={categories} hideFilterSec={true} />
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
              {recordNotFound ? notFound : null}

              {loading ? loader : productList}
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />
    </div>
  );
}
