import React from "react";
import useStyles from "./styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { TextField } from "@mui/material";


const DeliveryDateTime = ({dateTime, setDateTime}) => {
  const classes = useStyles();
  return (
    <div className={classes.datePickerSec}>
      <h6 className={classes.chooseDateTitle}>
        Choose Preferred Delivery Date *
      </h6>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          minDate={new Date()}
          renderInput={(props) => (
            <TextField
              disabled={true}
              className="py-1 w-100"
              variant="standard"
              fullwidth
              {...props}
            />
          )}
          value={dateTime}
          onChange={(newValue) => {
            setDateTime(newValue);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DeliveryDateTime;
