import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles(() => ({
  contactUsSec: {
    paddingTop: "145px",
    "@media (max-width: 991px)": {
      paddingTop: "130px",
    },
    "@media (max-width: 500px)": {
      paddingTop: "110px",
    }
  },
  heading: {
    textAlign: "center",
    marginTop: 30,
  },
  content: {
    padding: "70px 0px",
  },
  leftCol: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    marginBottom: 20,
  },
  icon: {
    width: 50,
    height: 50,
    background: COLORS.primary,
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    color: COLORS.lightGrey2,
  },
  form: {
    width: "80%",
    minHeight: 400,
    marginLeft: 'auto',
    padding: '30px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    '@media (max-width: 991px)': {
      width: "100%",
    },
    '@media (max-width: 768px)': {
      marginTop: '30px',
    },
  },
  title: {
    marginBottom: 20,
    fontSize: '22px !important',
  },
  inputFeild: {
    // padding: '12px 0 !important',
    marginBottom: '22px !important',
  },
  input: {
    width: '100%',
    marginBottom: 15,
    padding: '8px 0',
    border: '1px solid #fff',
    borderBottomColor: '#ccc',
  },
  submit: {
    width: '160px',
    border: 'none',
    marginTop: 30,
    background: COLORS.primary,
    marginLeft: 15,
    color: '#fff',
    padding: '10px 20px',
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: COLORS.secondary,
    }
  }
}));


export default useStyles;
