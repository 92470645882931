import { createAction } from 'redux-actions';

import { SET_META_DATA } from '../constants/actionTypes';

const setMetaAction = createAction(SET_META_DATA);

export function setMeta(meta) {
    return dispatch => dispatch(setMetaAction({ meta }));
}

