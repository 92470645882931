import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { appUrl } from "../../helpers/common";

function MetaTags(props) {
  const frontendSettings = useSelector((state) => state.settings.data);

  const {pathname} = useLocation()

  const canonical = `${appUrl}${pathname}`
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const getSetting = (key, settings) => {
    return settings?.find((setting) => setting.key === key);
  };

  useEffect(() => {
    // Module home
    if (props?.module === "home") {
      setTitle(getSetting("homepage_title", frontendSettings)?.value);
      setDescription(
        getSetting("homepage_meta_description", frontendSettings)?.value
      );
      setKeywords(
        getSetting("homepage_meta_keywords", frontendSettings)?.value
      );
    } else if (props?.module === "categories") {
      // Module categories

      let title =
        getSetting("category_title", frontendSettings)?.value || props?.title;
      let description =
        getSetting("category_meta_description", frontendSettings)?.value ||
        props?.description;
      let keywords =
        getSetting("category_meta_keywords", frontendSettings)?.value ||
        props?.keywords;
      setTitle(title?.replace("{NAME}", props?.title));
      setDescription(description?.replace("{DESCRIPTION}", props?.description));
      setKeywords(keywords?.replace("{NAME}", props?.keywords));
    } else if (props?.module === "products") {
      // Module products

      let title =
        getSetting("product_title", frontendSettings)?.value || props?.title;
      let description =
        getSetting("product_meta_description", frontendSettings)?.value ||
        props?.description;
      let keywords =
        getSetting("product_meta_keywords", frontendSettings)?.value ||
        props?.keywords;
      setTitle(title?.replace("{NAME}", props?.title));
      setDescription(description?.replace("{DESCRIPTION}", props?.description));
      setKeywords(keywords?.replace("{SEARCH_TAGS}", props?.keywords));
    } else if (props?.module === "pages") {
      // Module pages

      let title =
        getSetting("page_title", frontendSettings)?.value || props?.title;
      let description =
        getSetting("page_meta_description", frontendSettings)?.value ||
        props?.description;
      let keywords =
        getSetting("page_meta_keywords", frontendSettings)?.value ||
        props?.keywords;
      setTitle(title?.replace("{TITLE}", props?.title));
      setDescription(
        description?.replace("{META_DESCRIPTION}", props?.description)
      );
      setKeywords(keywords?.replace("{META_KEYWORDS}", props?.keywords));
    } else {
      setTitle(props?.title);
      setDescription(props?.description);
      setKeywords(props?.keywords);
    }
  }, [frontendSettings, props]);

  return (
    <Helmet>
      {title ? (
        <title>
          {title} {props?.module !== "home" ? "| Avon Namkeen" : ""}
        </title>
      ) : null}

      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='title' content={title} />

      <meta property='og:title' content={title} />
      {props?.image && <meta property='og:image' content={props?.image} />}

      {/* <!-- Open Graph / Facebook --> */}
      <meta property='og:type' content='website' />
      <meta property='og:description' content={description} />

      <link rel="canonical" href={window?.location?.href || canonical} />

      {props?.image && (
        <meta property='og:image' itemprop='image' content={props?.image} />
      )}
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:width' content='300' />
      <meta property='og:image:height' content='300' />

      {/* <!-- Twitter --> */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      {props?.image && (
        <meta property='twitter:image' content={props?.image}></meta>
      )}
    </Helmet>
  );
}

export default MetaTags;
