import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  loaderSec: {
    width: "100%",
    textAlign: "center",
  },
  loader: {
    width: 50,
    height: 50,
  },
  breadcrumb: {
    fontSize: 14,
  },
  breadcrumbLink: {
    color: COLORS.primary,
    fontSize: 14,
    '&:hover': {
      color: COLORS.black
    }
  },
  singleProductSec: {
    padding: "50px 0",
  },
  productName: {
    fontSize: 26,
    fontFamily: "Montserrat",
    textAlign: "left",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
    '@media (max-width: 475px)': {
      marginBottom: '0',
    }
  },
  ratingStar: {
    '@media (max-width: 475px)': {
      fontSize: '14px !important',
    }
  },
  priceSec: {
    display: "flex",
    alignItems: "center",
  },
  del: {
    fontSize: 17,
    color: COLORS.lightGrey1,
    fontFamily: "Poppins",
    display: "block",
    fontWeight: "500",
    marginRight: 15,
    "@media (max-width: 768px)": {
      fontSize: 17,
    },
    "@media (max-width: 576px)": {
      fontSize: 14,
    },
  },
  price: {
    fontSize: 26,
    color: COLORS.primary,
    fontFamily: "Poppins",
    letterSpacing: "20",
    "@media (max-width: 768px)": {
      fontSize: 20,
    },
    "@media (max-width: 576px)": {
      fontSize: 16,
    },
  },
  brandName: {
    marginTop: 8,
  },
  skuSec: {
    marginBottom: 5,
  },
  inStock: {
    color: "#00b300",
  },
  unavailable: {
    color: "#ff0000",
  },
  btnBock: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    "@media (max-width: 500px)": {
      marginTop: 10,
    },
  },
  inputBtnSec: {
    marginTop: 20,
  },
  weightSec: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
    marginTop: 10,
  },
  weight: {
    marginRight: "8px !important",
  },
  btnRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnTab: {
    marginRight: 10,
  },
  weightBtn: {
    border: `1px solid #E5E5E5`,
    color: COLORS.darkGrey1,
    padding: "4px 8px",
    borderRadius: "5px",
    fontSize: 13,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#E5E5E5",
      color: COLORS.darkGrey1,
      transition: "all 0.3s ease-in-out",
    },
    "@media (max-width: 576px)": {
      fontSize: 11,
    },
  },
  weightBtnActive: {
    backgroundColor: "#E5E5E5",
    color: COLORS.darkGrey1,
  },
  quantitySec: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  quantityBox: {
    marginLeft: 10,
  },
  buyNow: {
    marginRight: 10,
    background: "#fff !important",
    color: `${COLORS.darkGrey1} !important`,
    border: `1px solid ${COLORS.darkGrey1} !important`,
    "&:hover": {
      background: `${COLORS.darkGrey1} !important`,
      color: `${COLORS.white} !important`,
    },
  },
  ingredientsText: {
    color: COLORS.darkGrey1,
  },
  ingredientsValue: {
    fontSize: 14,
  },
  similarProductsSec: {
    padding: "50px 0",
    textAlign: "center",
  },
  sectionHeading: {
    fontSize: "25px !important",
    textAlign: "center",
    marginBottom: 30,
  },
  productRow: {
    alignItems: "center",
    justifyItems: "center",
    margin: "0 auto",
  },
  descriptionUl: {
    textTransform: "capitalize",
    "& li": {
      position: "relative",
      paddingLeft: 10,
      "&::before": {
        content: '""',
        position: "absolute",
        width: 6,
        height: 6,
        transform: "translateY(-50%) rotate(39deg)",
        background: COLORS.lightGrey2,
        top: 12,
        left: -5,
      },
    },
  },
  nutritionImg: {
    maxWidth: 200,
    width: "100%",
  },
  tabTitle: {
    fontSize: "16px !important",
    fontFamily: "Poppins !important",
    "@media (max-width: 991px)": {
      fontSize: "14px !important",
      marginTop: "30px !important",
    },
  },
  nutritionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 479px)': {
      flexDirection: 'column',
    }
  },
  nutiLeftCol: {
    width: '40%',
    '@media (max-width: 479px)': {
      width: '100%',
    }
  },
  nutiRightCol: {
    width: '60%',
    paddingLeft: '35px',
    '@media (max-width: 479px)': {
      width: '100%',
      paddingLeft: '0px',
      marginTop: '30px',
    }
  },
  divider: {
    width: 1,
    backgroundColor: COLORS.lightGrey,
    '@media (max-width: 479px)': {
      display: 'none',
    }
  }
}));

export default useStyles;
