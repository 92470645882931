import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from '../reducers';
import { LOGIN_SUCCESS } from '../constants/actionTypes';
import {userInfo_local_storage_key} from '../helpers/common';

const middlewares = process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];

const store = createStore(
  reducer,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : args => args,
  ),
);

if (localStorage.getItem(userInfo_local_storage_key)) {
  store.dispatch({
    type: LOGIN_SUCCESS,
    payload: JSON.parse(localStorage.getItem(userInfo_local_storage_key)),
  });
}

export default store;
