import React, { useCallback, useEffect, useState } from "react";
import ReactImageZoom from "react-image-zoom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateCart } from "../../actions/cart";
import { setMeta } from "../../actions/meta";
import { getReviews } from "../../actions/products";
import { showErrorSnackbar } from "../../actions/snackbar";
import { getItemList, getProduct } from "../../api/items";
import { COLORS } from "../../constant/colors";
import { UPDATE_CART } from "../../constants/actionTypes";
import { findProductInCart, updateCartData } from "../../helpers/cart";
import {
  convertToSlug, dataUrl, toArray
} from "../../helpers/common";
import AddCartButton from "../AddCartButton";
import MetaTags from "../Common/MetaTags";
import ProductLayout from "../ProductLayout";
import BasicTabs from "./BasicTabs";
import SimilarProducts from "./SimilarProducts";
import SingleProductDetailMeta from "./SingleProductDetailMeta";
import useStyles from "./styles";

const SingleProduct = () => {
  const classes = useStyles();

  let { productId } = useParams();

  const [productVariants, setProductVariants] = useState([]);
  const currProduct = useSelector((state) => state.products.product);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [mainImage, setMainImage] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [thumbs, setThumbs] = useState([]);
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.data);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);
  const tabRef = React.useRef();

  const handleResize = useCallback(() => {
    let width = window.innerWidth;
    window.addEventListener("resize", handleResize);

    if (width > 500) {
      setMainImage({
        zoomPosition: "right",
        width: 400,
        height: 350,
        zoomWidth: 400,
        background: "white",
        zIndex: 100,
        img: `${dataUrl}/products/${product?.id}/500x438-${product?.main_image}`,
      });
    } else {
      setMainImage({
        zoomPosition: "default",
        width: window.innerWidth,
        height: window.innerWidth - 50,
        zoomWidth: 600,
        background: "white",
        img: `${dataUrl}/products/${product?.id}/500x438-${product?.main_image}`,
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [product]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (product?.id) {
      dispatch(getReviews(product?.id));
    }
  }, [product, dispatch]);

  useEffect(() => {
    setLoading(true);
    getProduct(productId)
      .then((result) => {
        setProduct(result.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [productId]);

  useEffect(() => {
    setProduct(currProduct);
  }, [currProduct]);

  const changeProduct = useCallback(
    (id) => {
      setLoading(true);
      getProduct(id)
        .then((result) => {
          setProduct(result.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    },
    [setProduct]
  );

  const asyncCallsOnLoad = useCallback(async () => {
    try {
      const data = product;

      let media = data.media;

      if (media) {
        const thumbs = [];

        let mediaArr = toArray(media, ",");

        mediaArr.forEach((m) => {
          thumbs.push({
            img: m,
          });
        });

        setThumbs(thumbs);
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  }, [product]);

  useEffect(() => {
    asyncCallsOnLoad();
    handleResize();
    return () => {
      setMainImage({});
    };
  }, [asyncCallsOnLoad, handleResize]);

  const changeImage = (image) => {
    let width = window.innerWidth;
    window.addEventListener("resize", handleResize);

    if (width > 500) {
      setMainImage({
        zoomPosition: "right",
        width: 500,
        height: 350,
        zoomWidth: 450,
        background: "white",
        zIndex: 100,
        img: `${dataUrl}/products/${product?.id}/500x438-${image.img}`,
      });
    } else {
      setMainImage({
        zoomPosition: "default",
        width: "100%",
        height: "100%",
        zoomWidth: 600,
        background: "white",
        img: `${dataUrl}/products/${product?.id}/500x438-${image.img}`,
      });
    }
  };

  // finding parent from category id
  const categories = useSelector((state) => state.categories.data);

  const findCat = categories.find((cat) => cat.id === product?.category_id);
  const findMainCat = categories.find((cat) => cat.id === findCat?.parent_id);

  useEffect(() => {
    if (product?.name) {
      let breadcrumb = (
        <div className={classes.breadcrumb}>
          <Link to="/">
            <i className="fa fa-home" style={{ color: COLORS.primary }} />
          </Link>{" "}
          /
          {findMainCat?.name && findMainCat?.name?.toLowerCase() !== "main" ? (
            <> {findMainCat?.name} / </>
          ) : null}
          <Link
            className={classes.breadcrumbLink}
            to={`/category/${product?.category_id}/${convertToSlug(
              product?.category_name
            )}/1`}
          >
            {product?.category_name}
          </Link>{" "}
          / {product?.name}{" "}
        </div>
      );
      dispatch(setMeta({ title: "Product", breadcrumb }));
    }
  }, [
    classes.breadcrumb,
    classes.breadcrumbLink,
    dispatch,
    findMainCat,
    product,
  ]);

  useEffect(() => {
    let isCancelled = false;
    setProductVariants([]);
    if (!isCancelled) {
      getItemList(
        "products",
        `ProductsSearch[variants_of]=${product?.id}&sort=item_weight`
      ).then((json) => setProductVariants(json?.data));
    }
    return () => {
      isCancelled = true;
    };
  }, [product, dispatch]);

  const updateCartProduct = useCallback(
    (product, action) => {
      let newQty = quantity;
      // find product in cart
      let cartProduct = findProductInCart(cartData, product);

      if (action === "increase") {
        newQty = quantity + 1;
      }

      if (action === "decrease") {
        newQty = quantity - 1;
      }

      // atlease one qty must be there in cart.
      if (newQty === 0) {
        return;
      }

      // customer can not add more products than the available stock.
      if (newQty > product?.available_stock) {
        dispatch(
          showErrorSnackbar(
            `No more stock is available for the Product '${product?.name}'`
          )
        );
        return;
      }

      setQuantity(newQty);
      if (cartProduct) {
        const newCartData = updateCartData(cartData, product, newQty);
        dispatch({ type: UPDATE_CART, payload: newCartData });
      }
    },
    [quantity, cartData, dispatch]
  );

  useEffect(() => {
    let quantity = findProductInCart(cartData, product);
    if (quantity?.qty) {
      setQuantity(quantity.qty);
    } else {
      setQuantity(1);
    }
  }, [setQuantity, cartData, product]);

  const handleBuyNow = useCallback(
    (product) => {

      if (product?.available_stock < quantity || product.available_stock <= 0 || product.selling_price === 0) {
        dispatch(
          showErrorSnackbar(
            `No more stock is available for the Product '${product?.name}'`
          )
        );
        return;
      }
      const newCartData = updateCartData(cartData, product, quantity);
      dispatch(updateCart(newCartData));
      navigate("/cart");
    },
    [quantity, cartData, dispatch, navigate]
  );


  const loadMoreWeight = () => {
    if (productVariants.length === 0) {
      return null;
    }

    return (
      <>
        {productVariants[0]?.item_weight.length === 0 ? null : (
          <div className={classes.weightSec}>
            <p className={classes.weight}>Weight: </p>
            <div className={classes.btnRow}>
              {productVariants?.map((variant) => (
                <div
                  className={classes.products}
                  onClick={(_) => changeProduct(variant.id)}
                  key={"variant-" + variant.id}
                >
                  <div className={classes.btnTab}>
                    <Link
                      className={`${classes.weightBtn} ${
                        variant.id === product?.id ? classes.weightBtnActive : ""
                      }`}
                      to="#"
                    >
                      {variant?.item_weight} gms
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  let singleProduct = (
    <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 ">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="prod_left">
            <div className="owl-carousel" id="sync1">
              <div className="item">
                {mainImage.img ? <ReactImageZoom {...mainImage} /> : null}
              </div>
            </div>
            {thumbs.length > 0 ? (
              <div className="thumbs">
                {thumbs.map((thumb, index) => {
                  return (
                    <div key={"thumb-" + index} className="thumb-item">
                      <img
                        key={"thumb" + thumb.img}
                        onClick={() => changeImage(thumb)}
                        src={`${dataUrl}/products/${product?.id}/120x120-${thumb.img}`}
                        alt={product?.name}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div></div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="prod_right">
            <h2 className={classes.productName}>{product?.name}</h2>

            <div className={classes.priceSec}>
              {product?.mrp > product?.selling_price ? (
                <del className={classes.del}>
                  <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                  {product?.mrp}
                </del>
              ) : null}
              <span className={classes.price}>
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                {product?.selling_price}
              </span>
            </div>

            <div className={classes.skuSec}>
              <p className={classes.text}>SKU: {product?.sku}</p>
            </div>
            <div className={classes.availabilitySec}>
              <p className={classes.text}>
                Availability:

                {product?.available_stock > 0 && product.selling_price !== 0 ? (
                  <span className={classes.inStock}> In Stock</span>
                ) : (
                  <span className={classes.unavailable}> Out of Stock</span>
                )}
              </p>
            </div>

            <div>{loadMoreWeight()}</div>

            <div className={classes.quantitySec}>
              <p className={classes.text}>Quantity:</p>
              <div className={`quantity ${classes.quantityBox}`}>
                <div className="quantity-select">
                  <div
                    className="entry value-minus"
                    onClick={() => updateCartProduct(product, "decrease")}
                  >
                    &nbsp;
                  </div>
                  <div className="entry value">
                    <span className="span-1">{quantity}</span>
                  </div>
                  <div
                    className="entry single-product value-plus active"
                    onClick={() => updateCartProduct(product, "increase")}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>

            <div className={classes.btnBock}>
              <div onClick={() => handleBuyNow(product)} className={`btn-sec `}>
                <Link to="#" className={`my-btn ${classes.buyNow}`}>
                  Buy Now
                </Link>
              </div>
              <AddCartButton
                cartData={cartData}
                product={product}
                quantity={quantity}
              />
            </div>

            {/* {product?.attributes && (
              <div className={classes.ingredientsSec}>
                <p className={classes.ingredientsText}>Ingredients:</p>
                <p className={classes.ingredientsValue}>
                  {" "}
                  {product?.attributes
                    ? getList(product?.attributes)
                    : "No value found"}
                </p>
              </div>
            )} */}
          </div>
        </div>
        {/* <ProductsReview /> */}
      </div>
      {!loading ? (
        <BasicTabs
          tabRef={tabRef}
          tabValue={tabValue}
          setTabValue={setTabValue}
          product={product}
          setProduct={setProduct}
        />
      ) : null}
    </div>
  );

  return (
    <ProductLayout
      hideFilterSec={true}
      parentClass="product_detail"
      categoryId={product?.category_id}
    >
      <SingleProductDetailMeta product={product} />

      {product?.name ? (
        <MetaTags
          module='products'
          title={product?.name}
          description={product?.description || product?.highlights}
          keywords={product?.search_tags}
          image={
            dataUrl + "/products/" + product?.id + "/" + product?.main_image
          }
        ></MetaTags>
      ) : null}

      {loading ? (
        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 ">
          <div className={classes.loaderSec}>
            <img
              className={classes.loader}
              src="/images/loader1.gif"
              alt="loader"
            />
          </div>
        </div>
      ) : (
        singleProduct
      )}

      <SimilarProducts product={product} />
    </ProductLayout>
  );
};

export default SingleProduct;
