import React, { useEffect, useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../actions/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { authKey_local_storage_key, userInfo_local_storage_key } from "../../helpers/common";
import useStyles from "./styles";
import MetaTags from "../Common/MetaTags";

export default function Profile() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const customer = useSelector((state) => state.customers.profile);
  const token = localStorage.getItem(authKey_local_storage_key);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const { name, additional_mobile_number, mobile_number, pin_code, email } =
    useSelector((state) => state.customers.profile);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    if (!token) {
      dispatch(
        showErrorSnackbar("You are not logged out ! Please login to continue")
      );
      navigate("/");
    }
  }, [token, navigate, dispatch]);

  const [profile, setProfile] = useState({
    name: name || "",
    email: email || "",
    pin_code: pin_code || "",
    mobile_number: mobile_number || "",
    additional_mobile_number: additional_mobile_number || "",
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (!profile.name) {
      setErrors({ ...errors, name: "Name is required" });
      return;
    }

    if (!profile.mobile_number) {
      setErrors({ ...errors, mobile_number: "Mobile number is required" });
      return;
    }

    setLoading(true);

    return dispatch(updateProfile({ ...profile, id: customer.id })).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          dispatch(showSuccessSnackbar("Profile Updated successfully !"));
          const customer = res.data;
          localStorage.setItem(userInfo_local_storage_key, JSON.stringify({ customer, token }));
        } else
          dispatch(showErrorSnackbar("Sorry! Profile cannot be updated !"));
      }
    );
  };

  const onValueChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  return (
    <section className={classes.profileSec}>
      <MetaTags
        title="My Profile"
        description=""
        keywords=""
      ></MetaTags>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={8}
      >
        <h2 className={classes.heading}>My Profile</h2>
        <div className={classes.formSec}>
          {profile.name && profile.mobile_number ? (
            <div className={classes.form}>
              <div className="row">
                <div className="col-12">
                  <div className={classes.detailRow}>
                    <i className="fa fa-user-o" />
                    <div className={classes.col}>
                      <h6 className={classes.label}>Your Name:</h6>
                      {profile.name ? (
                        <span>{profile.name}</span>
                      ) : (
                        <span>- not added -</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className={classes.detailRow}>
                    <i className="fa fa-phone" />
                    <div className={classes.col}>
                      <h6 className={classes.label}>Mobile Number:</h6>
                      {profile.mobile_number ? (
                        <span>{profile.mobile_number}</span>
                      ) : (
                        <span>- not added -</span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="col-12">
                  <div className={classes.detailRow}>
                    <i className="fa fa-envelope-o" />
                    <div className={classes.col}>
                      <h6 className={classes.label}>Your Email:</h6>
                      {profile.email ? (
                        <span>{profile.email}</span>
                      ) : (
                        <span>- not added -</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className={classes.detailRow}>
                    <i className="fa fa-phone" />
                    <div className={classes.col}>
                      <h6 className={classes.label}>Alternate Mobile:</h6>
                      {profile.additional_mobile_number ? (
                        <span>{profile.additional_mobile_number}</span>
                      ) : (
                        <span>- not added -</span>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => navigate("/update-profile")}
                  className={classes.submit}
                  type="button"
                >
                  Edit
                </button>
              </div>
            </div>
          ) : (
            <form className={classes.form} onSubmit={onSubmit}>
              <Box className={classes.inputRow}>
                <Grid container alignItems="center">
                  <TextField
                    className={classes.inputFeild}
                    fullWidth
                    value={profile.name}
                    variant="standard"
                    name="name"
                    onChange={onValueChange}
                    label="Name *"
                    error={errors.name ? true : false}
                    helperText={errors.name}
                  />
                </Grid>
              </Box>

              <Box className={classes.inputRow}>
                <Grid container alignItems="center">
                  <TextField
                    className={classes.inputFeild}
                    fullWidth
                    value={profile.mobile_number}
                    variant="standard"
                    name="mobile_number"
                    label="Mobile Number *"
                    helperText={errors.mobile_number}
                    error={errors.mobile_number ? true : false}
                  />
                </Grid>
              </Box>

              <Box className={classes.inputRow}>
                <Grid container alignItems="center">
                  <TextField
                    className={classes.inputFeild}
                    fullWidth
                    variant="standard"
                    type="email"
                    name="email"
                    label="Email"
                    value={profile.email}
                    inputProps={{ minLength: 6 }}
                    onChange={onValueChange}
                  />
                </Grid>
              </Box>

              <Box className={classes.inputRow}>
                <Grid container alignItems="center">
                  <TextField
                    className={classes.inputFeild}
                    fullWidth
                    value={profile.additional_mobile_number}
                    variant="standard"
                    type="number"
                    name="additional_mobile_number"
                    label="Additional Mobile Number"
                  />
                </Grid>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                mt={2}
                className="login-btn"
              >
                <button className={classes.submit} type="submit">
                  {loading ? "Loading.." : "Update Profile"}
                </button>
              </Box>
            </form>
          )}
        </div>
      </Box>
    </section>
  );
}
