import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  aboutSec: {
    marginBottom: 60,
    "@media (max-width: 767px)": {
      marginBottom: 10,
    },
  },
  imgSec: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  row: {
    "@media (max-width: 767px)": {
      flexDirection: "column-reverse",
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      marginBottom: 30,
      textAlign: "center",
      marginTop: 20,
      paddingLeft: "3%",
    },
  },
  title: {
    marginBottom: 25,
    '@media (max-width: 767px)': {
      marginBottom: 10,
    }
  },
  paraDiv: {
    marginBottom: 20,
    '@media (max-width: 767px)': {
      marginBottom: 10,
    }
  }
}));
