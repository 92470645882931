import ajax from './ajax';

export function checkoutOrder(params) {
  return ajax('/orders', { method: 'POST', data: params });
}

export function placeOrder(data) {
  return ajax('/orders/checkout', { method: 'POST', data });
}

export function verifyPayment(data) {
  return ajax('/orders/verify_payment', { method: 'POST', data });
}

export function getAllOrders() {
  return ajax('/orders?sort=-id&per-page=1000');
}

export function getOrderDetails(orderId) {
  return ajax(`/orders/items?orderId=${orderId}`)
}
