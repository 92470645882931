import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constant/colors';

const useStyles = makeStyles(theme => ({
  modalSec: {
    backgroundColor: '#f8f9fa',
    minHeight: '100%'
  },
  couponCodeSec: {
    display: 'flex',
    border: '1px solid #f8f9fa',
    padding: '10px 18px',
    background: '#f8f9fa',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  applyCoupon: {
    margin: 0,
    '@media (max-width: 768px)': {
      fontSize: 14,
    }
  }, 
  loadingSec: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30%'
  },
  loader: {
    width: 50,
    height: 50,
  },
  appliedCoupon: {
    color: COLORS.success,
    margin: 0,
  },
  discountOfferImg: {
    width: 25,
    height: 25,
    marginRight: 5,
    '@media (max-width: 768px)': {
      width: 20,
      height: 20,
    }
  },
  arrowIcon: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 15px 15px 15px',
    background: '#fff',
    borderBottom: '1px solid #f8f9fa',
  },
  modalClose: {
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    marginTop: -10,
    '&:hover': {
      background: '#f8f9fa',
    }
  },
  modalBody: {
    padding: '20px 15px 15px 15px',
    overflow: 'overlay',
    minHeight: '100%',
  },
  couponInput: {
    background: '#fff',
    border: '1px solid #eeee !important',
    padding: '10px 15px !important',
    width: '100%',
    borderRadius: 5,
    fontSize: 14,
  },
  form: {
    position: 'relative',
    marginBottom: 15,
  },
  errorMsg: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
    marginLeft: 5,
  },
  inputApplyBtn: {
    position: 'absolute',
    right: 15,
    top: 10,
    zIndex: 1,
    border: 'none',
    background: '#fff',
    color: COLORS.primary,
    fontSize: 14,
  },
  couponCards: {
    padding: '10px',
    marginBottom: 10,
    border: '1px solid #f8f9fa',
    background: '#fff',
    borderRadius: 8,
  },
  couponCardTop: {
    display: 'flex',
    paddingBottom: 20,
    marginBottom: 13,
    borderBottom: '1px dashed gray',
  },
  couponImg: {
    width: 80,
    height: 80,
  },
  couponDetailSec: {
    marginLeft: 13,
  },
  title: {
    fontSize: 14,
  },
  description: {
    fontSize: 13,
  },
  couponCardBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  couponName: {
    fontSize: 13,
    '& span': {
      fontWeight: '600',
    }
  },
  applyBtn: {
    fontSize: 14,
    border: 'none',
    background: '#fff',
    color: COLORS.primary,
  },
  noCoupon: {
    textAlign: 'center',
    marginTop: '30%'
  }
}));

export default useStyles;