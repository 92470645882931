import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loaderSec: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100000,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

export default useStyles;