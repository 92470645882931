import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import CustomSnackbar from "../Common/CustomSnackbar";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import ScrollTopButton from "../ScrollTopButton";

function Layout() {
  const snackbarProp = useSelector((state) => state.snackbar);

  return (
    <>
      <CssBaseline />
      <Header />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
      <Footer />
      <CustomSnackbar
        open={snackbarProp.open}
        message={snackbarProp.message}
        severity={snackbarProp.severity}
        action={snackbarProp.action}
      />
      <ScrollTopButton />
    </>
  );
}

export default Layout;
