import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../constant/colors";
import {
  getAppliedFilters,
  getSettingByKey,
} from "../../helpers/common";
import useStyles from "./styles";

const FilterByPrice = ({
  onFilterChange,
  filteredPrice,
  setFilteredPrice,
  frontendSettings,
}) => {
  const classes = useStyles();
  const minFilterValue =
    getSettingByKey(frontendSettings, "filter_min_price")?.value || 0;
  const maxFilterValue =
    getSettingByKey(frontendSettings, "filter_max_price")?.value || 1000;
  const [value, setValue] = useState([minFilterValue, maxFilterValue]);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  function valueLabelFormat(value) {
    return `₹${value}`;
  }

  useEffect(() => {
    if (filteredPrice?.length === 0) {
      setValue([minFilterValue, maxFilterValue]);
    }
  }, [filteredPrice, maxFilterValue, minFilterValue]);

  const onChangeCommitted = (_, newValue) => {
    onFilterChange("price_range", newValue.join("-"));
    setFilteredPrice(value);
  };

  // const filterWeight =
  //   getSettingByKey(getFrontendSettings, "filter_weights")?.value || "";

  useEffect(() => {
    const getFilterValueFromParams = getAppliedFilters("price_range");
    if (getFilterValueFromParams) {
      const splitValue = getFilterValueFromParams?.split("-");
      setValue(splitValue);
      setFilteredPrice(splitValue);
    }
  }, [setValue, setFilteredPrice]);

  return (
    <>
      <div className={classes.filterMainSec}>
        <h4 className={classes.heading}>Filter By Price</h4>
        <div className={classes.sliderSec}>
          <div className={classes.sliderHeader}>
            <span>
              <i className="fa fa-inr mr-1" aria-hidden="true"></i>
              {value[0]}
            </span>
            <span>
              <i className="fa fa-inr mr-1" aria-hidden="true"></i>
              {value[1]}
            </span>
          </div>
          <Slider
            getAriaLabel={() => "Price range"}
            value={value}
            step={10}
            min={parseInt(minFilterValue)}
            max={parseInt(maxFilterValue)}
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
            onChangeCommitted={onChangeCommitted}
            valueLabelDisplay="auto"
            getAriaValueText={valueLabelFormat}
            sx={{
              color: COLORS.primary,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FilterByPrice;
