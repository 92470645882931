import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  newArrivalsSec: {
    marginBottom: 40,
    "@media (max-width: 767px)": {
      marginBottom: 0,
    },
    "@media (max-width: 500px)": {
      paddingBottom: 0,
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: 20,
    "@media (max-width: 767px)": {
      marginBottom: 20,
    },
  },
}));
