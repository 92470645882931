import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  logo: {
    maxWidth: 130,
    "& img": {
      minHeight: 90,
    },
    "@media (max-width: 500px)": {
      "& img": {
        maxHeight: 65,
        width: "contain",
        minHeight: 60,
      },
    },
  },
  logoSecDivider: {
    marginLeft: 10,
  },
  logoSec: {
    padding: "8px 0px",
  },
  minisearchSec: {
    position: "absolute",
    right: 0,
    display: "flex",
    transition: "all 0.5s ease-in-out",
    top: "-15px",
    width: "0%",
    height: "0",
    opacity: 0,
    zIndex: "10",
    alignItems: "center",
    paddingLeft: "10px",
    "& i": {
      color: COLORS.lightGrey2,
    },
  },
  minisearchSecActive: {
    visibility: "visible",
    background: "#f3f3f3",
    animation: "fadeInDown .4s ease-in-out",
    transition: "all 0.5s ease-in-out",
    opacity: 1,
    width: "280px",
    height: "40px",
    "@media (max-width: 480px)": {
      width: "180px",
      height: "35px",
      top: "-10px",
      "& i": {
        fontSize: "13px",
      },
    },
  },
  input: {
    border: "none",
    padding: "8px",
    backgroundColor: "#f3f3f3",
    maxWidth: "280px",
    width: "100%",
    fontSize: 13,
    "@media (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  inputButton: {
    border: "none",
    backgroundColor: "#f3f3f3",
    padding: "5px",
    display: "none",
  },
  inputButtonActive: {
    display: "block",
  },
  popoverBody: {
    padding: "10px 15px",
    margintop: "10px",
  },
  popoverList: {
    marginBottom: "5px !important",
    "&:last-child": {
      marginBottom: "0px !important",
    },
  },
  popoverLink: {
    color: COLORS.lightGrey2,
    cursor: "pointer",
    transition: "all 0.3s ease",
    fontSize: "15px",
    "&:hover": {
      color: COLORS.primary,
    },
  },
  headerButton: {
    position: "relative",
    padding: "0 15px 0 10px",
    "&:last-child": {
      paddingRight: "0",
      "&::before": {
        display: "none",
      },
    },
    "&:nth-child(3)": {
      paddingRight: "22px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      background: "url(/images/divider-big.png) no-repeat center",
      backgroundSize: "contain",
      width: "7px",
      height: "50px",
      right: 0,
      "@media (max-width: 991px)": {
        background: "url(/images/divider-small.png) no-repeat center",
        height: "20px",
      },
    },
  },
  buttonIcon: {
    color: COLORS.primary,
    fontSize: "18px",
  },
  cartCount: {
    position: "absolute",
    color: COLORS.white,
    background: COLORS.primary,
    top: "-9px",
    right: "10px",
    borderRadius: "50%",
    width: "17px",
    height: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
  },

  link: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: COLORS.darkGrey2,
    fontSize: "15px",
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      textDecoration: "none !important",
      color: COLORS.primary,
    },
  },
  menuBar: {
    marginLeft: 10,
  },
});

export default useStyles;
