import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  categoriesList: {
    marginBottom: 20,
  },
  heading: {
    fontSize: "26px",
    position: "relative",
    marginBottom: "25px",
    '&::before': {
      content: '""',
      width: '50px',
      borderRadius: 15,
      height: 4,
      position: "absolute",
      bottom: '-13px',
      left: "0",
      background: COLORS.primary,
    },
    '@media (max-width: 767px)': {
      fontSize: "20px !important",
    }
  },
  listingUl: {
    listStyle: "none",
    marginTop: 30,
  },
  listingLi: {
    borderBottom: `1px solid #e6e6e6`,
    position: "relative",
  },
  link: {
    color: COLORS.darkGrey1,
    transition: 'all 0.3s ease',
    padding: '10px 0',
    width: '100%',
    '&:hover': {
      color: COLORS.primary,
      '& div::before': {
        background: COLORS.primary,
      }
    },
    '@media (max-width: 767px)': {
      fontSize: 14
    },
    marginBottom: 10,
  },
  active: {
    color: COLORS.primary,
  },
  catName: {
    width: '100%',
    padding: "12px 17px",
    position: 'relative',
    '&::before': {
      content: '""',
      transition: 'all 0.3s ease-in-out',
      position: 'absolute',
      width: 8,
      height: 8,
      transform: 'translateY(-50%) rotate(39deg)',
      background: COLORS.lightGrey2,
      top: '50%',
      left: 0,
    }
  },
  catNameActive: {
    '&::before': {
      background: COLORS.primary,
    },
  },
  mainFilterSection: {
    marginBottom: 40
  }
}));

export default useStyles;
