import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constant/colors';

const useStyles = makeStyles({
  ordersSec: {
      padding: '50px 0px',
      paddingTop: "180px",
      "@media (max-width: 991px)": {
        paddingTop: "160px",
      },
      "@media (max-width: 500px)": {
        paddingTop: "140px",
      }
  },
  heading: {
      textAlign: 'center',
      marginBottom: 50,
      "@media (max-width: 500px)": {
        marginBottom: 30,
      }
  },
  loaderSec: {
    padding: '50px 0px',
    textAlign: 'center',
  },
  loader: {
      textAlign: 'center',
      width: 50,
      height: 50,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    borderRadius: 8,
    border:  `1px solid ${COLORS.primary}`,
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: '#eeee',
    }
  },
  cardArrow: {
    position: 'absolute',
    top: 15,
    right: 15,
    '& i': {
      fontSize: 22,
      fontWeight: 'bold',
     }
  },
  status: {
    fontSize: 16,
  },
  pending: {
    borderColor: COLORS.primary,
  },
  paid: {
    borderColor: COLORS.success,
  },
  paymentPaid: {
    color: COLORS.success,
  },
  paymentPending: {
    color: COLORS.primary,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease-in-out',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: '#f1f1f1',
      '& i': {
        color: '#000'
      },
    },
  },
  orderDetailSec: {
    padding: '10px',
    position: 'relative',
    paddingBottom: 80,
  },
  itemTitle: {
    fontSize: 24,
    marginBottom: 10,
  },
  productRow: { 
    border: '1px solid #fff',
    borderBottomColor: '#e6e6e6',
    padding: '10px 0',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemDetail: {
    marginLeft: '15px',
  },
  detailImg: {
    width: 120,
    height: 120,
  },
  orderFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 10px',
    alignItems: 'center',
  },
  orderTotal: {
    display: 'flex',
    alignItems: 'center',
  },
  totalAmount: {
    fontSize: 18,
    marginRight: 5,
  },
  reorderBtn: {
    background: COLORS.primary,
    color: '#fff',
    border: 'none',
    padding: '10px 25px',
    transition: 'all 0.3s ease-in-out',
    borderRadius: 5,
    '&:hover': {
      background: COLORS.secondary,
    }
  }
})  

export default useStyles;