import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  checkoutSec: {
    padding: "180px 15px 50px",
    "@media (max-width: 991px)": {
      paddingTop: "160px",
    },
    "@media (max-width: 500px)": {
      paddingTop: "140px",
    }
  },
  heading: {
    textAlign: "center",
    marginBottom: 50,
    fontSize: "26px",
    "@media (max-width: 767px)": {
      marginBottom: 20,
    },
  },
  leftCol: {
    "@media (max-width: 767px)": {
      margin: "0 -15px",
      marginBottom: 20,
    },
  },
  detailBox: {
    padding: "30px 30px 15px",
    background: "#f8f9fa",
    marginBottom: 40,
    width: "93%",
    "@media (max-width: 767px)": {
      width: "100%",
      marginBottom: 20,
      padding: "20px 20px 10px",
    },
  },
  li: {
    marginBottom: 15,
    "&:last-child": {
      marginBottom: 0,
    },
    "& span": {
      fontWeight: "bold",
    },
    "@media (max-width: 480px)": {
      fontSize: 14,
    },
  },
  btnSec: {
    marginTop: 25,
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  btnLink: {
    padding: "8px 30px",
    color: "#fff",
    display: "inline-block",
    borderRadius: 5,
    background: COLORS.primary,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "#fff",
      background: COLORS.secondary,
    },
  },
  paymentSecHeading: {
    "@media (max-width: 767px)": {
      fontSize: "17px",
      marginBottom: 15,
    },
  },
  paymentOption: {
    "@media (max-width: 767px)": {
      "& span": { fontSize: 14 },
    },
  },
  orderTime: {
    border: "1px solid #e6e6e6",
    marginTop: 20,
    padding: "10px 10px",
  },
  orderTimeBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& i':  {
      cursor: 'pointer',
      padding: 5
    }
  } 
}));

export default useStyles;
