import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  scrollTopButton: {
    position: "fixed",
    bottom: 30,
    right: 30,
    width: 40,
    height: 40,
    background: COLORS.golden,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.3)",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    opacity: 0,
    visibility: "hidden",
    '@media (max-width: 500px)': {
      width: 35,
      height: 35,
      bottom: 20,
      right: 20,
    }
  },
  scrollTopButtonActive: {
    opacity: 1,
    visibility: "visible",
  },
  icon: {
    color: COLORS.white,
    fontSize: 25,
  },
}));

export default useStyles;
