import React, { useEffect } from "react";
import BannerCarousel from "./BannerCarousel";
// import CategoryBanners from "./CategoryBanners";
import OurSpecials from "./OurSpecials";
import AboutUs from "./AboutUs";
import BestSellers from "./BestSellers";
import FooterBanner from "./FooterBanner";
import HomeCategories from "./HomeCategories";
import MetaTags from "../Common/MetaTags";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <section>
      <MetaTags
        module='home'
        title='Welcome'
        image=''
        description={`Avon Namkeen is a leading manufacturer and supplier of namkeens and Indian snacks.`}
      />

      <BannerCarousel />
      <BestSellers />
      <OurSpecials />
      <HomeCategories />
      {/* <CategoryBanners /> */}
      <FooterBanner />
      <AboutUs />
    </section>
  );
};

export default HomePage;
