import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  appLogoUrl,
  appName,
  appUrl,
  getParsedJson,
  getSettingByKey,
} from "../../helpers/common";
import { useLocation } from "react-router-dom";

const SEOTags = () => {
  const settings = useSelector((state) => state.settings.data);

  const description = getSettingByKey(
    settings,
    "homepage_meta_description"
  )?.value;
  const company_address = getSettingByKey(
    settings,
    "company_address_seo"
  )?.value;
  const company_contact_number = getSettingByKey(
    settings,
    "company_contact_number"
  )?.value;

  const parsedCompanyAddress = getParsedJson(company_address, {});

  const socialLinks = settings
    ?.filter((item) => item?.key?.startsWith("social_links_"))
    ?.map((item) => item.value);

  const websiteData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: appUrl,
    name: appName,
    potentialAction: {
      "@type": "SearchAction",
      target: `${appUrl}/search/{search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };

  const localBusiness = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: appName,
    description: description || "",
    address: {
      "@type": "PostalAddress",
      streetAddress: parsedCompanyAddress?.streetAddress,
      addressLocality: parsedCompanyAddress?.addressLocality,
      addressRegion: parsedCompanyAddress?.addressRegion,
      postalCode: parsedCompanyAddress?.postalCode,
      addressCountry: parsedCompanyAddress?.addressCountry,
    },
    telephone: company_contact_number || "",
    openingHours: "Mo-Sa 09:00-18:00",
    image: appLogoUrl,
    url: appUrl,
    sameAs: socialLinks,
  };

  const webPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: appName,
    description: description,
    url: appUrl,
  };

  const organisation = {
    "@context": "https://schema.org",
    "@type": "Organization",
    Name: appName,
    URL: appUrl,
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: company_contact_number,
        contactType: "Customer Service",
      },
    ],
    logo: appLogoUrl,
    sameAs: socialLinks,
  };

  const location = useLocation();
  // Generate the full URL of the current page
  const currentUrl = `${appUrl}${location?.pathname}`;

  return (
    <>
      <Helmet>
        {/* Canonical Link */}
        {location?.pathname && <link rel='canonical' href={currentUrl} />}

        <script type='application/ld+json'>
          {JSON.stringify(websiteData)}
        </script>

        <script type='application/ld+json'>
          {JSON.stringify(localBusiness)}
        </script>

        <script type='application/ld+json'>{JSON.stringify(webPage)}</script>

        <script type='application/ld+json'>
          {JSON.stringify(organisation)}
        </script>
      </Helmet>
    </>
  );
};

export default SEOTags;
