import {
  CHECKOUT_ORDER_SUCCESS,
  CHECKOUT_ORDER_FAILURE,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_FAILURE,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE
} from '../constants/actionTypes';
import { createAction } from 'redux-actions';
import * as Order from '../api/orders';
import axios from "axios";
import { apiUrl } from '../helpers/common';
const checkoutOrderSuccess = createAction(CHECKOUT_ORDER_SUCCESS);
const checkoutOrderFailure = createAction(CHECKOUT_ORDER_FAILURE);

export function checkoutOrder(params) {
  return dispatch => Order.checkoutOrder(params)
    .then((json) => {
      dispatch(checkoutOrderSuccess(json));
      return json;
    })
    .catch((error) => {
      dispatch(checkoutOrderFailure(error));
      return error;
    })
}

const placeOrderSuccess = createAction(PLACE_ORDER_SUCCESS);
const placeOrderFailure = createAction(PLACE_ORDER_FAILURE);

export function placeOrder(data) {
  return dispatch => Order.placeOrder(data)
    .then((json) => {
      dispatch(placeOrderSuccess(json));
      return json;
    })
    .catch((error) => {
      dispatch(placeOrderFailure(error));
      return error
    });
}

const getAllOrderSuccess = createAction(GET_ALL_ORDER_SUCCESS);
const getAllOrderFailure = createAction(GET_ALL_ORDER_FAILURE);

export function getAllOrder() {
  return dispatch => Order.getAllOrders()
    .then(json => dispatch(getAllOrderSuccess(json)))
    .catch(error => dispatch(getAllOrderFailure(error)));
}

const getOrderDetailsSuccess = createAction(GET_ORDER_DETAILS_SUCCESS);
const getOrderDetailsFailure = createAction(GET_ORDER_DETAILS_FAILURE);

export function getOrderDetails(orderId) {
  return dispatch => Order.getOrderDetails(orderId)
    .then((json) => {
      dispatch(getOrderDetailsSuccess(json));
      return true;
    })
    .catch(error => {
      dispatch(getOrderDetailsFailure(error));
      return false;
    });
}

export const callOrdersCheckoutApi = async (dispatch, token, requestBody) => {
  requestBody.attachments = requestBody.customer.attachments;
  return axios.post(
    `${apiUrl}/orders/checkout`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// const checkoutOrderSuccess = createAction(CHECKOUT_ORDER_SUCCESS);
// const checkoutOrderFailure = createAction(CHECKOUT_ORDER_FAILURE);

// export function verifyPayment(params) {
//   return dispatch => Order.checkoutOrder(params)
//     .then((json) => {
//       dispatch(checkoutOrderSuccess(json));
//       return json;
//     })
//     .catch((error) => {
//       dispatch(checkoutOrderFailure(error));
//       return error;
//     })
// }

export const verifyPayment = async (token, requestBody) => {
  return axios.post(`${apiUrl}/orders/verify_payment`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};