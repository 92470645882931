import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles(() => ({
  filterMainSec: {
    marginTop: 30,
    borderBottom: `1px solid ${COLORS.lightGrey}`,
    paddingBottom: 30
  },
  heading: {
    fontSize: "22px",
    position: "relative",
    marginBottom: "25px",
    "&::before": {
      content: '""',
      width: "50px",
      borderRadius: 15,
      height: 4,
      position: "absolute",
      bottom: "-13px",
      left: "0",
      background: COLORS.primary,
    },
    "@media (max-width: 767px)": {
      fontSize: "20px !important",
    },
  },
  filterItems: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
  },
  filterItem: {
    padding: "4px 10px",
    // background: COLORS.primary,
    color: COLORS.black,
    marginRight: "10px",
    marginBottom: "10px",
    fontSize: "12px",
    borderRadius: 4,
    border: `1px solid ${COLORS.lightGrey}`,
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      background: COLORS.primary,
      color: COLORS.white,
      border: `1px solid ${COLORS.primary}`,
      transition: "all 0.3s ease",
    },
  },
  activefilterItem: {
    background: COLORS.primary,
    color: COLORS.white,
    border: `1px solid ${COLORS.primary}`,
  }
}));

export default useStyles;
