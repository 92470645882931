import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";
// import colors from "../../helpers/colors";

const useStyles = makeStyles((theme) => ({
  innerLoaderSec: {
    padding: 50,
    textAlign: "center",
    paddingTop: 250,
  },
  innerLoaderImg: {
    width: "50px",
    height: "50px",
  },
  shippingForm: {
    "@media (max-width: 767px)": {
      margin: "0 -15px",
      marginBottom: "25px",
    },
  },
  inputFormBox: {
    border: "1px solid #e6e6e6",
    padding: 30,
    marginRight: 20,
  },
  inputForm: {
    marginBottom: 10,
  },
  shippingSec: {
    padding: "50px 15px",
    maxWidth: "1000px",
    margin: "0 auto",
    paddingTop: "180px",
    "@media (max-width: 991px)": {
      paddingTop: "160px",
    },
    "@media (max-width: 500px)": {
      paddingTop: "140px",
    }
  },
  heading: {
    marginBottom: 30,
    fontSize: 22,
  },
  shippingFeild: {
    padding: "10px 0 !important",
  },
  btnSec: {
    marginTop: 25,
    width: "50%",
    textAlign: "center",
  },
  btnLink: {
    padding: "8px 30px",
    color: "#fff",
    display: "inline-block",
    borderRadius: 5,
    background: COLORS.primary,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "#fff",
      background: COLORS.secondary,
    },
  },
  cartItemContainer: {
    border: "1px solid #666",
    borderRadius: 6,
    alignItems: "center",
  },
  shippingBlock: {
    marginBottom: 20,
    alignItems: "flex-start",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  errorText: {
    color: "red",
    fontSize: 13,
  },
  images: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: "1px solid #aaa",
    borderRadius: 6,
  },
  products: {
    "& > *": {
      maxWidth: 1200,
      margin: "auto",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: 16,
    "&:hover": {
      color: COLORS.primary,
    },
  },
  addressSelect: {
    display: "flex",
    marginTop: 20,
  },
  addressSelectBtn: {
    padding: "6px 20px",
    border: "1px solid #e6e6e6",
    borderRadius: 20,
    marginRight: 10,
    fontSize: 13,
    cursor: "pointer",
  },
  addressSelectBtnActive: {
    borderColor: COLORS.primary,
    color: COLORS.primary,
  },
  submitBtn: {
    border: "none",
    width: "95%",
    textAlign: "center",
  },
  productName: {
    fontSize: 15,
    fontWeight: '500'
  },
  productPrice: {
    fontSize: 14,
  },
  productTotalPrice: {
    fontSize: 15,
  },
  orderTime: {
    border: "1px solid #e6e6e6",
    marginTop: 20,
    padding: "10px 10px",
  },
  orderTimeBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& i':  {
      cursor: 'pointer',
      padding: 5
    }
  },
  inputRow: {
    display: 'flex',
    marginBottom: 20,
  },
  citySelect: {
    width: '95%',
    textAlign: 'left',
  },
  label: {
    color: `rgba(0, 0, 0, 0.6)`,
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: 400,
    fontSize: 13,
    marginBottom: 0,
    position: 'absolute',
    top: -2,
  },
  errorMsg: {
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    bottom: -30,
  },
  pincodeLink: {
    color: '#d32f2f',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontSize: 13,
    position: 'absolute',
    top: 52,
    lineHeight: 1.2
  },
  inputFeildAddress: {
    '& input': {
      paddingRight: 70,
    }
  },
  characterLimit: {
    position: 'absolute',
    right: 0,
    top: 30,
    fontSize: 12,
    color: '#999',
  },
}));

export default useStyles;
