import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constant/colors';

export default makeStyles(() => ({
  bannerSection: {
    marginBottom: 60,
    minHeight: 300,
    paddingTop: 140,
    '@media (max-width: 991px)': {
      marginBottom: 40,
      minHeight: 'auto', 
      paddingTop: 130,
    },
    '@media (max-width: 500px)': {
      paddingTop: 110,
      marginBottom: 25,
    }
  },
  bannerSec: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  bannerImg: {
    width: 'inherit',
    maxWidth: 'inherit',
    '@media (max-width: 1200px)': {
      maxWidth: '130%',
      left: '-10%',
      textAlign: 'left'
    },
    '@media (max-width: 500px)': {
      maxWidth: '100%',
    }
  },
  container: {
    position: 'relative',
  },
  bannerContents: {
    position: 'absolute',
    left: '7%',
    width: '45%',
  },
  bannerTitle: {
    fontSize: 50,
    letterSpacing: 1.2,
  },
  span1: {
    color: COLORS.primary,
  },
  span2: {
    color: COLORS.secondary,
  },
  text: {
    fontSize: 19,
    fontFamily: 'Poppins',
    fontWeight: 'regular',
    fontStyle: 'normal',
    color: COLORS.darkGrey2,
    marginTop: 20,
  }
}));
