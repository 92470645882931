import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getSimilarProducts } from "../../api/items";
import ProductBox from "../ProductContainer/ProductBox";
import useStyles from "./styles";

export default function SimilarProducts({ product }) {
  const [similarProducts, setSimilarProducts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && product.id) {
      getSimilarProducts(product.id)
        .then(function (response) {
          setSimilarProducts(response.data);
        })
        .catch(function (error) {
          console.error("error::", error);
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [product]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 481 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const children = (
    <div className={`row ${classes.productRow}`}>
      {similarProducts?.map((similarProduct) => (
          <ProductBox
            key={similarProduct.id}
            isSimilarProducts={true}
            product={similarProduct}
          />
      ))}
    </div>
  );

  return (
    <div className="container">
      {similarProducts.length > 0 ? (
        <div className={classes.similarProductsSec}>
          <h3 className={classes.sectionHeading}>Similar Products</h3>
          {similarProducts?.length > 3 ? (
            <Carousel
              draggable={true}
              keyBoardControl={true}
              responsive={responsive}
              infinite={true}
              partialVisible={"false"}
              autoPlay
              autoPlaySpeed={3000}
              arrows={true}
            >
              {children}
            </Carousel>
          ) : (
            children
          )}
        </div>
      ) : null}
    </div>
  );
}
