import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { convertToSlug, productImageUrl } from "../../helpers/common";
import "../../index.css";
import AddCartButton from "../AddCartButton";
import useStyles from "./styles";

export default function ProductBox({ product, isSimilarProducts = false }) {
  const link = `/product/${product.id}/${convertToSlug(product.url_slug)}`;
  const cartData = useSelector((state) => state.cart.data);
  const quantity = 1;
  const bootstrapClasses = isSimilarProducts
    ? "col-lg-3 col-md-3 col-6"
    : "col-lg-4 col-md-6 col-sm-4 col-6";

  const classes = useStyles();
  return (
    <div key={product.id} className={`${bootstrapClasses} ${classes.myCol}`}>
      <Link to={link}>
        <div className={classes.productImgBlock}>
          <img
            className={classes.productImg}
            src={productImageUrl(
              product.main_image,
              "products",
              product.id,
              "290x290-"
            )}
            alt={product?.name}
          />
        </div>
        <div className={classes.content}>
          <h3>
            {product.name} ({product.item_weight} gms)
          </h3>
          
          <div className={classes.priceSec}>
            {product.mrp > product.selling_price ? (
              <del className={classes.del}>
                <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                {product.mrp}
              </del>
            ) : null}
            <span className={classes.price}>
              {" "}
              <i className="fa fa-inr mr-1" aria-hidden="true"></i>
              {product.selling_price}
            </span>
          </div>
        </div>
      </Link>
      <AddCartButton
        cartData={cartData}
        product={product}
        quantity={quantity}
      />
    </div>
  );
}
