import React, { useEffect, useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import useStyles from "./styles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { updateAddress } from "../../../api/customer";
import { getCustomerAddress } from "../../../actions/customer";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { getSettingByKey, getWordCount } from "../../../helpers/common";
import SelectCity from "../../Common/SelectCity";
import { Link } from "react-router-dom";
import SelectState from "../../Common/SelectState";

export default function UpdateAddressModal({
  openUpdateAddressModal,
  setOpenUpdateAddressModal,
  selectedAddressId,
  selectedAddress,
  setSelectedAddress
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [addressType, setAddressType] = useState(selectedAddress.address_type);
  const dispatch = useDispatch();
  const {
    mobile_number,
    email,
    pin_code,
    contact_person,
    full_address,
    address_name,
    city,
    state,
  } = selectedAddress;

  const settings = useSelector((state) => state.settings.data);
  const findAllowedPincodes = getSettingByKey(
    settings,
    "allowed_pincodes"
  )?.value;

  useEffect(() => {
    setCustomer(selectedAddress);
    setAddressType(selectedAddress.address_type);
  }, [selectedAddress]);

  const [customer, setCustomer] = useState({
    address_name: address_name || "",
    contact_person: contact_person || "",
    email: email || "",
    full_address: full_address || "",
    pin_code: pin_code || "",
    mobile_number: mobile_number || "",
    city: city || "",
    state: state || "",
  });

  const addressCharCount = customer?.full_address?.length;
  const maxAddressChar = 200;
  const addreddCharLength = maxAddressChar - addressCharCount;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!customer.address_name) {
      setErrors({ address_name: "Address name is required" });
      return;
    }
    if (!customer.mobile_number) {
      setErrors({ mobile_number: "Mobile number is required" });
      return;
    }
    if (
      !customer?.mobile_number?.match("[0-9]{10}") ||
      customer?.mobile_number?.length !== 10
    ) {
      setErrors({ mobile_number: "Please provide valid mobile number" });
      return;
    }
    if (!customer.contact_person) {
      setErrors({ contact_person: "Name is required" });
      return;
    }
    if (!customer.full_address) {
      setErrors({ full_address: "Address is required" });
      return;
    }
    if (
      customer.full_address?.length < 40 ||
      getWordCount(customer.full_address) < 5
    ) {
      setErrors({
        full_address: "Address must be 5 word and 40 character long.",
      });
      return;
    }
    if (!customer.city) {
      setErrors({ city: "City is required" });
      return;
    }

    if (!customer.state) {
      setErrors({ state: "State is required" });
      return;
    }

    if (!customer.pin_code) {
      setErrors({ pin_code: "Pin code is required" });
      return;
    }

    if (
      findAllowedPincodes &&
      !findAllowedPincodes.includes("*") &&
      !findAllowedPincodes.includes(customer.pin_code)
    ) {
      setErrors({ pin_code: `Pin code is not allowed ` });
      return;
    }

    setLoading(true);

    updateAddress({ ...customer, address_type: addressType }, selectedAddressId)
      .then(() => {
        dispatch(getCustomerAddress()).then((ack) => {
          dispatch(showSuccessSnackbar("Address updated successfully"));
          setOpenUpdateAddressModal(false);
          setSelectedAddress({...selectedAddress, state: customer.state})
        });
      })
      .catch((_err) => {
        dispatch(showErrorSnackbar("Error occurred, please try again later."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onValueChange = (event) => {
    if (event.target.value.length <= 200) {
      setErrors({});
      setCustomer({ ...customer, [event.target.name]: event.target.value });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0 !important",
    width: "100%",
    height: "85%",
    maxWidth: 550,
    borderRadius: "10px",
    bgcolor: "background.paper",
    // boxShadow: `0px 4px 16px -6px ${COLORS.lightGrey}`,
    transition: "all 0.5s ease-in-out",
    overflow: "overlay !important",
    overflowX: "hidden !important",
    "@media (max-width: 991px)": {
      width: "90%",
      height: "80%",
    },
    "@media (max-width: 550px)": {
      width: "95%",
      height: "95%",
    },
  };

  const handleClose = () => {
    setOpenUpdateAddressModal(false);
    setErrors({});
  };

  return (
    <>
      <Modal
        open={openUpdateAddressModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={openUpdateAddressModal}>
          <Box sx={style}>
            <div className='container'>
              <div className={classes.myAddressesSec}>
                <form className={classes.form} onSubmit={onSubmit}>
                  <div className={classes.header}>
                    <h2 className={classes.heading}>Edit Address</h2>
                    <button
                      type='button'
                      className={classes.close}
                      onClick={handleClose}
                    >
                      <i className='fa fa-times'></i>
                    </button>
                  </div>

                  <Box className={classes.inputRow}>
                    <Grid container alignItems='center'>
                      <TextField
                        className={classes.inputFeild}
                        fullWidth
                        value={customer.contact_person}
                        variant='standard'
                        name='contact_person'
                        onChange={onValueChange}
                        label='Contact Name *'
                        error={errors.contact_person ? true : false}
                        helperText={errors.contact_person}
                      />
                    </Grid>
                  </Box>

                  <Box className={classes.inputRow}>
                    <Grid container alignItems='center'>
                      <TextField
                        className={classes.inputFeild}
                        fullWidth
                        value={customer.mobile_number}
                        type="number"
                        variant='standard'
                        name='mobile_number'
                        label='Mobile Number *'
                        helperText={errors.mobile_number}
                        error={errors.mobile_number ? true : false}
                        onChange={onValueChange}
                      />
                    </Grid>
                  </Box>

                  <Box className={classes.inputRow}>
                    <Grid container alignItems='center' position='relative'>
                      <TextField
                        className={`${classes.inputFeild} ${classes.inputFeildAddress}`}
                        fullWidth
                        value={customer.full_address}
                        variant='standard'
                        name='full_address'
                        onChange={onValueChange}
                        label='Full Address *'
                        error={errors.full_address ? true : false}
                        helperText={errors.full_address}
                      />

                      <div
                        className={classes.characterLimit}
                      >{`(${addreddCharLength} / ${maxAddressChar})`}</div>
                    </Grid>
                  </Box>

                  <Box className={classes.inputRow} minHeight={60}>
                    <Grid container alignItems='center' position={"relative"}>
                      <SelectCity
                        classes={classes}
                        errors={errors}
                        setCustomer={setCustomer}
                        customer={customer}
                        defaultValue={{
                          value: customer?.city,
                          label: customer?.city,
                        }}
                      />
                    </Grid>
                    <Grid container alignItems='center' position={"relative"}>
                      <SelectState
                        classes={classes}
                        errors={errors}
                        setCustomer={setCustomer}
                        customer={customer}
                        defaultValue={{
                          value: customer?.state,
                          label: customer?.state,
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      alignItems='center'
                      position='relative'
                      className='mobile-hide'
                    >
                      <TextField
                        className={classes.inputFeild}
                        fullWidth
                        value={customer.pin_code}
                        type="number"
                        variant='standard'
                        name='pin_code'
                        onChange={onValueChange}
                        label='Pin Code *'
                        error={errors.pin_code ? true : false}
                        // helperText={errors.pin_code}
                      />
                      {errors.pin_code && !customer.pin_code && (
                        <span className={classes.pincodeLink}>
                          Pin code is required
                        </span>
                      )}

                      {errors.pin_code &&
                        findAllowedPincodes &&
                        !findAllowedPincodes.includes(customer.pin_code) && (
                          <span className={classes.pincodeLink}>
                            Pin code is not allowed{" "}
                            <Link
                              to='/pages/available-pincodes'
                              target='_blank'
                            >
                              Click here
                            </Link>
                          </span>
                        )}
                    </Grid>
                  </Box>

                  {/* this box is showing on mobile only */}
                  <Box className={`${classes.inputRow} web-hide`}>
                    <Grid container alignItems='center' position={"relative"}>
                      <TextField
                        className={classes.inputFeild}
                        fullWidth
                        value={customer.pin_code}   
                        type="number"
                        variant='standard'
                        name='pin_code'
                        onChange={onValueChange}
                        label='Pin Code *'
                        error={errors.pin_code ? true : false}
                        // helperText={errors.pin_code}
                      />
                       {errors.pin_code && !customer.pin_code && (
                        <span className={classes.pincodeLink}>
                          Pin code is required
                        </span>
                      )}
                      {errors.pin_code &&
                        findAllowedPincodes &&
                        !findAllowedPincodes.includes(customer.pin_code) && (
                          <span className={classes.pincodeLink}>
                            Pin code is not allowed{" "}
                            <Link
                              to='/pages/available-pincodes'
                              target='_blank'
                            >
                              Click here
                            </Link>
                          </span>
                        )}
                    </Grid>
                  </Box>

                  <Grid className={classes.inputForm}>
                    <TextField
                      value={customer.landmark}
                      fullWidth
                      error={errors.landmark ? true : false}
                      name='landmark'
                      label='Landmark'
                      placeholder='Landmark'
                      onChange={onValueChange}
                      variant='standard'
                      className={classes.inputFeild}
                    />
                    {errors.landmark ? (
                      <Box className={classes.errorText}>
                        Please enter a valid additional mobile number
                      </Box>
                    ) : null}
                  </Grid>

                  <Box className={classes.inputRow}>
                    <Grid container alignItems='center'>
                      <TextField
                        className={classes.inputFeild}
                        fullWidth
                        value={customer.address_name}
                        variant='standard'
                        name='address_name'
                        onChange={onValueChange}
                        label='Name this Address'
                        placeholder='i.e. My Home, My Office, My Sister Home etc'
                        error={errors.address_name ? true : false}
                        helperText={errors.address_name}
                      />
                    </Grid>
                  </Box>

                  <Grid className={classes.inputForm}>
                    <h6 className={classes.title}>Save address as</h6>
                    <div className={classes.addressSelect}>
                      <div
                        onClick={() => setAddressType("home")}
                        className={`${classes.addressSelectBtn} ${
                          addressType === "home"
                            ? classes.addressSelectBtnActive
                            : ""
                        } `}
                      >
                        Home
                      </div>
                      <div
                        onClick={() => setAddressType("work")}
                        className={`${classes.addressSelectBtn} ${
                          addressType === "work"
                            ? classes.addressSelectBtnActive
                            : ""
                        } `}
                      >
                        work
                      </div>
                    </div>
                  </Grid>

                  <Box
                    display='flex'
                    justifyContent='center'
                    mt={2}
                    className='login-btn'
                  >
                    {/* <button
                      onClick={addressDelete}
                      className={`${classes.submit} ${classes.delete}`}
                      type="button"
                    >
                      {deleteLoading ? "Loading.." : "Delete Address"}
                    </button> */}
                    <button className={classes.submit} type='submit'>
                      {loading ? "Loading.." : "Save Address"}
                    </button>
                  </Box>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
