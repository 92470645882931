import React, { useCallback, useEffect, useRef, useState } from "react";
import { searchProducts } from "../../../../actions/products";
import { convertToSlug, productImageUrl } from "../../../../helpers/common";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../../../actions/snackbar";


export default function HeaderSearch({ classes }) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    result: [],
    state: "Idle",
  });

  const headerSearchRef = useRef()

  useEffect(() => {
    const handler = (event) => {
      if (
        !headerSearchRef.current.contains(event.target) 
      ) {
        setSearchKeyword("");
        setSearch({
          result: [],
          state: "Idle",
        })
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [])

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setSearch({
        result: [],
        state: "Idle",
      });
    }
    if (searchKeyword) {
      setSearch({
        result: [],
        state: "Searching",
      });
    }

    const searchOnType = async () => {
      if (searchKeyword) {
        let result = await searchProducts(searchKeyword);
        setSearch({
          result: result.data,
          state: "SearchFinished",
        });
      }
    };
    const timerId = setTimeout(() => {
      searchOnType();
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchKeyword]);

  if (search.state === "Searching") {
    var content = (
      <div className="search-result">
        <CircularProgress size={20} />
      </div>
    );
  }

  const openProduct = (data) => {
    navigate(`/product/${data.id}/${convertToSlug(data.name)}`);
    setSearchKeyword("");
  };

  if (search.state === "SearchFinished") {
    if (search.result.length > 0) {
      content = (
        <div className="search-result">
          {search?.result?.map((data) => (
            <div
              onClick={() => openProduct(data)}
              key={data.id}
              className="search-result-item"
            >
              <div className="search-result-item-content">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: 50, height: 50, marginRight: 8 }}
                    src={productImageUrl(data.main_image, "products", data.id)}
                   alt=""/>
                  <h5>{data.name}</h5>
                </div>
                <div className="price-sec">
                  <h6><i className="fa fa-inr mr-1" aria-hidden="true"></i>{data.selling_price}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      content = (
        <div className="search-result">
          <div className="no-result-found">
            <h6>No result found</h6>
          </div>
        </div>
      );
    }
  }

  const handleSearchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!searchKeyword) {
        dispatch(showErrorSnackbar("Please enter any keyword"));
        return false;
      } else {
        navigate("/search/" + searchKeyword);
      }
    },
    [searchKeyword, dispatch, navigate]
  );

  return (
    <div ref={headerSearchRef} className="top-header-sec">
      <form
        className={`${classes.inputSec} input-sec`}
        onSubmit={handleSearchSubmit}
        autoComplete="nope"
      >
        <input
          type="text"
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder="Search Entire Store Here"
          name="search-products"
          value={searchKeyword}
          className={classes.input}
          autoComplete="nope"
        />
        <i
          onClick={() => setSearchKeyword("")}
          className={`fa fa-times clear-search ${
            searchKeyword.length === 0 ? "hide" : ""
          }`}
        />
        <button className={classes.inputButton}>Search</button>
      </form>

      <div
        className={`search-result-sec ${
          searchKeyword.length === 0 ? "hide" : ""
        }`}
      >
        {content}
      </div>
    </div>
  );
}
