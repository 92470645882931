import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import DropdownMenuMobile from "./DropdownMenuMobile";
import { dataUrl, getMenusByCategory } from "../../../helpers/common";
import { getLabeledProducts } from "../../../actions/products";

export default function SideBar({ sideBarRef, open, setOpen }) {
  const classes = useStyles();
  const menus = useSelector((state) => state.menus.menus);
  const topMenus = getMenusByCategory(menus, "top-menu-dropdown") ?? [];
  const labeledProducts =
    useSelector((state) => state.products.labeledProducts) ?? {};
  const categories = useSelector((state) => state.categories.data) ?? [];
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets.data);
  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");
  useEffect(() => {
    dispatch(getLabeledProducts("Best Sellers"));
    dispatch(getLabeledProducts("Todays Deal"));
    dispatch(getLabeledProducts("New Arrival"));
  }, [dispatch]);

  return (
    <div
      ref={sideBarRef}
      className={`sideBarMenu ${classes.sideBarMenu} ${
        open ? classes.sideBarMenuActive : ""
      }`}
    >
      <div className={classes.logoImg}>
        <img src={`${dataUrl}/assets/${websiteLogo?.file_name}`} alt="logo" />
      </div>
      <DropdownMenuMobile
        topMenus={topMenus}
        categories={categories}
        labeledProducts={labeledProducts}
        setOpen={setOpen}
        open={open}
      />
    </div>
  );
}
