import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

const useStyles = makeStyles({
  headerSticky: {
    position: "fixed",
    zIndex: "1001",
    width: "100%",
    backgroundColor: COLORS.white,
  },
  socialBar: {
    width: "100%",
    borderBottom: "1px solid #e0e0e0",
    padding: "5px 0",
    backgroundColor: COLORS.primary,
  },
  runningText: {
    whiteSpace: 'nowrap',
    color: COLORS.white,
    fontWeight: 500,
    fontSize: 13,
  },
  popoverBody: {
    padding: '15px 18px',
  },
  popoverList: {
    marginBottom: '5px !important',
    '&:last-child': {
      marginBottom: '0px !important',
    },
  },
  popoverLink: {
    color: COLORS.lightGrey2,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '15px',
    '&:hover': {
      color: COLORS.primary,
    },
  },
  rightCol: {
    '@media (max-width: 767px)': {
      display: "none",
    }
  },
  socialBarRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:767px)": {
      flexDirection: "column",
      justifyContent: 'flex-start',
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  companyContact: {
    position: "relative",
    padding: "0 10px",
    "&:before": {
      content: '""',
      position: "absolute",
      background: "url(/images/divider-small.png) no-repeat center",
      backgroundSize: "contain",
      width: "7px",
      height: "29px",
      right: 0,
    },
    "&:last-child": {
      "&:hover a p": {
        color: COLORS.primary,
      },
      "&:before": {
        display: "none",
      },
    },
    "&:hover a p": {
      color: COLORS.secondary,
    },
  },
  webView: {
    "@media (max-width:480px)": {
      display: "none",
    },
  },
  mobileView: {
    display: "none",
    "@media (max-width:480px)": {
      display: "block",
    },
  },
  companyContactLink: {
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    marginRight: "10px",
  },
  icon: {
    marginRight: "5px",
  },
  contactText: {
    color: COLORS.lightGrey1,
    fontWeight: "500",
    fontSize: "14px",
    transition: "all 0.3s ease-in-out",
    fontFamily: "Poppins, sans-serif",
  },
  phone: {
    color: COLORS.secondary,
  },
  envelope: {
    color: COLORS.primary,
  },

  logoSecDivider: {
    marginLeft: 10,
  },
  logoSec: {
    padding: "10px 0px",
  },
  logo: {
    maxWidth: 130,
    minHeight: 90
  },
  inputSec: {
    boxShadow: `0px 4px 16px -6px ${COLORS.lightGrey}`,
    display: "flex",
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  input: {
    minWidth: "200px",
    padding: "6px 10px",
    border: `1px solid ${COLORS.lightGrey}`,
    fontSize: "13px",
    "@media (max-width: 991px)": {
      minWidth: "160px",
    },
  },
  inputButton: {
    background: COLORS.secondary,
    outline: 0,
    padding: "6px",
    border: `1px solid transparent`,
    minWidth: "80px",
    color: COLORS.white,
    fontSize: "14px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: COLORS.primary,
    },
    "@media (max-width: 991px)": {
      minWidth: "60px",
    },
  },
  headerButton: {
    position: "relative",
    padding: "0 20px 0 15px",
    "&:last-child": {
      paddingRight: "0",
      "&::before": {
        display: "none",
      },
    },
    "&::before": {
      content: '""',
      position: "absolute",
      background: "url(/images/divider-big.png) no-repeat center",
      backgroundSize: "contain",
      width: "7px",
      height: "50px",
      right: 0,
    },
  },
  buttonIcon: {
    color: COLORS.primary,
    fontSize: "17px",
  },
  cartCount: {
    position: "absolute",
    color: COLORS.white,
    background: COLORS.primary,
    top: "-9px",
    right: "-9px",
    borderRadius: "50%",
    width: "17px",
    height: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: COLORS.darkGrey2,
    fontSize: "14px",
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      textDecoration: "none !important",
      color: COLORS.primary,
    },
  },
  menuBar: {
    background: COLORS.primary,
    minHeight: 45,
  },
  menuUl: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "0px",
    flexWrap: "wrap",
  },
  menuLi: {
    position: 'relative',
  },
  menuLink: {
    color: COLORS.primary,
    fontWeight: "500",
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif",
    transition: "all 0.3s ease-in-out",
    padding: "12px 10px",
    "&:hover": {
      color: COLORS.secondary,
      textDecoration: "none",
    },
    '@media (max-width: 991px)': {
      fontSize: "15px",
    }
  },
  dropdownMenuLink: {
    color: COLORS.black,
    fontWeight: "normal",
    fontSize: "14px",
    transition: "all 0.3s ease-in-out",
    padding: "5px 0px",
    "&:hover": {
      color: COLORS.secondary,
      textDecoration: "none",
    },
  },
  dropdownContent: {
    position: "absolute",
    backgroundColor: "#fff",
    zIndex: 9999,
    left: 0,
    padding: "15px",
    opacity: 0,
    visibility: "hidden",
    maxWidth: 200,
    minWidth: 200,
    width: "100%",
    transform: "translateY(30px)",
    transition: "transform .35s linear",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
    pointerEvents: "none",
  },
  block: {
    display: "flex",
    flexWrap: "wrap",
  },
  categoriesBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: "20px",
    maxWidth: "160px",
    textAlign: "center",
    "&:hover img": {
      color: "#eaaa64",
      border: "1px solid #eaaa64",
      transition: "all .3s ease-in-out",
    },
  },
  boxImage: {
    border: "1px solid #e5e5e5",
    transition: "all .3s ease-in-out",
  },
  root: {
    borderBottom: "1px solid #e5e5e5",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  nested: {
    paddingLeft: 10,
  },
  subCategoryImage: {
    width: 60,
    height: 60,
    marginRight: 10,
    border: "1px solid #e5e5e5",
  },
  productName: {
    marginBottom: 0,
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  productPrice: {
    color: "#eaaa64",
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  categoryName: {
    fontSize: 13,
    lineHeight: "18px",
    marginTop: 5,
  }
});

export default useStyles;
