import React from "react";
import { useSelector } from "react-redux";
import { productImageUrl, convertToSlug } from "../../helpers/common";
import useStyles from "./cartMobileStyles";
import { Link } from "react-router-dom";

export default function CartItemsMobile({ updateCartProduct, removeItem }) {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);
  return (
    <div className={classes.mobileCartItemsSec}>
      {cartData.length > 0 ? (
        cartData.map((product) => {
          const total = product?.selling_price * product?.qty;
          const link = `/product/${product.id}/${convertToSlug(product?.name)}`;

          return (
            <div key={product.id} className={`row ${classes.productRow}`}>
              <div className="col-9 d-flex align-items-center">
                <Link to={link}>
                  <img
                    className={classes.image}
                    src={productImageUrl(
                      product.main_image,
                      "products",
                      product.id,
                      "290x290-"
                    )}
                    alt="cart-item"
                  />
                </Link>
                <div className="ml-3">
                  <span className={classes.name}>{product.name}</span>
                  <div className={classes.selling_price}>
                    <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                    {product.selling_price}/{product.item_weight}gms
                  </div>
                  <div className="quantity">
                    <div className="quantity-select">
                      <div
                        className="entry value-minus"
                        onClick={() => updateCartProduct(product, "decrease")}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                      <div className="entry value">
                        <span className="span-1">{product.qty}</span>
                      </div>
                      <div
                        className="entry value-plus active"
                        onClick={() => updateCartProduct(product, "increase")}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-3 ${classes.rightCol}`}>
                <h6 className={classes.totalPrice}>
                  <i className="fa fa-inr mr-1" aria-hidden="true"></i>
                  {total.toFixed(2)}
                </h6>
                <div
                  className={classes.remove}
                  onClick={() => removeItem(product)}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h3 className={classes.emptyText}>Cart is Empty</h3>
      )}
    </div>
  );
}
