
import { GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = {
  'Website Homepage Banners': [],
  "Listing Banners": [],
  'Product Banners': [],
  'About Us Banners': [],
  'Home_footer_banner': [],
  'Website Ad Banners': [],
  bannerError: null
};

export default function banners(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return { ...state, [action.payload.bannerType]: action.payload.data };
    case GET_BANNERS_FAILURE: ;
      return { ...state, bannerError: action.payload.error }
    default:
      return state;
  }
}
