import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ProductLayout from "../ProductLayout";

import ProductContainer from "../ProductContainer";

import { getProducts } from "../../actions/products";

import { useParams, useSearchParams } from "react-router-dom";
import MetaTags from "../Common/MetaTags";
import { dataUrl } from "../../helpers/common";

export default function ProductListing(props) {
  // getting category id from the url
  // const categoryId = parseInt(props.match.params.categoryId);
  const { categoryId, filters } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();


  const categories = useSelector((state) => state.categories.data);
  const currentCategory = categories.find(
    (category) => category.id === parseInt(categoryId)
  );

  const [loading, setLoading] = useState(true);
  const [recordFound, setRecordFound] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setRecordFound(true);

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    let priceRange = searchParams.get('price_range');
    let itemWeight = searchParams.get('item_weight');
    let taste = searchParams.get('taste');

    let params = [];
    params.push(`ProductsSearch[category_id]=${categoryId}`);
    params.push(`sort=display_order`);
    params.push(`per-page=200`);

    if (priceRange){
      params.push(`ProductsSearch[selling_price_range]=${priceRange}`);
    }
    if (itemWeight){
      params.push(`ProductsSearch[item_weight]=${itemWeight}`);
    }
    if (taste){
      params.push(`AdditionalFieldsSearch[taste]=${taste}`);
    }

    dispatch(
      getProducts( params.join("&") )
    ).then((result) => {
      if (result?.payload?.data?.length === 0) {
        setRecordFound(false);
      }
      setLoading(false);
    });
  }, [dispatch, categoryId, currentCategory, filters, searchParams]);

  return (
    <ProductLayout parentClass="list_sec" categoryId={categoryId}>
      {currentCategory ? (
        <MetaTags
          module='categories'
          title={currentCategory?.name}
          description={currentCategory?.description}
          keywords={currentCategory?.name}
          image={dataUrl + "/categories/" + currentCategory?.image}
        ></MetaTags>
      ) : null}

      <ProductContainer loading={loading} recordFound={recordFound} />
    </ProductLayout>
  );
}
