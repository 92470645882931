import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((_) => ({
  row: {
    width: "100%",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyItems: "center",
    '@media (max-width: 500px)': {
      flexDirection: "column",
    }
  },
  col: {
    width: "50%",
    height: "100%",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  leftcol: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    justifyItems: "center",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 30,
    '@media (max-width: 500px)': {
      borderBottomLeftRadius: 0,
    }
  },
  loginImg: {
    maxWidth: 300,
    margin: "0 auto",
    '@media (max-width: 500px)': {
      maxWidth: 200,
    }
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 20,
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease-in-out",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      background: "#f1f1f1",
      "& i": {
        color: "#000",
      },
    },
    "& i": {
      color: "#fff",
      '@media (max-width: 500px)': {
        color: COLORS.primary,
      },
    },
  },
  heading: {
    fontSize: "30px !important",
    textAlign: "center",
    color: COLORS.white,
    fontFamily: "Poppins",
    position: "relative",
    marginBottom: 30,
    "&:before": {
      content: '""',
      position: "absolute",
      bottom: -10,
      left: "calc(50% - 38px)",
      width: 70,
      borderRadius: 5,
      height: 3,
      background: "#fff",
      zIndex: 100,
    },
    '@media (max-width: 500px)': {
      fontSize: "22px !important",
      marginTop: 20,
    }
  },
  description: {
    color: COLORS.white,
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: "400",
    textAlign: "center",
    lineHeight: "1.6",
    marginBottom: 30,
    '@media (max-width: 500px)': {
      marginBottom: 20,
    }
  },
  PhoneNumber: {
    fontWeight: "bold",
    textDecoration: 'underline',
  },
  formRow: {
    alignItems: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  inputFeild: {
    color: `${COLORS.black} !important`,
    padding: "10px 10px !important",
    border: 'none',
    borderRadius: "5px",
    fontSize: "14px !important",
    width: "100%",
  },
  errorText: { 
    fontSize: "13px",
    marginTop: "10px !important", 
    color: 'black'
  },
  submit: {
    border: "none",
    padding: "8px 30px",
    background: COLORS.white,
    color: "#000",
    width: "100%",
    borderRadius: "5px",
    margin: "15px 15px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: COLORS.secondary,
      color: COLORS.white,
    },
  },
  rightcol: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px 40px",
    "@media (max-width: 650px)": {
      padding: "10px 20px !important",
    },
    "@media (max-width: 550px)": {
      padding: "10px 15px !important",
    },
  },
  resendLoading: {
    textAlign: "center",
    marginTop: "10px",
  },
  goToLoginBtn: {
    border: "none",
    background: 'transparent',
    color: COLORS.white,
    textAlign: "center",
    margin: "auto",
    display: 'block',
    marginTop: "10px",
    fontSize: "14px",
  }
}));

export default useStyles;
