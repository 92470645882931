import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { dataUrl } from "../../helpers/common";
import { Link } from "react-router-dom";
import useImagePreloader from "../Common/useImagePreloader";

const AdModal = () => {
  const [openAdModal, setOpenAdModal] = useState(false);
  const classes = useStyles();
  const adBanner = useSelector((state) => state.banners["Website Ad Banners"]);

  const handleClose = () => {
    setOpenAdModal(false);
  };

  let randomAdBannerArray = adBanner
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);


  const preloadSrcList = [
    `${dataUrl}/banners/${randomAdBannerArray[0]?.image}`
  ]
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0 !important",
    transition: "all 0.5s ease-in-out",
    overflow: "overlay !important",
    overflowX: "hidden !important",
    "@media (max-width: 550px)": {
      maxWidth: "500px",
      width: "100%",
    },
  };

  useEffect(() => {
    if (imagesPreloaded) {
      setTimeout(() => {
        setOpenAdModal(true);
      }, 4000);
    }
  }, [setOpenAdModal, imagesPreloaded]);


  return (
    <>
      <Modal
        open={openAdModal}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)", cursor: 'url("/images/close.png"), crosshair' } }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={openAdModal}>
          <Box sx={style}>
            <div className={classes.innerContainer}>
              <div onClick={handleClose} className={classes.closeButton}>
                <i className="fa fa-times" />
              </div>
              <Link
                to={randomAdBannerArray[0]?.url}
                onClick={() => setOpenAdModal(false)}
              >
                <img
                  src={`${dataUrl}/banners/${randomAdBannerArray[0]?.image}`}
                  alt=""
                />
                {/* <SuspenseImg alt="" src={`${dataUrl}/banners/${randomAdBannerArray[0]?.image}`} /> */}


              </Link>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AdModal;
