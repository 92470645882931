import React from "react";
import useStyles from "./styles";
import {
  convertToSlug,
  getCategoriesByParentName,
} from "../../../helpers/common";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";

const DropdownMenuMobile = ({
  topMenus,
  categories,
  labeledProducts,
  open,
  setOpen,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const [currentDropdownIndex, setCurrentDropdownIndex] = React.useState(null);

  const handleClick = (index) => {
    setCurrentDropdownIndex(index);
    setDropDownOpen(!dropDownOpen);
  };

  const productClick = (item) => {
    navigate(`/product/${item.id}/${convertToSlug(item.name)}`);
    setDropDownOpen(!dropDownOpen);
    setOpen(!open);
  };

  const categoryClick = (item) => {
    navigate(`/category/${item.id}/${convertToSlug(item.name)}/1`);
    setOpen(!open);
    setDropDownOpen(!dropDownOpen);
  };

  return (
    <div className={classes.mobileMenus}>
      {topMenus.map((menu, index) => (
        <div key={`top-menu-${menu.id}`}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <ListItem button onClick={() => handleClick(index)}>
              <ListItemText primary={menu.name} />
              {currentDropdownIndex === index ? (
                <>{dropDownOpen ? <ExpandLess /> : <ExpandMore />}</>
              ) : (
                <ExpandMore />
              )}
            </ListItem>
          </List>
          <Collapse
            in={currentDropdownIndex === index ? dropDownOpen : false}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {menu.url.includes("#labels/")
                ? labeledProducts[menu.name]?.map((item) => {
                    return (
                      <ListItem
                        key={"product-" + item.id}
                        button
                        className={classes.nested}
                        onClick={() => productClick(item)}
                      >
                        <Link
                          to={`/category/${item.id}/${convertToSlug(
                            item.name
                          )}`}
                        >
                          <div className="d-flex align-items-center">
                            <ListItemText primary={item.name} />
                            <p className={classes.productPrice}>
                              <i className="fa fa-inr mr-1" aria-hidden="true"></i>{item.selling_price}
                            </p>
                          </div>
                        </Link>
                      </ListItem>
                    );
                  })
                : getCategoriesByParentName(categories, menu.name)?.map(
                    (category) => (
                      <ListItem
                        key={"category-" + category.id}
                        button
                        className={classes.nested}
                        onClick={() => categoryClick(category)}
                      >
                        <div
                          className="d-flex align-items-center"
                          onClick={() => categoryClick(category)}
                        >
                          <ListItemText className={classes.subMenuLink} primary={category.name} />
                        </div>
                      </ListItem>
                    )
                  )}
            </List>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default DropdownMenuMobile;
