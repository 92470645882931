import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  productContainer:{
    padding: '140px 0 40px',
    '@media (max-width: 991px)': {
      paddingTop: 130,
    },
    '@media (max-width: 500px)': {
      paddingTop: 110,
    }
  },
  heading: {
    textAlign: 'center',
  },
  notFound: {
    textAlign: 'center',
    padding: '50px 0'
  },
  innerBanner: {
    marginBottom: '50px',
    background: 'url(/images/inner_banner2.jpg) no-repeat center',
    backgroundSize: 'cover',
    width: '100%',
    minHeight: 240,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minHeight: 'auto',
      padding: '10% 0',
    },
    '@media (max-width: 767px)': {
      marginBottom: 30
    }
  },
}))

export default useStyles;