import React from "react";
import { useSelector } from "react-redux";
import { dataUrl } from "../../../helpers/common";
import useStyles from "./styles";
import { Link } from "react-router-dom";

export default function FooterBanner() {
  const classes = useStyles();
  const banners = useSelector((state) => state.banners["Home Footer Banners"]);

  let randomFooterBanners = banners
    ?.map((value) => ({ value, sort: Math.random() }))
    ?.sort((a, b) => a.sort - b.sort)
    ?.map(({ value }) => value);


  return (
    <div className={classes.footerBannerSec}>
      <div className="container">
      {randomFooterBanners && randomFooterBanners.length && (
          <Link to={randomFooterBanners[0]?.url ?? "/"}>
            <img
              src={`${dataUrl}/banners/1400x450-${randomFooterBanners[0]?.image}`}
              alt="footer-banner"
            />
          </Link>
        )}
      </div>
    </div>
  );
}
