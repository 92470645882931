import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

const useStyles = makeStyles((theme) => ({
  comm_sec: {
    '@media (max-width: 600px)': {
      margin: '0 -20px',
    }
  },
  prodrate_sec: {
    marginTop: "20px",
    '@media (max-width: 575px)': {
      marginTop: '0',
    }
  },
  pr_left: {
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid #e6e6e6",
    padding: "15px 0",
    '@media (max-width: 575px)': {
      border: 'none',
      paddingTop: '0',
    }
  },
  ratingStar: {
    '@media (max-width: 475px)': {
      fontSize: '16px !important',
    }
  },
  totalRating: {
    fontSize: "50px",
    margin: 0,
    marginRight: "15px",
    '@media (max-width: 475px)': {
      fontSize: '42px !important',
    }
  },
  rate_info: {
    '@media (max-width: 475px)': {
      marginTop: '-5px',
    }
  },
  rate_info_span: {
    fontSize: "14px",
  },
  totalStar: {
    fontSize: "1.125rem",
    color: COLORS.primary,
  },
  progressList: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progress: {
    width: "75%",
    height: "7px",
  },
  progressColLeft: {
    width: "10%",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "10px",
    "& i": {
      fontSize: "12px",
    },
  },
  progressColRight: {
    width: "15%",
    paddingLeft: "10px",
    fontSize: "13px",
  },
  rateRow: {
    '@media (max-width: 991px)': {
      display: 'none',
    }
  },
  rev_btns_sec: {
    display: "block",
    textAlign: "center",
  },
  rev_btns: {
    textAlign: "center",
  },
  ratingFormSec: {
    marginTop: "15px",
  },
  ratingForm: {
    minHeight: "100px",
    marginTop: 20,
    "&:focus": {
      borderColor: `${COLORS.primary} !important`,
      boxShadow: `0 0 0 0.2rem rgb(255 0 0 / 25%) !important`,
    },
  },
  cancelBtn: {
    background: "transparent !important",
    color: `${COLORS.darkGrey1} !important`,
    border: `1px solid ${COLORS.darkGrey1} !important`,
    marginLeft: 15,
    "&:hover": {
      background: `${COLORS.darkGrey1} !important`,
      color: `${COLORS.white} !important`,
    },
  },
  heading: {
    fontSize: 17,
  },
  commentBlock: {
    marginTop: "30px",
  },
}));

export default useStyles;
