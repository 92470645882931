import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, TextField } from "@mui/material";
import useStyles from "./styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addAddress } from "../../api/customer";
import { getCustomerAddress } from "../../actions/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { Link, useNavigate } from "react-router-dom";
import { getSettingByKey, getWordCount } from "../../helpers/common";
import SelectCity from "../Common/SelectCity";
import MetaTags from "../Common/MetaTags";
import SelectState from "../Common/SelectState";

export default function AddAddress() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addressType, setAddressType] = useState("home");
  const {
    additional_mobile_number,
    mobile_number,
    email,
    pin_code,
    contact_person,
    address,
    address_name,
  } = useSelector((state) => state.customers.profile);

  const [customer, setCustomer] = useState({
    address_type: addressType,
    address_name: address_name || "",
    contact_person: contact_person || "",
    full_address: address || "",
    pin_code: pin_code || "",
    email: email || "",
    mobile_number: mobile_number || "",
    additional_mobile_number: additional_mobile_number || "",
    city: "",
    state: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    setAddressType(event.target.value);
  };
  const settings = useSelector((state) => state.settings.data);
  const findAllowedPincodes = getSettingByKey(
    settings,
    "allowed_pincodes"
  )?.value;
  const addressCharCount = customer?.full_address?.length;
  const maxAddressChar = 80;
  const addreddCharLength = maxAddressChar - addressCharCount;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!customer.mobile_number) {
      setErrors({ mobile_number: "Mobile number is required" });
      return;
    }
    if (
      !customer?.mobile_number?.match("[0-9]{10}") ||
      customer?.mobile_number?.length !== 10
    ) {
      setErrors({ mobile_number: "Please provide valid mobile number" });
      return;
    }
    if (!customer.contact_person) {
      setErrors({ contact_person: "Name is required" });
      return;
    }
    if (
      customer.full_address?.length < 40 ||
      getWordCount(customer.full_address) < 5
    ) {
      setErrors({
        full_address: "Address must be 5 word and 40 character long.",
      });
      return;
    }
    if (!customer.city) {
      setErrors({ city: "City is required" });
      return;
    }
    if (!customer.state) {
      setErrors({ state: "State is required" });
      return;
    }
    if (!customer.pin_code) {
      setErrors({ pin_code: "Pin code is required" });
      return;
    }
    if (
      findAllowedPincodes &&
      !findAllowedPincodes.includes("*") &&
      !findAllowedPincodes.includes(customer.pin_code)
    ) {
      setErrors({ pin_code: `Pin code is not allowed ` });
      return;
    }

    if (!customer.address_name) {
      setErrors({ address_name: "Address name is required" });
      return;
    }

    setLoading(true);

    addAddress({ ...customer, address_type: addressType })
      .then(() => {
        dispatch(getCustomerAddress()).then((ack) => {
          dispatch(showSuccessSnackbar("Address added successfully !"));
          navigate("/my-addresses");
        });
      })
      .catch((_err) => {
        dispatch(showErrorSnackbar("Sorry! Address cannot be added !"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onValueChange = (event) => {
    if (event.target.value.length <= 80) {
      setCustomer({ ...customer, [event.target.name]: event.target.value });
    }
  };

  return (
    <div>
      <MetaTags title='Add new address' description='' keywords=''></MetaTags>

      <div className='container'>
        <div className={classes.myAddressesSec}>
          <h2 className={classes.heading}>Add New Address</h2>

          <form className={classes.form} onSubmit={onSubmit}>
            <Box className={classes.inputRow}>
              <Grid container alignItems='center'>
                <FormControl
                  className={classes.inputFeild}
                  variant='standard'
                  fullWidth
                >
                  <InputLabel id='demo-simple-select-standard-label'>
                    Address Type *
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-standard-label'
                    id='demo-simple-select-standard'
                    value={addressType}
                    onChange={handleChange}
                    label='Address Type'
                  >
                    <MenuItem value={"home"}>Home</MenuItem>
                    <MenuItem value={"work"}>Work</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems='center'>
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.mobile_number}
                  type="number"
                  variant='standard'
                  name='mobile_number'
                  onChange={onValueChange}
                  label='Mobile Number *'
                  helperText={errors.mobile_number}
                  error={errors.mobile_number ? true : false}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems='center'>
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.email}
                  variant='standard'
                  name='email'
                  label='Email Address'
                  helperText={errors.email}
                  error={errors.email ? true : false}
                  onChange={onValueChange}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems='center'>
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.contact_person}
                  variant='standard'
                  name='contact_person'
                  onChange={onValueChange}
                  label='Contact Name *'
                  error={errors.contact_person ? true : false}
                  helperText={errors.contact_person}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems='center' position='relative'>
                <TextField
                  className={`${classes.inputFeild} ${classes.inputFeildAddress}`}
                  fullWidth
                  value={customer.full_address}
                  variant='standard'
                  name='full_address'
                  onChange={onValueChange}
                  label='Full Address *'
                  error={errors.full_address ? true : false}
                  helperText={errors.full_address}
                  placeholder='i.e. Street, Area, City, State'
                />

                <div
                  className={classes.characterLimit}
                >{`(${addreddCharLength} / ${maxAddressChar})`}</div>
              </Grid>
            </Box>

            <Box className={classes.inputRow} minHeight={60}>
              <Grid container alignItems='center' position={"relative"}>
                <SelectCity
                  classes={classes}
                  errors={errors}
                  setCustomer={setCustomer}
                  customer={customer}
                />
              </Grid>

              <Grid container alignItems='center' position={"relative"}>
                <SelectState
                  classes={classes}
                  errors={errors}
                  setCustomer={setCustomer}
                  customer={customer}
                  defaultValue={{
                    value: customer?.state,
                    label: customer?.state,
                  }}
                />
              </Grid>

              <Grid container alignItems='center' position={"relative"}
              className='mobile-hide'
              >
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.pin_code}
                  type="number"
                  variant='standard'
                  name='pin_code'
                  onChange={onValueChange}
                  label='Pin Code *'
                  error={errors.pin_code ? true : false}
                />
                {errors.pin_code &&
                  findAllowedPincodes &&
                  !findAllowedPincodes.includes(customer.pin_code) && (
                    <span className={classes.pincodeLink}>
                      Pin code is not allowed{" "}
                      <Link to='/pages/available-pincodes' target="_blank">Click here</Link>
                    </span>
                  )}
              </Grid>
            </Box>

            {/* this box is showing on mobile only */}
            <Box className={`${classes.inputRow} web-hide`}>
              <Grid container alignItems='center' position={"relative"}>
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.pin_code}
                  type="number"
                  variant='standard'
                  name='pin_code'
                  onChange={onValueChange}
                  label='Pin Code *'
                  error={errors.pin_code ? true : false}
                />
                {errors.pin_code &&
                  findAllowedPincodes &&
                  !findAllowedPincodes.includes(customer.pin_code) && (
                    <span className={classes.pincodeLink}>
                      Pin code is not allowed{" "}
                      <Link to='/pages/available-pincodes' target="_blank">Click here</Link>
                    </span>
                  )}
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems='center'>
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={customer.address_name}
                  variant='standard'
                  name='address_name'
                  onChange={onValueChange}
                  label='Name this Address'
                  error={errors.address_name ? true : false}
                  helperText={errors.address_name}
                  placeholder='i.e. My Home, My Office, My Sister Home etc'
                />
              </Grid>
            </Box>

            <Box
              display='flex'
              justifyContent='center'
              mt={2}
              className='login-btn'
            >
              <button className={classes.submit} type='submit'>
                {loading ? "Loading.." : "Save Address"}
              </button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}
