import React from "react";
import useStyles from "./styles";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import { getFirstCharacter } from "../../../helpers/common";

export default function SingleReview({ review }) {
  const classes = useStyles();
  return (
    <div className={classes.commentBox}>
      <div className={`row `}>
        <div className="col-xl-6 col-lg-6 col-md-6 col-8">
          <div className={classes.user_block}>
            <Avatar className={classes.avatar}>
              {review?.customer?.name === ""
                ? getFirstCharacter("user")
                : getFirstCharacter(review?.customer?.name)}
            </Avatar>
            <div className="rdtl">
              <h5 className={classes.name}>
                {review?.customer?.name === ""
                  ? "user"
                  : review?.customer?.name}
              </h5>
              <h6 className={classes.date}>{review?.created_at}</h6>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-4 text-right">
          <div>
            <div className="rate_info xl">
              <Rating
                name="simple-controlled"
                value={parseInt(review.rating_value, 10)}
                className={classes.ratingStar}
                size="small"
                readOnly
              />
            </div>
            <p>
              {review.rating_value}/<span>5</span>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.comment}>
        <p className={classes.commentText}>{review.review}</p>
      </div>
    </div>
  );
}
