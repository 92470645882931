import { createAction } from 'redux-actions';

import { UPDATE_CART, LOAD_CART_DATA } from '../constants/actionTypes';

const updateCartAction = createAction(UPDATE_CART);

export function updateCart(payload) {
    return dispatch => dispatch(updateCartAction(payload));
}

const loadCartDataAction = createAction(LOAD_CART_DATA);

export function loadCartData(payload) {
    return dispatch => dispatch(loadCartDataAction(payload));
}

