import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/pages";
import useStyles from "./styles";
import NotFound from "../NotFoundPage";
import AppLoader from "../AppLoader";
import MetaTags from "../Common/MetaTags";

const CmsPage = () => {
  const classes = useStyles();
  const { page } = useParams();
  const [details, setDetails] = useState({ content: "", title: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setLoading(true);

    if (page) {
      getPage(page)
        .then((json) => {
          setDetails(json.data?.[0]);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [page]);

  let content = details ? (
    <div dangerouslySetInnerHTML={{ __html: details.content }} />
  ) : (
    <NotFound cmsPageNotFound={true} />
  );

  return (
    <>
      <MetaTags
        module='pages'
        title={details?.title}
        description={details?.meta_description}
        keywords={details?.meta_keyword}
      ></MetaTags>

      {loading ? <AppLoader /> : null}
      <div className={classes.cmsPage}>
        {details ? (
          <div className={classes.innerBanner}>
            <div className='container'>
              <h2 className={classes.title}>{details?.title}</h2>
            </div>
          </div>
        ) : null}

        <div className={classes.content}>
          <div className='list_sec'>
            <div className='container'>
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CmsPage;
