import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../constant/colors";

const useStyles = makeStyles((theme) => ({
  commentBox: {
    padding: '25px 15px',
    border: '1px solid #f3f3f3',
    margin: '0',
    marginTop: 20,
    '@media (max-width: 768px)': {
      marginTop: 10,
    }
  },
  avatar: {
    marginRight: 10,
    fontSize: '14px !important',
    backgroundColor: 'green !important',
    width: '35px !important',
    height: '35px !important'
  },
  user_block: {
    display: 'flex'
  },
  name: {
    fontSize: '16px',
    '@media (max-width: 475px)': {
      fontSize: '14px',
    }
  },
  date: {
    fontSize: '13px !important',
    color: COLORS.lightGrey,
    '@media (max-width: 475px)': {
      fontSize: '11px !important',
    }
  },
  comment: {
    marginTop: 10,
  },
  commentText: {
    fontSize: 14,
  },
  ratingStar: {
    '@media (max-width: 475px)': {
    fontSize: '14px !important',
    }
  }
}))

export default useStyles;