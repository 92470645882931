import React, { useEffect } from "react";
import {
  getAppliedFilters,
  getSettingByKey,
  toArray,
} from "../../helpers/common";
import useStyles from "./styles";

const FilterByTaste = ({
  onFilterChange,
  setFilteredTaste,
  filteredTaste,
  frontendSettings,
}) => {
  const classes = useStyles();
  const filterTastes =
    getSettingByKey(frontendSettings, "filter_tastes")?.value || "";

  const filterTastesArray = filterTastes && toArray(filterTastes, "\n");

  // split from new line

  const onClick = (value) => {
    onFilterChange("taste", value);
    setFilteredTaste(value);
  };

  useEffect(() => {
    const getFilterValueFromParams = getAppliedFilters("taste");
    setFilteredTaste(getFilterValueFromParams);
  }, [setFilteredTaste]);

  return (
    filterTastesArray.length > 0 && (
      <div className={classes.filterMainSec}>
        <h4 className={classes.heading}>Filter By Taste</h4>
        <div className={classes.filterItems}>
          {filterTastesArray?.map((value, index) => (
            <div
              key={value + index}
              className={`${classes.filterItem} ${
                filteredTaste === value ? classes.activefilterItem : ""
              }`}
              onClick={(_) => onClick(value)}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default FilterByTaste;
