import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((_) => ({
  boxStyle: {
    transform: 'scale(0) !important',
    transition: '1.5s ease-in-out !important',
    width: '100%',
    height: '100%',
  },
  innerContainer: {
    padding: 15
  },
  active: {
    transform: 'scale(1) !important',
    transition: '1.5s ease-in-out !important',
  },
  closeButton: {
    position: "absolute",
    top: 15,
    right: 15,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    backgroundColor: COLORS.white,
    "&:hover": {
      background: COLORS.darkGrey1,
      "& i": {
        color: "#fff",
      },
    },
    "& i": {
      color: "#000",
      '@media (max-width: 500px)': {
        color: COLORS.primary,
      },
    },
  },
}));

export default useStyles;
