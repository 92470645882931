import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postContactUs } from "../../actions/contactUs";
import { dataUrl } from "../../helpers/common";
import MetaTags from "../Common/MetaTags";
import useStyles from "./styles";

export default function ConactUs() {
  const classes = useStyles();
  const profile = useSelector((state) => state.customers.profile);
  const settings = useSelector((state) => state.settings.data);
  const [email, setEmail] = useState(profile.email ?? "");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [feedback, setFeedback] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const companyAddress = settings.find(
    (item) => item.key === "company_address"
  );
  const companyPhoneNumber = settings.find(
    (item) => item.key === "company_contact_number"
  );
  const companyEmail = settings.find(
    (item) => item.key === "company_email_address"
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(
      postContactUs({
        name: profile.name ?? name,
        email_address: profile.email ?? email,
        contact_number: profile.mobile_number ?? phone,
        subject,
        captcha_value: "N/A",
        message: feedback,
      })
    ).then((json) => {
      setLoading(false);
      if (json?.status === 201) {
        setFeedback("");
        setSubject("");
        setName("");
        setPhone("");
        setEmail("");
      }
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const banners = useSelector((state) => state.banners["Contact Us Banners"]);

  let randomContactBanners = banners
    ?.map((value) => ({ value, sort: Math.random() }))
    ?.sort((a, b) => a.sort - b.sort)
    ?.map(({ value }) => value);

  return (
    <section className={classes.contactUsSec}>

      <MetaTags
        title="Contact Us"
        description="You can reach us for any query or inquiry"
      />

      {randomContactBanners && randomContactBanners.length && (
        <img
          src={`${dataUrl}/banners/1400x250-${randomContactBanners[0]?.image}`}
          alt="Contact Us banner"
        />
      )}
      <div className="container">
        <h2 className={classes.heading}>Contact Us</h2>
      </div>

      <div className={classes.content}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-5 col-md-6 ${classes.leftCol}`}>
              <div className="w-100">
                <div className={classes.row}>
                  <div className="col-2">
                    <i className={`fa fa-map-marker ${classes.icon}`}></i>
                  </div>
                  <div className="col-10">
                    <h3>Address</h3>
                    <p>{companyAddress?.value}</p>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className="col-2">
                    <i className={`fa fa-phone ${classes.icon}`}></i>
                  </div>
                  <div className="col-10">
                    <h3>Phone</h3>
                    <a
                      className={classes.link}
                      href={`tel:${companyPhoneNumber?.value}`}
                    >
                      {companyPhoneNumber?.value}
                    </a>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className="col-2">
                    <i className={`fa fa-envelope-o ${classes.icon}`}></i>
                  </div>
                  <div className="col-10">
                    <h3>Email</h3>
                    <a
                      className={classes.link}
                      href={`mailto:${companyEmail?.value}`}
                    >
                      {companyEmail?.value}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className={classes.form}>
                <h3 className={classes.title}>Send Message</h3>
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        InputLabelProps={{ style: { top: '-8px' } }}
                        label="Enter your name"
                        variant="standard"
                        required
                        type="text"
                        value={name}
                        className={classes.inputFeild}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        InputLabelProps={{ style: { top: '-8px' } }}
                        label="Enter your email"
                        variant="standard"
                        required
                        value={email}
                        className={classes.inputFeild}
                        fullWidth
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        InputLabelProps={{ style: { top: '-8px' } }}
                        label="Enter your contact number"
                        variant="standard"
                        required
                        value={phone}
                        className={classes.inputFeild}
                        fullWidth
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        InputLabelProps={{ style: { top: '-8px' } }}
                        id="standard"
                        label="Subject"
                        multiline
                        variant="standard"
                        required
                        fullWidth
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        InputLabelProps={{ style: { top: '-8px' } }}
                        id="standard-multiline-static"
                        label="Type your message"
                        multiline
                        variant="standard"
                        required
                        fullWidth
                        rows={3}
                        maxRows={4}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                      />
                    </div>
                    {loading ? (
                      <button className={classes.submit} type="button">
                        Please Wait..
                      </button>
                    ) : (
                      <button className={classes.submit} type="submit">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
