import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getSettingByKey, toArray } from '../../../helpers/common';

const SelectCity = ({ classes, errors, setCustomer, customer, defaultValue }) => {

  const [cityValue, setCityValue] = useState();

  const settings = useSelector((state) => state.settings.data);

  const findAllowedCities = getSettingByKey(settings, 'allowed_cities')?.value;

  const cityOptionsArray = findAllowedCities && toArray(findAllowedCities, '\n');

  const cityOptions = useMemo(() => {
    const options = [];
    if (cityOptionsArray && cityOptionsArray.length > 0) {
      cityOptionsArray.forEach((item) => {
        options.push({ value: item, label: item });
      });
    }
    return options;
  }, [cityOptionsArray]);
  
  const [options, setOptions] = useState(cityOptions);

  const customStyles = useMemo(
    () => ({
      option: (provided) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: '100%',
        border: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
        borderRadius: '0px',
        padding: '0px',
        boxShadow: 'none !important',
        '&:hover': {
          borderBottom: '2px solid rgba(0, 0, 0)',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0px !important',
        marginBottom: '-10px !important',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#000',
        padding: '0px !important',
      }),
    }),
    []
  );

  const handleCityCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setCityValue(newValue);
      setCustomer({ ...customer, city: newValue ? newValue.value : "" });
    },
    [options, setCustomer, customer]
  );

  const handleCityChange = useCallback((inputValue) => {
    setCityValue(inputValue)
    setCustomer({ ...customer, city: inputValue ? inputValue.value : "" });
  }, [customer, setCustomer]);

  return (
    <>
      <h5 className={classes.label}>City *</h5>
      <CreatableSelect
        isClearable
        value={cityValue}
        options={options}
        defaultValue={defaultValue?.value ? defaultValue : []}
        onChange={handleCityChange}
        onCreateOption={handleCityCreate}
        className={`${classes.citySelect} creatable-select`}
        placeholder="Enter your city"
        styles={customStyles}
      />
      <h6 className={classes.errorMsg}>{errors.city}</h6>
    </>
  )
}

export default SelectCity