import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  productLayout: {
    paddingTop: 140,
    '@media (max-width: 991px)': {
      paddingTop: 130,
    },
    '@media (max-width: 500px)': {
      paddingTop: 110,
    }
  },
  innerBanner: {
    backgroundSize: '100%',
    width: '100%',
    minHeight: 260,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minHeight: 200,
      padding: '10% 0',
    },
  },
  bannerImg: {
    minHeight: 100,
    objectFit: 'cover',
    objectPosition: '65%'
  },
  breadcrumbSec: {
    background: '#f0f0f0',
    padding: '13px 0',
    marginBottom: '40px',
  },
  breadcrumb_info: {
    margin: '0',
    fontSize: 14
  },
  content: {
    padding: '50px 0px',
  },
  listingRow: {
    marginBottom: 40,
    '@media (max-width: 767px)': {
      marginBottom: 20,
    }
  },
  breadcrumbValue: {
    textTransform: 'capitalize',
    '@media (max-width: 500px)': {
     fontSize: '12px',
    }
  },
})


export default useStyles;