import React, { useEffect } from "react";
import {
  getAppliedFilters,
  getSettingByKey,
  toArray,
} from "../../helpers/common";
import useStyles from "./styles";

const FilterByWeight = ({
  onFilterChange,
  filteredWeight,
  setFilteredWeight,
  frontendSettings,
}) => {
  const classes = useStyles();
  const onClick = (value) => {
    onFilterChange("item_weight", value);
    setFilteredWeight(value);
  };

  const filterWeight =
    getSettingByKey(frontendSettings, "filter_weights")?.value || "";

  const filterWeightArray = filterWeight && toArray(filterWeight, "\n");

  useEffect(() => {
    const getFilterValueFromParams = getAppliedFilters("item_weight");
    setFilteredWeight(getFilterValueFromParams);
  }, [setFilteredWeight]);

  return (
    filterWeightArray?.length > 0 && (
      <div className={classes.filterMainSec}>
        <h4 className={classes.heading}>Filter By Weight</h4>
        <div className={classes.filterItems}>
          {filterWeightArray.map((value, index) => (
            <div
              key={value + index}
              className={`${classes.filterItem} ${
                filteredWeight === value ? classes.activefilterItem : "false"
              }`}
              onClick={(_) => onClick(value)}
            >
              {value}gms
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default FilterByWeight;
