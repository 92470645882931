import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  mobileCartItemsSec: {
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'block'
    }
  },
  productRow: {
    marginBottom: 18,
    alignItems: "center",
    border: "1px solid #fff",
    paddingBottom: 20,
    borderBottomColor: COLORS.lightGrey,
  },
  image: {
    width: 80,
  },
  name: {
    fontSize: "13px",
    fontWeight: "500",
  },
  selling_price: {
    fontSize: "13px",
  },
  remove: {
    cursor: 'pointer',
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
    alignItems: 'end'
  },
  emptyText: {
    textAlign: "center",
    fontSize: '22px !important',
    marginBottom: 30,
  },
  totalPrice: {
    fontSize: "14px",
  }
}))

export default useStyles;