import React, { useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/customer";
import { showErrorSnackbar } from "../../../actions/snackbar";
import Login from "../../Login";
import useStyles from "./styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { authKey_local_storage_key, dataUrl } from "../../../helpers/common";
import HeaderSearch from "./HeaderSearch";
import DropdownMenu from "./DropdownMenu";

export default function LogoSection() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const cartData = useSelector((state) => state.cart.data);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authToken = localStorage.getItem(authKey_local_storage_key);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const assets = useSelector((state) => state.assets.data);
  const sideBarRef = useRef();
  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");

  const handleAccountClick = (event) => {
    if (authToken) {
      setAnchorEl(event.currentTarget);
    } else {
      handleOpen();
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
    dispatch(showErrorSnackbar("You are logged out!"));
    setAnchorEl(null);
  }, [dispatch]);

  function goToTop() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="container">
      <Login
        openModal={openModal}
        handleOpen={handleOpen}
        setOpenModal={setOpenModal}
        handleClose={handleClose}
      />

      <div className={classes.logoSec}>
        <div className={classes.row}>
          <div className={classes.logo}>
            <Link to="/" onClick={() => goToTop()}>
              <img
                src={`${dataUrl}/assets/${websiteLogo?.file_name}`}
                alt="logo"
              />
            </Link>
          </div>

          <DropdownMenu sideBarRef={sideBarRef} open={open} />

          <HeaderSearch classes={classes} />

          <div className={classes.row}>
            {!authToken ? (
              <div className={classes.headerButton}>
                <Link to="#" onClick={handleOpen} className={classes.link}>
                  <i className={`fa fa-sign-in ${classes.buttonIcon}`} />
                  Login
                </Link>
              </div>
            ) : null}

            <div className={classes.headerButton}>
              <Link
                onClick={handleAccountClick}
                to="#"
                className={classes.link}
              >
                <i className={`fa fa-user ${classes.buttonIcon}`} />
                Account
              </Link>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className={classes.popoverBody}>
                  <Typography className={classes.popoverList}>
                    <Link className={classes.popoverLink} to="/profile">
                      My Profile
                    </Link>
                  </Typography>
                  <Typography className={classes.popoverList}>
                    <Link className={classes.popoverLink} to="/orders">
                      My Order
                    </Link>
                  </Typography>
                  <Typography className={classes.popoverList}>
                    <Link className={classes.popoverLink} to="/my-addresses">
                      My Addresses
                    </Link>
                  </Typography>
                  <Typography className={classes.popoverList}>
                    <Link className={classes.popoverLink} to="/pages/offers">
                      Offers
                    </Link>
                  </Typography>
                  {authToken ? (
                    <Typography
                      onClick={handleLogout}
                      className={classes.popoverList}
                    >
                      <p className={classes.popoverLink}>Log Out</p>
                    </Typography>
                  ) : null}
                </div>
              </Popover>
            </div>
            <div className={classes.headerButton}>
              <span className={classes.cartCount}>{cartData.length}</span>
              <Link to="/cart" className={classes.link}>
                <i className={`fa fa-shopping-cart ${classes.buttonIcon}`} />
                cart
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
