import React, { useCallback, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { COLORS } from "../../constant/colors";
import useStyles from "./styles";
import { getLoginOtp } from "../../api/customer";
import { useDispatch } from "react-redux";
import { login } from "../../actions/customer";
import { CircularProgress } from "@mui/material";
import Fade from "@mui/material/Fade";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import ResendOtp from "../ResendOtp";
import { useNavigate } from "react-router-dom";
export default function Login({ handleClose, openModal, setOpenModal }) {
  const classes = useStyles();
  const lastPhoneNumber = localStorage.getItem("last_mobile_number");
  const [phone, setPhone] = useState(lastPhoneNumber);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [otpVerify, setOtpVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0 !important",
    width: "65%",
    height: "85%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    background: `${COLORS.primary} url(/images/right-bg2.jpg) right repeat`,
    boxShadow: `0px 4px 16px -6px ${COLORS.lightGrey}`,
    transition: "all 0.5s ease-in-out",
    overflow: "overlay !important",
    overflowX: "hidden !important",
    "@media (max-width: 991px)": {
      width: "90%",
      height: "80%",
    },
    "@media (max-width: 550px)": {
      width: "95%",
      height: "95%",
    },
  };

  const handleSendOtp = useCallback(
    async (e) => {
      e.preventDefault();
      setErrors("");

      if (phone.length < 10) {
        setErrors({ phone: "Mobile number must be 10 digits" });
        return false;
      }
      localStorage.setItem("last_mobile_number", phone);

      setLoading(true);
      try {
        // sending an otp to the given mobile.
        let response = await getLoginOtp({ mobile_number: phone });
        console.log(response);
        dispatch(showSuccessSnackbar("An OTP sent to your mobile number"));
        setLoading(false);
        setOtpVerify(true);
        setOtp("");
      } catch (err) {
        setLoading(false);
        dispatch(showErrorSnackbar('error accured'));
      }
    },
    [dispatch, phone]
  );

  const handleOTPSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (otp.length === 0) {
        dispatch(showErrorSnackbar("Pls enter OTP!"));
        return false;
      }

      setLoading(true);

      if (otp.length < 6) {
        dispatch(showErrorSnackbar("Invalid OTP!"));
        return false;
      }

      dispatch(login({ mobile_number: `${phone}`, otp })).then(async (json) => {
        setLoading(false);
        navigate('/');
        if (json?.data?.customer?.id) {
          setOpenModal(false);
          setOtpVerify(false);
          dispatch(showSuccessSnackbar("Login Successful"));
        }
      });
    },
    [dispatch, otp, navigate, phone, setOpenModal]
  );
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <div onClick={handleClose} className={classes.closeButton}>
            <i className="fa fa-times" />
          </div>
          <div className={`${classes.row}`}>
            <div className={`${classes.col} ${classes.leftcol}`}>
              <img
                className={classes.loginImg}
                src="/images/login-img.png"
                alt="bg"
              />
            </div>
            <div className={`${classes.col} ${classes.rightcol}`}>
              <h3 className={classes.heading}>{!otpVerify ? "Welcome to Avon" : "Enter Your OTP"}</h3>
              {!otpVerify ? (
                <h4 className={classes.description}>
                  Sign Up or Sign In to access your account and more!
                </h4>
              ) : (
                <h4 className={classes.description}>
                  An OTP is sent to your given mobile number{" "}
                  <span className={classes.PhoneNumber}>{phone}</span>
                </h4>
              )}
              {!otpVerify ? (
                <form onSubmit={handleSendOtp}>
                  <div className={`row ${classes.formRow}`}>
                    <div className="col-12">
                      <input
                        className={`${classes.inputFeild}`}
                        type="text"
                        value={phone}
                        placeholder="Enter your mobile number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {errors.phone ? (
                        <p className={classes.errorText}>{errors.phone}</p>
                      ) : null}
                    </div>
                    {loading ? (
                      <button className={classes.submit} type="button">
                        <CircularProgress size={20} color="inherit" />
                      </button>
                    ) : (
                      <button className={classes.submit} type="submit">
                        Send OTP
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <form onSubmit={handleOTPSubmit}>
                  <div className={`row ${classes.formRow}`}>
                    <div className="col-12">
                      <input
                        className={`${classes.inputFeild}`}
                        type="text"
                        value={otp}
                        maxLength={6}
                        placeholder="Enter your OTP"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    {loading ? (
                      <button className={classes.submit} type="button">
                        <CircularProgress size={20} color="inherit" />
                      </button>
                    ) : (
                      <button className={classes.submit} type="submit">
                        Verify OTP
                      </button>
                    )}
                  </div>

                  {resendLoading ? (
                    <div className={classes.resendLoading}>
                      <CircularProgress size={16} color="inherit" />
                    </div>
                  ) : (
                    <ResendOtp
                      phone={phone}
                      maxTime={60}
                      setResendLoading={setResendLoading}
                    />
                  )}

                  <button onClick={() => setOtpVerify(false)} className={classes.goToLoginBtn}>Go to login</button>
                </form>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
